// {

// },
import { UnitWeight } from '../../../store/slices/references/prices/types';

export type RowType = 'FOLDER' | 'RATE' | 'RATE_POSITION';
export type UnitType =
  | 'PEOPLE_HOUR'
  | 'MACHINE_HOUR'
  | 'KIT'
  | 'ROUBLE'
  | 'PIECE'
  | 'M'
  | 'M2'
  | 'M3'
  | 'KG'
  | 'TN';
export type ResourceType =
  | 'WORKERS'
  | 'MACHINE_OPERATORS'
  | 'MiM'
  | 'SERVICES'
  | 'EQUIPMENT'
  | 'MATERIALS';
export const resourceRus: Record<ResourceType, string> = {
  EQUIPMENT: 'Оборудование',
  MiM: 'МиМ',
  MATERIALS: 'Материалы',
  WORKERS: 'Рабочие',
  MACHINE_OPERATORS: 'Механизаторы',
  SERVICES: 'Услуги',
};
export const resourceEng: Record<string, ResourceType> = {
  Оборудование: 'EQUIPMENT',
  МиМ: 'MiM',
  Материалы: 'MATERIALS',
  Рабочие: 'WORKERS',
  Механизаторы: 'MACHINE_OPERATORS',
  Услуги: 'SERVICES',
};

export interface IEstimateReference {
  folder?: number;
  hasChildren: boolean | null;
  folderID?: number | null;
  hash: number;
  uuid: string;
  create?: boolean;
  id: number;
  parentID: number | null;
  parentUUID: string | null;
  title: string;
  lvl: number | null;
  rowType: RowType;
  unit: string | null;
  // unit: UnitType;
  code: string | null;
  qty: number | null;
  pz: number | null;
  kfoCoefficient: number | null;
  kfoName: string | null;
  kfoUnit: string | null;
  fot: number | null;
  mim: number | null;
  mtr: number | null;
  totalWorkTime: number | null;
  description: string | null;
  resourceType: ResourceType | null;
  children: IEstimateReference[];
}

export interface IFolderEstimate {
  code: string | null;
  lvl: number;
  parentID: number | null;
  title: string;
}

export interface IUpdateFolder {
  code: string | null;
  lvl: number;
  parentID: number | null;
  title: string;
}

export interface IUpdateRate {
  description: string | null;
  kfoCoefficient: number | null;
  kfoGroup: number | null;
  kfoUnit: string | null;
  fot: number | null;
  mim: number | null;
  pz: number | null;
  mtr: number | null;
  title: string;
  unit: string;
  totalWorkTime: number | null;
  type: ResourceType | null;
}

export interface IUpdateRatePosition {
  fot: number | null;
  mim: number | null;
  mtr: number | null;
  pz: number;
  qty: number;
  title: string;
  kfoCoefficient: number | null;
  kfoGroup: number | null;
  kfoUnit: string | null;
  type: ResourceType | null;
  unit: string;
  workTime: number | null;
}

export interface IRateEstimate {
  type: ResourceType | null;
  insertAfterID?: number | null;
  description: string | null;
  kfoCoefficient: number | null;
  kfoGroup: number | null;
  mim: number | null;
  mtr: number | null;
  fot: number | null;
  kfoUnit: string | null;
  title: string;
  unit: string;
}

export interface IPositionEstimate {
  insertAfterID: number | null;
  fot: number | null;
  mim: number | null;
  mtr: number | null;
  pz: number | null;
  qty: number | null;
  title: string | null;
  type: ResourceType | null;
  unit: string | null;
  workTime: number | null;
}

export interface ICreateRootFolderRequest {
  folders: IFolderEstimate[];
  insertAfterID: null | number;
  rate: IRateEstimate;
}

export interface ICreateFolderOrRateRequest {
  folder: IFolderEstimate | null;
  insertAfterID: null | number;
  rate: IRateEstimate | null;
}

export interface ICreateFolderRequest {
  folder: IFolderEstimate;
  insertAfterID: null | number;
}

export interface ICreateUnitWeight {
  name: string;
}

export interface ICreateKFOGroup extends ICreateUnitWeight {}

export interface IDeleteUnitWeight {
  unitID: number;
}

export interface IDeleteKFOGroup {
  groupID: number;
}

export interface IDeleteRateParams {
  rateIDs: number[];
  ratesPositionIDs: number[];
}

export interface IKFOGroup extends UnitWeight {}

export interface IParamsGetDataWithFilters {
  codes?: number[];
  groups?: string[];
  resourceTypes?: string[];
  titles?: number[];
}

export interface IGetFilters {
  code: string;
  id: number;
  title: string;
}

// export type RowType = 'RATE' | 'RATE_POSITIONS' | 'FOLDER';
export type OrNull<T> = T | null;
export type CoeffType = 'KZ' | 'INDEX';

export interface ICoefficientData {
  id: OrNull<number>;
  isPercent: OrNull<boolean>;
  rowType: RowType;
  lvl: OrNull<number>;
  fot: OrNull<number> | string;
  mim: OrNull<number> | string;
  mtr: OrNull<number> | string;
  pz: OrNull<number> | string;
  fotAsString?: OrNull<string>;
  mimAsString?: OrNull<string>;
  mtrAsString?: OrNull<string>;
  pzAsString?: OrNull<string>;
  rowID: number;
  parentID?: null | number;
  title: OrNull<string>;
  type: OrNull<CoeffType>;
  changedPz?: boolean;
  changedFot?: boolean;
  changedMim?: boolean;
  changedMtr?: boolean;
  children: ICoefficientData[];
}

export interface ICommonCoefficients {
  coefficients: ICoefficientData[];
  global: ICoefficientData;
}

export interface IResponseCoefficients {
  index: ICommonCoefficients;
  kz: ICommonCoefficients;
  synchronized?: OrNull<boolean>;
}

export interface IUpdateData {
  deleted: boolean;
  fot: OrNull<number | string>;
  id: OrNull<number | string>;
  isPercent: boolean;
  mim: OrNull<number | string>;
  mtr: OrNull<number | string>;
  pz: OrNull<number | string>;
  rowID: OrNull<number>;
  type: CoeffType;
}

export interface IRequestUpdateCoefficients {
  calcID: number;
  coefficients: IUpdateData[];
}

export interface IRequestUpdateActCoefficients
  extends IRequestUpdateCoefficients {
  actID: number;
}

export interface IQueryData {
  calcID: number;
  actID: number;
}

export interface IResponseActCoefficients {
  actID: number;
  synchronized?: boolean;
  coefficients: IResponseCoefficients;
}
