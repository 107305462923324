/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React from 'react';
import { Form, FormikProvider } from 'formik';
import { Button, Stack } from '@mui/material';
import UploadForm from '../../../../../../components/UploadForm';
import { Drawer } from '../../../../../../components/Drawer';
import { useMutationHandlers } from '../../../../../../hooks/useMutationHandlers';
import useConfirmDialog from '../../../../../../hooks/useConfirmDialog';
import { enqueueSnackbar } from 'notistack';
import { socketLsr } from '../../../../../../api/websocketApi';
import { useCalcId } from '../../../../../../hooks/useCalcId';
import { useUploadExcelMutation } from '../../../../../../api/calculations';
import { UploadLsrSocketFile } from '../../../../../Lsr/components/UploadLSRFilesDrawer/UploadLSRFilesDrawer.types';
import { IErrorLSRData } from '../../../../../Lsr/components';
import { useProjectId } from '../../../../../../hooks/useProjectId';
import { useForm } from '../../../../../../hooks/useForm';

const ImportDrawer: React.FC<{
  open: boolean;
  changeOpen: (flag: boolean) => void;
}> = ({ changeOpen, open }) => {
  const [createCalculation, createCalculationResponse] =
    useUploadExcelMutation();
  const calcID = useCalcId();
  const projectID = useProjectId();

  const [errorData, setErrorData] = React.useState<IErrorLSRData | null>(null);
  const [error, setError] = React.useState<boolean>(false);
  const [filesData, setFilesData] = React.useState<
    UploadLsrSocketFile[] | null
  >(null);

  const initialValues: { files: File[] | null } = {
    files: null,
  };
  const onSubmit = (values: { files: File[] | null }) => {
    if (socketLsr.readyState !== 1) {
      enqueueSnackbar(
        'Установка соединения. Повторите попытку через несколько секунд.',
        {
          variant: 'error',
          autoHideDuration: 15000,
        },
      );
      return;
    }
    if (values.files) {
      let formData = new FormData();
      formData.append('file', values.files[0]);

      createCalculation({
        projectID: projectID,
        calcID,
        body: formData,
      });
    }
  };

  const { formik } = useForm({
    initialValues,
    onSubmit,
  });

  const {
    ConfirmDialog: CloseImportDrawerDialog,
    openConfirm: handleConfirmImportDrawer,
  } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) changeOpen(false);
    },
  });

  useMutationHandlers(createCalculationResponse, () => {
    enqueueSnackbar({ variant: 'success', message: 'Успешно загружен файл' });
    changeOpen(false);
  });

  React.useEffect(() => {
    if (!open) {
      setFilesData(null);
    }
  }, [open]);
  return (
    <Drawer
      title="Загрузить документы"
      open={open}
      onClose={() => {
        formik.dirty ? handleConfirmImportDrawer() : changeOpen(false);
      }}>
      <FormikProvider value={formik}>
        <Stack p={2.5} component={Form} height="100%" overflow="auto">
          <UploadForm
            errorsMSG={errorData}
            mode="xls"
            format={{
              'application/vnd.ms-excel': ['.xls'],
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                ['.xlsx'],
            }}
            error={error}
            isLoading={false}
            setError={setError}
            setFormData={undefined}
            isWS={true}
            setFilesData={setFilesData}
            // wsProgress={progressPercent}
            // wsTime={progressTime}
            filesData={filesData}
            // isListFiles={isListFiles}
            // setIsListFiles={setIsListFiles}
          />
          {/* {lastJsonMessage?.type === 'upload_progress' && (
              <Box>
                <div>Прогресс: {progress.progress}%</div>
                <div>
                  Загружено файлов: {progress.completed} из {progress.total}
                </div>
                <div>Осталось времени: {progress.remainTime}</div>
              </Box>
            )} */}
          <Stack direction="row" spacing={2} mt="auto">
            <Button
              color="success"
              type="submit"
              disabled={!filesData}
              fullWidth>
              Обработать
            </Button>
            <Button
              fullWidth
              onClick={() => {
                formik.dirty ? handleConfirmImportDrawer() : changeOpen(false);
              }}>
              Закрыть
            </Button>
          </Stack>
        </Stack>
      </FormikProvider>
      <CloseImportDrawerDialog />
    </Drawer>
  );
};

export default React.memo(ImportDrawer);
