import { Box, InputBase } from '@mui/material';
import { LevelFolder } from 'components/LevelFolder';
import styled from 'styled-components';
import { getLevelLeftOffset } from './GroupRow.service';

export const StyledGroupRowWrapper = styled(Box)`
  display: grid;
  align-items: center;
  grid-template-columns: 2420px 150px 150px 150px 150px 150px;
  gap: 20px;
  font-size: 14px;
`;

export const StyledGroupRowInfo = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: `${theme.spacing(1)} ${theme.spacing(8)}`,
  textAlign: 'left',
}));

export const StyledLevelFolder = styled(LevelFolder)`
  margin-left: ${({ level }) => `${getLevelLeftOffset(level)}px`};
`;

export const StyledInput = styled(InputBase)`
  border: 1px solid rgba(92, 110, 140, 0.2);
  padding-left: 8px;
  padding-left: 8px;
  border-radius: 4px;
  font-size: 14px;
  width: 400px;
`;
