/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import { ActList } from '../types';

/**
 * открытие соединение с локальной БД
 */
export const openDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('store', 1);

    request.onerror = () => {
      reject('Error opening database');
    };

    request.onsuccess = function () {
      resolve(this.result);
    };

    request.onupgradeneeded = function () {
      const db = this.result as IDBDatabase;
      if (!db.objectStoreNames.contains('acts')) {
        db.createObjectStore('acts', { keyPath: 'id' });
      }
    };
  });
};

/**
 * добавление данных в локальную БД
 */
export const addData = (
  db: IDBDatabase,
  data: { id: number; act: ActList },
): Promise<string> => {
  const transaction = db.transaction(['acts'], 'readwrite');
  const objectStore = transaction.objectStore('acts');
  const request = objectStore.put(data);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve('Data added');
    request.onerror = () => reject('Error adding data');
  });
};

/**
 * удаление данных из локальной БД
 */
export const deleteData = (db: IDBDatabase, id: number): Promise<string> => {
  const transaction = db.transaction(['acts'], 'readwrite');
  const objectStore = transaction.objectStore('acts');
  const request = objectStore.delete(id);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve('Data deleted');
    request.onerror = () => reject('Error deleting data');
  });
};

/**
 * получение данных из локальной БД
 */
export const getData = (
  db: IDBDatabase,
  id: number,
): Promise<{ id: number; act: ActList }> => {
  const transaction = db.transaction(['acts'], 'readonly');
  const objectStore = transaction.objectStore('acts');
  const request = objectStore.get(id);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject('Error getting data');
  });
};
