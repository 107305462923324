/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import React from 'react';
import { Box, Tab, Tabs as TabsContainer } from '@mui/material';
import { TTabType } from '../handbook.services';

type TTabsProps = {
  changeTab: (event: React.SyntheticEvent, tabName: TTabType) => void;
  selectedTab: TTabType;
  btnPortalContainer: React.RefObject<HTMLDivElement>;
};

const Tabs: React.FC<TTabsProps> = ({
  selectedTab,
  changeTab,
  btnPortalContainer,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={'space-between'}
      alignItems="center">
      <TabsContainer value={selectedTab} onChange={changeTab}>
        <Tab value={'calculation'} label={'Расчет'} />
        <Tab value={'vor'} label={'Укрупненные'} />
        <Tab value={'executed'} label={'Выполнение'} />
      </TabsContainer>
      <div
        style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        ref={btnPortalContainer}
      />
    </Box>
  );
};

export default Tabs;
