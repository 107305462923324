import { alpha, Button } from '@mui/material';
import styled from 'styled-components';

export const StyledParametersButton = styled(Button)(({ theme }) => ({
  border: 'none',
  borderRadius: theme.spacing(0.75),
  backgroundColor: alpha(theme.palette.primary.main, 0.08),

  '&:hover': {
    border: 'none',
  },
}));

export const StyledMenuButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(0.75),
  backgroundColor: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  padding: theme.spacing(1),
  minWidth: '40px',
}));
