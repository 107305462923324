import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FieldHookConfig, useField } from 'formik';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { UploadLsrSocketFile } from '../../../../../../Lsr/components/UploadLSRFilesDrawer/UploadLSRFilesDrawer.types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useUploadExcelMutation } from '../../../../../../../api/calculations';
import style from '../../../../../../../components/UploadForm/EmptyProjectStart.module.scss';
import {
  DragWrapper,
  StyledErrorBox,
  StyledReloadButton,
  StyledUploadName,
  UploadText,
  UploadTitle,
  UploadWrapper,
} from '../../../../../../../components/UploadForm/style';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import { ListFiles } from '../../../../../../../components/UploadForm/components/ListFiles';
import { CircularProgressPercent } from '../../../../../../../components/CircularProgressPercent/CircularProgressPercent';
import Progress from '../../../../../../../components/Progress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { theme } from '../../../../../../../styles/theme';
import { svgDownloadSecond } from '../../../../../../../assets/SVG/SVG';
import { Drawer } from '../../../../../../../components/Drawer';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useLazyGetTemplatePriceReferenceQuery } from '../../../../../../../api/references/estimates';
import { downloadBase64File } from '../../../../../../../utils/downloadBase64File';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { IErrorData } from '../ImportDrawer';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { OrNull } from '../../../../../../../api/references/estimates/estimates.types';

export type ListNameType = {
  name: string;
  idx: number;
};

export interface UploadFormProps {
  setFormData?: (formData: FormData | null) => void;
  done: boolean;
  setError: (value: boolean) => void;
  error: boolean;
  fieldProps?: Partial<FieldHookConfig<File[] | null>>;
  format?: Accept;
  mode?: 'xml' | 'xls';
  isLoading: boolean;
  isWS: boolean;
  setFilesData?: (filesData: UploadLsrSocketFile[] | null) => void;
  wsProgress?: number;
  wsTime?: string;
  errorsMSG: IErrorData[] | null;
  filesData?: UploadLsrSocketFile[] | null;
  isListFiles?: boolean;
  setIsListFiles?: (value: boolean) => void;
  disabledClick?: boolean;
  showDownload?: boolean;
  updatedData?: OrNull<{
    created: OrNull<number>;
    deleted: OrNull<number>;
    updated: OrNull<number>;
  }>;
}

const CopyUploadForm: React.FC<UploadFormProps> = ({
  updatedData,
  errorsMSG,
  showDownload = true,
  disabledClick,
  done,
  setFormData,
  setError,
  error,
  fieldProps,
  format,
  mode,
  isLoading,
  isWS,
  setFilesData,
  wsProgress,
  wsTime,
  filesData,
  isListFiles,
  setIsListFiles,
}) => {
  // console.log(wsProgress, wsTime);
  const { t } = useTranslation('files');
  const { enqueueSnackbar } = useSnackbar();

  const [uploadExcel, uploadExcelResponse] = useUploadExcelMutation();

  const [getTemplate, { isFetching: isFetchingGetTemplate }] =
    useLazyGetTemplatePriceReferenceQuery();

  const [uploadExRes, setUploadExRes] = useState<any>({});

  const [showErrors, setShowErrors] = useState(false);
  console.log('disabledClick>>>', disabledClick);
  const [{ value: formValue }, { touched }, { setValue, setTouched }] =
    useField({
      name: 'files',
      ...fieldProps,
    });

  const getTemplateFile = useCallback(() => {
    getTemplate().then((response) => {
      if ('data' in response && response.data) {
        downloadBase64File(response.data, 'справочник.xlsx');
      }
    });
  }, []);

  const nameFiles = useMemo(() => {
    return formValue?.map((item) => {
      if (formValue.length === 1) {
        return item.name;
      } else {
        return item.name + ', ';
      }
    });
  }, [formValue]);

  const onDropFile = useCallback(
    (files: File[]) => {
      setTouched(true);
      if (!isWS) {
        let formData = new FormData();
        if (mode === 'xls') {
          formData.append('file', files[0]);
        }
        if (mode === 'xml') {
          files.forEach((file, index) => {
            formData.append(`file${index}`, file); //
          });
        }
        if (files?.length) {
          setValue(files);
          setFormData?.(formData);
        }
      } else {
        const fileList: UploadLsrSocketFile[] = [];
        //WARNING incompatible with xls mode
        if (mode === 'xml') {
          files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = ({ target }) => {
              let base64String = (target!.result as string)
                .replace('data:', '')
                .replace(/^.+,/, '');
              fileList.push({ base64: base64String, name: file.name });
            };
            reader.readAsDataURL(file);
          });
        }
        if (files?.length) {
          setValue(files);
          setFilesData?.(fileList);
        }
      }
    },
    [setValue],
  );

  const [errorMsg, setErrorMsg] = useState<string>('');

  //const [isListFiles, setIsListFiles] = useState<boolean>(false);

  function onDropRejected(fileRejections: FileRejection[]) {
    setError(true);
    const errorMsg = fileRejections[0].errors[0].code;
    setErrorMsg(
      t(`common:errors.${errorMsg}`, { maxFiles: mode === 'xls' ? 1 : 500 }),
    );
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop: onDropFile,
    onDropRejected,
    accept: format!,
    multiple: false,
    // maxFiles: mode === 'xls' ? 1 : 500,
    maxFiles: 1,
    disabled: isLoading || disabledClick,
  });

  const nameAcceptedFiles: ListNameType[] = useMemo(
    () =>
      (formValue || []).map((item, idx) => {
        return { name: item.name, idx: idx };
      }),
    [formValue],
  );

  const onDeleteFile = useCallback(
    (delIndex: number) => {
      if (!isWS) {
        const files = formValue!.filter((item, index) => {
          return index !== delIndex;
        });
        if (files.length === 0) setIsListFiles?.(false);
        setValue(files);
      } else {
        const files = formValue!.filter((item, index) => {
          return index !== delIndex;
        });
        if (files.length === 0) setIsListFiles?.(false);
        const filesD = filesData!.filter((i, idx) => {
          return idx !== delIndex;
        });
        console.log(files);
        console.log(filesD);
        setValue(files);
        setFilesData?.(filesD);
      }
    },
    [formValue, filesData],
  );

  useEffect(() => {
    setUploadExRes(uploadExcelResponse);
    setValue(null);
  }, []);

  const onReloadXMLFile = () => {
    setTouched(false);
    setIsListFiles?.(true);
    setError(false);
  };

  const onReloadXLSFile = () => {
    setFormData?.(null);
    setTouched(false);
    setError(false);
    setValue(null);
  };

  const onClickShowErrors = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowErrors(true);
  };

  return (
    <main className={style.main_awd}>
      <Fragment>
        <div className={style.awd_container}>
          <div className={style.awd_description}>
            {/* <Typography variant="body2">
              Вы можете добавить расчет с помощью exсel документа с уже подготовленными данными
            </Typography> */}
          </div>
          <div className={style.awd_loader}>
            {error && errorsMSG ? (
              <StyledErrorBox>
                <ReportProblemOutlinedIcon
                  sx={{ maxHeight: '48px', fontSize: '48px', color: '#F46B6B' }}
                />
                <>
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: '#F46B6B', mb: '6px' }}>
                      Произошла ошибка при загрузке файла!
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: '#F46B6B', mb: '6px' }}>
                      Всего строк с ошибками {errorsMSG.length}
                    </Typography>
                  </Box>
                  <Stack
                    direction="column"
                    spacing={1.5}
                    sx={{
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mt: '22px',
                    }}>
                    <Button
                      fullWidth
                      onClick={() => setShowErrors(true)}
                      variant={'text'}
                      color={'error'}>
                      посмотреть ошибки
                    </Button>
                    <StyledReloadButton
                      fullWidth
                      startIcon={<SyncIcon />}
                      onClick={onReloadXMLFile}>
                      {' '}
                      загрузить еще раз
                    </StyledReloadButton>
                  </Stack>
                </>
              </StyledErrorBox>
            ) : isListFiles ? (
              <ListFiles
                listName={[...nameAcceptedFiles]}
                onDeleteFile={onDeleteFile}
                setIsListFiles={setIsListFiles}
              />
            ) : (
              <DragWrapper drag={isDragActive} {...getRootProps()}>
                <input {...getInputProps()} />
                <UploadWrapper
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  isPointer={true}>
                  {done ? (
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      gap={'24px'}
                      alignItems={'center'}
                      justifySelf={'center'}>
                      <CheckCircleIcon
                        fontSize={'large'}
                        sx={{
                          '& path': {
                            fill: '#00BFA5',
                          },
                        }}
                      />
                      Успешно обработано!
                      {!!updatedData?.created && (
                        <Typography>Создано {updatedData.created}</Typography>
                      )}
                      {!!updatedData?.updated && (
                        <Typography>Обновлено {updatedData.updated}</Typography>
                      )}
                      {!!updatedData?.deleted && (
                        <Typography>Удалено {updatedData.deleted}</Typography>
                      )}
                    </Box>
                  ) : isLoading ? (
                    isWS ? (
                      <Box>
                        <CircularProgressPercent
                          thickness={3.6}
                          value={wsProgress!}
                          time={wsTime!}
                        />
                      </Box>
                    ) : (
                      <Progress />
                    )
                  ) : (
                    <>
                      {mode === 'xml' ? (
                        formValue && formValue?.length > 0 && null
                      ) : (
                        // <CloudDoneIcon sx={{ fontSize: '48px', color: '#0288D1', mb: '30px' }} />
                        <CloudUploadIcon
                          sx={{
                            fontSize: 48,
                            color: theme.palette.secondary.gray,
                          }}
                        />
                      )}
                      {formValue?.length ? (
                        <>
                          {mode === 'xml' ? (
                            <>
                              <Typography variant="subtitle2">
                                ФАЙЛ ДОБАВЛЕН
                              </Typography>
                              <StyledUploadName variant="body2">
                                {nameFiles}
                              </StyledUploadName>
                            </>
                          ) : (
                            <>
                              <UploadTitle variant="body2">
                                Файл загружен
                              </UploadTitle>
                              <UploadText variant="body2">
                                {formValue[0].name}
                              </UploadText>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <UploadTitle variant="body2">
                            загрузить XLS(X)
                          </UploadTitle>
                          <UploadText variant="body2">
                            Чтобы загрузить документ, выберите его из папки на
                            компьютере или просто перетяните его в эту область.
                          </UploadText>
                        </>
                      )}
                    </>
                  )}
                </UploadWrapper>
              </DragWrapper>
            )}
          </div>
          {showDownload && (
            <Button
              variant={'text'}
              onClick={getTemplateFile}
              className={style.awd_download}>
              {svgDownloadSecond}
              <Typography style={{ marginLeft: '10px' }} variant="buttonSmall">
                скачать шаблон формы
              </Typography>
            </Button>
          )}
        </div>
      </Fragment>
      <Drawer
        BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        ModalProps={{
          keepMounted: true,
          disablePortal: true,
        }}
        title={'Информация об ошибке'}
        titleEndButton={
          <IconButton onClick={() => setShowErrors(false)}>
            <CancelIcon sx={{ color: '#5C6E8C' }} />
          </IconButton>
        }
        // titleStartButton={
        //   <Button onClick={() => setShowErrors(false)} variant={'text'}>
        //     <ArrowBackIcon />
        //     Назад
        //   </Button>
        // }
        open={showErrors}
        onClose={() => setShowErrors(false)}>
        <List>
          {errorsMSG?.map((item) => (
            <ListItem
              sx={{
                borderBottom: '1px solid #D1D8FA',
              }}>
              <Box
                width={'100%'}
                display={'grid'}
                gridTemplateColumns={'min-content 1fr'}
                gap={'10px'}>
                <WarningAmberIcon color={'error'} />
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography typography={'subtitle2'}>{item.msg}</Typography>
                  <Typography
                    typography={'subtitle2'}
                    fontSize={'0.8em'}
                    color={'gray'}>
                    {item.address}
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </main>
  );
};

export default CopyUploadForm;
