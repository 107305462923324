import { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadType } from './WSCustomSnackbar.types';
import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { AlertTitle, Box, Snackbar, Stack, Typography } from '@mui/material';
import { StyledAlert } from './styles';
import { Loader } from './icons/Loader';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface WSCustomSnackbarProps {
  isDrawerOpen: boolean; // isUploadDrawerOpen || isIntegDrawerOpen
  //wsType?: string;
  /* progressPercent: number;
  progressTotal: number;
  progressComplete: number; */
  isUploaded?: UploadType;
  setUploaded: (value: UploadType) => void;
  projectIDEquals: boolean;
  handleRefresh: () => void;
  socket: WebSocket;
}

export default function WSCustomSnackbar({
  isDrawerOpen,
  isUploaded,
  setUploaded,
  projectIDEquals,
  handleRefresh,
  socket,
}: WSCustomSnackbarProps) {
  const [open, setOpen] = useState(false);
  const [colorVar, setColor] = useState<string>('');

  const [progressPercent, setProgressPercent] = useState<number>(0);

  useMemo(() => {
    if (isUploaded === 'loading' && !isDrawerOpen) {
      setOpen(true);
    } else if (isUploaded === 'loading') {
      setOpen(false);
    }
  }, [isDrawerOpen, isUploaded]);

  useEffect(() => {
    if (isUploaded === 'loading') {
      setColor('#2B3648');
    }
    if (isUploaded === 'success') {
      setColor('#2E7D32');
    }
    if (isUploaded === 'error') {
      setColor('#F46B6B');
    }
  }, [isUploaded]);

  const handleClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      /*  if (reason === 'timeout') {
       setUploaded('start')
     } */
      if (reason === 'clickaway') {
        return;
      }
      if (isUploaded === 'success') {
        setUploaded('start');
      }
      setOpen(false);
    },
    [isUploaded],
  );

  function LinearProgressPercent(
    props: LinearProgressProps & { value: number },
  ) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
      height: '6px',
      borderRadius: '15px',
      boxShadow:
        'inset 0px -1px 1px rgba(52, 89, 254, 0.15), inset 0px 1px 1px rgba(52, 89, 254, 0.15)',
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
          theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#6D9ADC' : '#308fe8',
      },
    }));

    return (
      <Box
        sx={{
          width: '96%',
          position: 'absolute',
          bottom: 0,
          left: '10px',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography
              variant="body2"
              style={{ padding: '0 10px' }}
              color="#2B3648">{`${Math.round(props.value)}%`}</Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  /****************************************** websocket *************************************************/

  socket.onmessage = function (event) {
    let parsed = JSON.parse(event.data);

    let status = parsed.type;

    let bodyJson = JSON.parse(parsed.bodyJson);

    if (bodyJson !== null && bodyJson.payload == 'pong') return;

    if (
      (status === 'upload_progress' ||
        status === 'upload_handbook' ||
        status === 'import_progress') &&
      projectIDEquals
    ) {
      setUploaded('loading');
      let progress = bodyJson.progress;
      setProgressPercent!(Number(progress));
      //let totalRecords = Number(JSON.parse(bodyJson).total);
    }
    if (status === 'status_ok' && isUploaded === 'loading') {
      setUploaded('success');
      handleRefresh();
    }
    if (status === 'error') {
      setUploaded('error');
    }
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={isUploaded === 'success' ? 6000 : null}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <StyledAlert
          onClose={handleClose}
          variant="outlined"
          icon={
            (isUploaded === 'loading' && <Loader />) ||
            (isUploaded === 'success' && (
              <DoneIcon sx={{ color: '#2E7D32' }} />
            )) ||
            (isUploaded === 'error' && (
              <ErrorOutlineIcon sx={{ color: '#F46B6B' }} />
            ))
          }>
          <AlertTitle
            sx={{
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: `${colorVar}`,
              display: 'flex',
              alignItems: 'center',
            }}>
            {isUploaded === 'loading' && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '32px' }}>
                  Идет загрузка данных.
                  {progressPercent > 0
                    ? ` Загружено ${progressPercent}%`
                    : ' Получаем данные о файлах....'}
                </Typography>
                {/* <Button onClick={onClickDrawerOpen} variant="text" style={{ color: '#0044B4' }} >подробнее</Button> */}
              </>
            )}
            {isUploaded === 'success' && `Данные успешно загружены!`}
            {isUploaded === 'error' && (
              <>
                <Typography variant="body2" sx={{ lineHeight: '44px' }}>
                  Не удалось загрузить файлы
                </Typography>
                {/* <Button onClick={onClickDrawerOpen} variant="text" style={{ color: '#0044B4' }} >подробнее</Button> */}
              </>
            )}
          </AlertTitle>
          {isUploaded === 'loading' && (
            <LinearProgressPercent value={progressPercent} />
          )}
        </StyledAlert>
      </Snackbar>
    </Stack>
  );
}
