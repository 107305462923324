/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { createContext } from 'react';
import type { NavigateOptions } from 'react-router';
import { OrNull } from '../../../../api/references/estimates/estimates.types';
import { ActList, ActResponse } from '../../../../types';

type TInitialTabs = {
  key: string;
  defaultValue?: TTabType;
  navigateOptions?: NavigateOptions;
};

export type TTabType = 'vor' | 'calculation' | 'executed';

export const initialTabs: TInitialTabs = {
  key: 'tab',
  defaultValue: 'calculation',
  navigateOptions: {
    replace: true,
  },
};

type THandbookContext = {
  buttonContainer: OrNull<HTMLDivElement>;
  openUploadDrawer: (f?: boolean) => void;
  currentAct: OrNull<ActList>;
  changeAct: (act: OrNull<ActList>) => void;
  actList: OrNull<ActResponse>;
  toggleIntegrate: (f: boolean) => void;
};

export const HandbookContext = createContext<THandbookContext>({
  buttonContainer: null,
  toggleIntegrate: () => {},
  openUploadDrawer: () => {},
  changeAct: () => {},
  currentAct: null,
  actList: null,
});
