/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

export function saveLevels(levels: number[]) {
  localStorage.setItem('levels', JSON.stringify(levels));
}

export function getSaveLevels(): number[] | null {
  const levels = JSON.parse(localStorage.getItem('levels') ?? 'null');
  return levels;
}
