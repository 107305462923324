import React from 'react';
import { AppRoutes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ruLocale from 'date-fns/locale/ru';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { theme } from './styles/theme';
import { ArcElement, Chart } from 'chart.js';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { I18nextProvider } from 'react-i18next';
import { i18n } from './i18n';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { UICProvider } from './contexts/ui';
import { WSCProvider } from 'contexts/ws';
import './styles/App.scss';

Chart.register(ArcElement);

const snackbarAnchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
};

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <StyledThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <Provider store={store}>
                <UICProvider>
                  <WSCProvider>
                    <BrowserRouter>
                      <SnackbarProvider
                        autoHideDuration={1000}
                        style={{ maxWidth: 320 }}
                        anchorOrigin={snackbarAnchorOrigin}
                        maxSnack={1}>
                        <div className="App">
                          <AppRoutes />
                        </div>
                      </SnackbarProvider>
                    </BrowserRouter>
                  </WSCProvider>
                </UICProvider>
              </Provider>
            </I18nextProvider>
          </StyledThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};

export default App;
