/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import {
  useGetActCoefficientsCalculationQuery,
  useGetCoefficientsCalculationQuery,
  useUpdateActCoefficientsCalculationMutation,
  useUpdateCoefficientsCalculationMutation,
} from 'api/references/estimates';
import {
  CoeffType,
  ICoefficientData,
  IResponseCoefficients,
  IUpdateData,
  OrNull,
} from 'api/references/estimates/estimates.types';
import BigNumber from 'bignumber.js';
import { ToggleButton } from 'components/ToggleButton';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import Tooltip from 'components/Tooltip';
import { Field, FieldProps, Formik, useFormikContext } from 'formik';
import { useCalcId } from 'hooks/useCalcId';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import React, {
  Fragment,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NumericFormat } from 'react-number-format';
import Cell from '../../../../Accomplishment/components/Cell';
import { StyledClearableTextField } from '../../../../CalculationBasic/components/ParametersDialog/components/TabComponent/TabComponent.styles';
import Header from './Header';
import Menu from './Menu';
import {
  Badge,
  CoeffParameters,
  DelimetrRow,
  Table,
  WrapperParameters,
} from './Parameters.style';
import { popperSetup, TTab } from './Parameters.types';
import { ActList, CalcData, TActStats } from '../../../../../../../types';
import { HandbookContext } from '../../../handbook.services';
import {
  ActCol,
  ActRow,
  ActTable,
} from '../../../../CalculationBasic/components/ParametersDialog/components/ActsTab/ActsTab.style';
import {
  formatDateToString,
  formatToDate,
  toLocalString,
} from 'utils/formatDate';
import { LabelsGroup } from '../../../../../../../api/params/params.types';
import { add, isAfter, isBefore, isDate, lastDayOfMonth, sub } from 'date-fns';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { IconDate } from '../../../../Accomplishment/components/ActDialog/ActDialog.style';
import { DatePickerRenderInput } from '../../../../../../../components/FieldForm/styles';
import { Delete } from '@mui/icons-material';
import {
  useDeleteActMutation,
  useLazySyncCoefficientsActQuery,
  useUpdateActMutation,
} from '../../../../../../../api/params';

export interface IForm {
  index: {
    coefficients: ICoefficientData[];
    global: ICoefficientData;
  };
  kz: {
    coefficients: ICoefficientData[];
    global: ICoefficientData;
  };
  acts?: {
    actual: LabelsGroup[];
    removed: ActList[];
  };
  synchronized?: OrNull<boolean>;
}

const statuses: Record<TActStats, { title: string; color: string }> = {
  CLOSED: { color: '#2E7D32', title: 'Период закрыт' },
  COLLECTING: { color: '#A14DE7', title: 'Получение факта' },
  PREPARED: { color: '#FF9800', title: 'Период изменен' },
  NEW: { color: 'white', title: '' },
  REOPENED: { color: '#FF9800', title: 'Период изменен' },
};

const maxNum = BigNumber('999999.999999999999999');
const minNum = BigNumber('-999999.999999999999999');

const clearData = {
  fot: '',
  isPercent: false,
  mim: '',
  mtr: '',
  pz: '',
};
const initGlobalParams: ICoefficientData = {
  fot: '',
  id: null,
  rowType: 'FOLDER',
  isPercent: null,
  lvl: null,
  mim: '',
  mtr: '',
  pz: '',
  rowID: 0,
  title: null,
  type: null,
  children: [],
};
const defaultValues: IForm = {
  index: {
    coefficients: [],
    global: initGlobalParams,
  },
  kz: {
    coefficients: [],
    global: initGlobalParams,
  },
  acts: undefined,
  synchronized: undefined,
};

function operation(
  num: string | number | null,
  value: string,
  onlyPercent = false,
) {
  if (!num) return num;

  const val =
    typeof num === 'string'
      ? Number(
          num
            .trim()
            .replace(',', '')
            .replace(/[\s\%]/g, ''),
        )
      : num;
  if (onlyPercent) {
    if (value === '%') {
      return val * 100;
    } else {
      return val / 100;
    }
  } else {
    if (value === '%') {
      return val * 100;
    } else {
      return val / 100;
    }
  }
}

function getBaseName(str: string): string {
  return str.replace(/^(.+)\.\w+$/gi, '$1');
}

function objectFromArray(arr: string[]) {
  const result: { [key: string]: string | boolean } = {};
  for (const str of arr) {
    if (str.startsWith('changed')) {
      result[str] = true;
    }
  }
  return result;
}

function getDefaultNode(
  node: ICoefficientData,
  isPZ = false,
): { [key: string]: any } {
  return {
    // isPercent: node.isPercent || false,
    pz: node.pz || '',
    fot: node.fot || '',
    mim: node.mim || '',
    mtr: node.mtr || '',
  };
}

function findAndReplaceNodeInTreeArray(
  treeArray: ICoefficientData[],
  rowID: number,
  newNode: ICoefficientData,
): void {
  for (let i = 0; i < treeArray.length; i++) {
    const root = treeArray[i];
    const node = findNodeByRowID(root, rowID);
    if (node) {
      Object.assign(node, newNode);
    }
  }
}

function findNodeByRowID(
  root: ICoefficientData | null,
  rowID: number,
): ICoefficientData | null {
  if (!root) {
    return null;
  }

  if (root.id! === rowID || root.rowID! === rowID) {
    return root;
  }

  if (root.children) {
    for (let i = 0; i < root.children.length; i++) {
      const found = findNodeByRowID(root.children[i], rowID);
      if (found) {
        return found;
      }
    }
  }

  return null;
}

// function getInputProps(disabled: boolean, showEndAdornment: boolean, values:IForm,modKey:'kz'|'index',) {
function getInputProps(
  disabled: boolean,
  showEndAdornment: boolean,
  isEmptyValue: boolean,
) {
  return {
    disabled,
    endAdornment: null,
    // ...(showEndAdornment
    //   ? {
    //     endAdornment: (
    //       <Tooltip title={'Коэффициент удален из-за изменения на нижних уровнях'} placement="top">
    //         <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //           <path
    //             d="M8.00016 2C11.6668 2 14.6668 4.38667 14.6668 7.33333C14.6668 10.28 11.6668 12.6667 8.00016 12.6667C7.1735 12.6667 6.38016 12.5467 5.64683 12.3333C3.70016 14 1.3335 14 1.3335 14C2.88683 12.4467 3.1335 11.4 3.16683 11C2.0335 10.0467 1.3335 8.75333 1.3335 7.33333C1.3335 4.38667 4.3335 2 8.00016 2ZM7.3335 9.33333V10.6667H8.66683V9.33333H7.3335ZM7.3335 8H8.66683V4H7.3335V8Z"
    //             fill="#0044B4"
    //           />
    //         </svg>
    //       </Tooltip>
    //     ),
    //   }
    //   : {}),
  };
}

const InformIcon = memo(() => {
  return (
    <Tooltip
      title={'Коэффициенты были изменены на уровнях ниже'}
      placement="top">
      <svg
        style={{ position: 'absolute', left: '5px' }}
        width="20"
        height="20"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00016 2C11.6668 2 14.6668 4.38667 14.6668 7.33333C14.6668 10.28 11.6668 12.6667 8.00016 12.6667C7.1735 12.6667 6.38016 12.5467 5.64683 12.3333C3.70016 14 1.3335 14 1.3335 14C2.88683 12.4467 3.1335 11.4 3.16683 11C2.0335 10.0467 1.3335 8.75333 1.3335 7.33333C1.3335 4.38667 4.3335 2 8.00016 2ZM7.3335 9.33333V10.6667H8.66683V9.33333H7.3335ZM7.3335 8H8.66683V4H7.3335V8Z"
          fill="#0044B4"
        />
      </svg>
    </Tooltip>
  );
});

const toValue = (val: string | number | null): string | null => {
  val = val?.toString()?.trim() || null;
  if (val === '' || val === null) return null;
  console.log(val);
  console.log(val.replace(/,/gi, '.').replace(/[\s\%]/gi, ''));
  return val.replace(/,/gi, '.').replace(/[\s\%]/gi, '') ?? null;
};
const modifyDataSubmit = (
  data: ICoefficientData,
  type: CoeffType,
): IUpdateData => {
  const { children, title, ...row } = data;
  // if (row.children.length) {
  //   row.children = row.children.map((item) => modifyDataSubmit(item, type)) as any;
  // }
  return {
    ...row,
    rowID: row.rowID || null,
    type,
    isPercent: !!row.isPercent,
    fot: toValue(row.fot),
    mim: toValue(row.mim),
    mtr: toValue(row.mtr),
    pz: toValue(row.pz),
    deleted: false,
  };
};
const flatChildren = (data: ICoefficientData[]): ICoefficientData[] => {
  const result: ICoefficientData[] = [];
  data.forEach((item) => {
    result.push(item);
    if (item.children.length) {
      result.push(...flatChildren(item.children));
    }
  });
  return result;
};

function useCustomRef<T>() {
  const ref = useRef<OrNull<T | HTMLElement>>(null);
  return ref.current;
}

function validate(value: string | number) {
  if (!value) return;
  let num: string | number = value;
  if (typeof value === 'string') {
    num = value?.replace(',', '.')?.replace(/\s/g, '') || '0';
  } else {
    num = value?.toString() || '0';
  }
  if (maxNum.lt(num)) {
    return 'Максимальное значение - 999 999,999999999999999';
  }
  if (minNum.gt(num)) {
    return 'Минимальное знаение - -999 999,999999999999999';
  }
}

// function propagateValue(key: keyof ICoefficientData, value: number | null | string, data: ICoefficientData[], parentValue?: number | null | string): void {
//   data.forEach((item) => {
//     if (item[key] === undefined || item[key] === parentValue) {
//       item[key] = value;
//       item.isPzOverridden = false;
//     } else {
//       // Если значение на текущем уровне отличается, обновляем флаг
//       item.isPzOverridden = true;
//     }
//     // Рекурсивно обновляем дочерние элементы
//     if (item.children) {
//       propagateValue(key, item[key], item.children, value);
//     }
//   });
// }
//
// function updateGlobalValue(key: keyof ICoefficientData, newValue: number | null | string, data: Data): void {
//   data.global[key] = newValue;
//   propagateValue(key, newValue, data.data);
// }
interface Test_IResponseCoefficients {
  index: {
    global: ICoefficientData;
    coefficients: Record<string, ICoefficientData>;
  };
  kz: {
    global: ICoefficientData;
    coefficients: Record<string, ICoefficientData>;
  };
}

const test_initialData = {
  kz: {
    global: {},
    coefficients: {},
  },
  index: {
    global: {},
    coefficients: {},
  },
};

function test_modifyDataFromServer(
  data: IResponseCoefficients & {
    synchronized?: boolean;
  },
  isActs?: boolean,
  actList?: ActList[],
) {
  const acts = actList ? generateActs(actList) : [];
  const result: Test_IResponseCoefficients = {
    kz: {
      global: { ...data.kz?.global },
      coefficients: {},
    },
    index: {
      global: { ...data.index?.global },
      coefficients: {},
    },
    ...(isActs && {
      acts: {
        actual: acts,
        removed: [],
      },
      synchronized: data?.synchronized ?? null,
    }),
  };
  const flatChildren = (
    coefArr: ICoefficientData[],
    key: 'index' | 'kz',
    name = '',
  ) => {
    coefArr.forEach((item, index) => {
      // console.log(name)
      const newName = !name ? key + ':' + index : name + ':' + index;

      result[key].coefficients[newName] = item;
      if (item.children && item.children.length) {
        flatChildren(item.children, key, newName + ':' + 'children');
      }
    });
  };
  console.log(data);
  flatChildren(data.kz.coefficients, 'kz');
  flatChildren(data.index.coefficients, 'index');

  return result;
}

function generateActs(acts: ActList[]) {
  return acts?.reduce((acc, prev) => {
    // const title = prev.estimateName || 'Общие';
    const label =
      'Акты за ' + formatDateToString(new Date(prev.onDate || ''), 'yyyy');

    const idxGroup = acc.findIndex((_) => _.label === label);
    //
    // prev.forEach((_) => {

    const update = {
      ...prev,
      onDate: new Date(prev.onDate || ''),
      startDate: new Date(prev.startDate || ''),
      endDate: new Date(prev.endDate || ''),
    };
    if (idxGroup >= 0) {
      acc[idxGroup].fields = [...acc[idxGroup].fields, update];
    } else {
      acc.push({ label, fields: [update] });
    }
    // });
    return acc;
  }, [] as LabelsGroup[]);
}

const Parameters: React.FC<{
  open: boolean;
  close: () => void;
  isActs: boolean;
  calculation?: CalcData;
}> = ({ open, close, isActs, calculation }) => {
  // const { tableData } = useContext(CalculationDirectoryContext);
  const calcID = useCalcId();
  const { actList, currentAct, changeAct } = React.useContext(HandbookContext);

  const { data: coeffData } = useGetCoefficientsCalculationQuery(calcID, {
    skip: !calcID || isActs,
  });
  const { data: coeffActData } = useGetActCoefficientsCalculationQuery(
    {
      calcID,
      actID: currentAct?.id! ?? 0,
    },
    { skip: (!calcID && !currentAct?.id) || !isActs || !open },
  );
  // const { data: calculationParameters } = useGetCoefficientsCalculationQuery(calcID, { skip: !calcID });
  const calculationParameters = useMemo(() => {
    return isActs
      ? {
          ...(coeffActData || {}).coefficients,
          synchronized: coeffActData?.synchronized ?? null,
        }
      : coeffData;
  }, [isActs, coeffActData, coeffData]);

  const [updateCalculation, { data: updateResponse }] =
    useUpdateCoefficientsCalculationMutation();

  const [updateActCalculation, { data: updateActResponse }] =
    useUpdateActCoefficientsCalculationMutation();

  const [updateAct] = useUpdateActMutation();

  const [deleteAct] = useDeleteActMutation();

  const [syncAct] = useLazySyncCoefficientsActQuery();

  const mapParentsIndex = useRef(new Map<number, ICoefficientData>());
  const mapParentsKz = useRef(new Map<number, ICoefficientData>());

  const [coefficientsData, setCoefficientsData] = useState<
    IResponseCoefficients & {
      acts?: IForm['acts'];
    }
  >(defaultValues);

  const modifyResponse = useCallback(
    (resp: IResponseCoefficients): IForm => {
      const globalParameters = {
        index: {
          changedPz: false,
          changedFot: false,
          changedMim: false,
          changedMtr: false,
        },
        kz: {
          changedPz: false,
          changedFot: false,
          changedMim: false,
          changedMtr: false,
        },
      };

      function findParent(id: number, map: Map<number, ICoefficientData>) {
        if (map.has(id)) {
          return map.get(id);
        }
      }

      // function differenceV2(
      //   data: ICoefficientData,
      //   map: Map<number, ICoefficientData>,
      //   arr: ICoefficientData[],
      //   type: 'index' | 'kz',
      // ) {
      //   const parent = data.parentID ? findParent(data.parentID, map) : null;
      //   if (parent) {
      //     const isDifferent = parent.pz !== data.pz|| parent.fot !== data.fot|| parent.mim !== data.mim|| parent.mtr !== data.mtr;
      //
      //       globalParameters[type].changed = isDifferent;
      //
      //     if (isDifferent) {
      //       findAndReplaceNodeInTreeArray(arr, data.rowID ?? data.id, {
      //         changed: isDifferent,
      //       } as any);
      //       map.set(data.rowID ?? data.id, {
      //         ...data,
      //         changed: isDifferent,
      //       });
      //
      //       let currentParent: ICoefficientData | null = parent;
      //       while (currentParent) {
      //         findAndReplaceNodeInTreeArray(arr, currentParent.rowID, {
      //           changed: isDifferent,
      //         } as any);
      //         if (currentParent.parentID !== undefined && currentParent.parentID !== null) {
      //           currentParent = findParent(currentParent.parentID, map)!;
      //         } else {
      //           currentParent = null;
      //         }
      //       }
      //     }
      //   }
      //   for (const child of data.children) {
      //     difference(child, map, arr, type);
      //   }
      // }
      function difference(
        data: ICoefficientData,
        map: Map<number, ICoefficientData>,
        arr: ICoefficientData[],
        type: 'index' | 'kz',
      ) {
        const parent = data.parentID ? findParent(data.parentID, map) : null;
        if (parent) {
          const isDifferentPz = parent.pz !== data.pz;
          const isDifferentFot = parent.fot !== data.fot;
          const isDifferentMim = parent.mim !== data.mim;
          const isDifferentMtr = parent.mtr !== data.mtr;

          if (type === 'kz') {
            if (isDifferentPz) globalParameters.kz.changedPz = isDifferentPz;
            if (isDifferentFot) globalParameters.kz.changedFot = isDifferentFot;
            if (isDifferentMim) globalParameters.kz.changedMim = isDifferentMim;
            if (isDifferentMtr) globalParameters.kz.changedMtr = isDifferentMtr;
          }
          if (type === 'index') {
            if (isDifferentPz) globalParameters.index.changedPz = isDifferentPz;
            if (isDifferentFot)
              globalParameters.index.changedFot = isDifferentFot;
            if (isDifferentMim)
              globalParameters.index.changedMim = isDifferentMim;
            if (isDifferentMtr)
              globalParameters.index.changedMtr = isDifferentMtr;
          }

          if (
            isDifferentPz ||
            isDifferentFot ||
            isDifferentMim ||
            isDifferentMtr
          ) {
            findAndReplaceNodeInTreeArray(arr, data.id ?? data.rowID, {
              changedPz: isDifferentPz,
              changedFot: isDifferentFot,
              changedMim: isDifferentMim,
              changedMtr: isDifferentMtr,
            } as any);
            map.set(data.id ?? data.rowID, {
              ...data,
              pz: data?.fotAsString?.replace('.', ',') || '',
              fot: data?.fotAsString?.replace('.', ',') || '',
              mim: data?.fotAsString?.replace('.', ',') || '',
              mtr: data?.fotAsString?.replace('.', ',') || '',

              changedPz: isDifferentPz,
              changedFot: isDifferentFot,
              changedMim: isDifferentMim,
              changedMtr: isDifferentMtr,
            });

            let currentParent: ICoefficientData | null = parent;
            while (currentParent) {
              findAndReplaceNodeInTreeArray(
                arr,
                currentParent.id ?? currentParent.rowID,
                {
                  changedPz: isDifferentPz,
                  changedFot: isDifferentFot,
                  changedMim: isDifferentMim,
                  changedMtr: isDifferentMtr,
                } as any,
              );
              if (
                currentParent.parentID !== undefined &&
                currentParent.parentID !== null
              ) {
                currentParent = findParent(currentParent.parentID, map)!;
              } else {
                currentParent = null;
              }
            }
          }
        }
        for (const child of data.children) {
          difference(child, map, arr, type);
        }
      }

      function transaction(
        arr: ICoefficientData[],
        map: Map<number, ICoefficientData>,
        parentID: number | null,
        key: 'index' | 'kz',
      ) {
        const copyArr = [...arr];
        return copyArr.map((item) => {
          const copyItem = {
            ...item,
            parentID,
            pz: item?.pzAsString?.replace('.', ',') || '',
            fot: item?.fotAsString?.replace('.', ',') || '',
            mim: item?.mimAsString?.replace('.', ',') || '',
            mtr: item?.mtrAsString?.replace('.', ',') || '',
            changedPz:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.pz !== item?.pz || false
                  : false,
            changedFot:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.fot !== item?.fot || false
                  : false,
            changedMim:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.mim !== item?.mim || false
                  : false,
            changedMtr:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.mtr !== item?.mtr || false
                  : false,
          };
          copyItem.children = transaction(
            copyItem.children,
            map,
            copyItem.id ?? copyItem.rowID,
            key,
          );
          map.set(copyItem.id ?? copyItem.rowID, copyItem);
          // if (copyItem.parentID !== null) {
          // console.log(difference(copyItem, mapParents));
          // }
          return copyItem;
        });
      }

      const coeffIndex = transaction(
        resp.index.coefficients,
        mapParentsIndex.current,
        null,
        'index',
      );
      const coeffKz = transaction(
        resp.kz.coefficients,
        mapParentsKz.current,
        null,
        'kz',
      );
      coeffKz.forEach((item, idx, arr) => {
        difference(item, mapParentsKz.current, arr, 'kz');
      });
      coeffIndex.forEach((item, idx, arr) => {
        difference(item, mapParentsIndex.current, arr, 'index');
      });
      console.log(mapParentsKz.current, actList);
      return {
        index: {
          coefficients: coeffIndex,
          global: {
            ...initGlobalParams,
            ...globalParameters.index,
            ...resp?.index?.global,
            pz: resp?.index?.global?.pzAsString?.replace('.', ',') || '',
            fot: resp?.index?.global?.fotAsString?.replace('.', ',') || '',
            mim: resp?.index?.global?.mimAsString?.replace('.', ',') || '',
            mtr: resp?.index?.global?.mtrAsString?.replace('.', ',') || '',
            parentID: null,
          },
        },
        kz: {
          coefficients: coeffKz,
          global: {
            ...initGlobalParams,
            ...globalParameters.kz,
            ...resp?.kz?.global,
            pz: resp?.kz?.global?.pzAsString?.replace('.', ',') || '',
            fot: resp?.kz?.global?.fotAsString?.replace('.', ',') || '',
            mim: resp?.kz?.global?.mimAsString?.replace('.', ',') || '',
            mtr: resp?.kz?.global?.mtrAsString?.replace('.', ',') || '',
            parentID: null,
          },
        },
        ...(isActs && {
          acts: {
            actual: actList?.actList ? generateActs(actList.actList) : [],
            removed: [],
          },
          synchronized:
            calculationParameters &&
            'synchronized' in calculationParameters &&
            (calculationParameters?.synchronized! ?? null),
        }),
      };
    },
    [actList, currentAct, calculationParameters, isActs],
  );

  useEffect(() => {
    if (
      ((isActs && coeffActData && actList?.actList?.length) ||
        (!isActs && coeffData)) &&
      calculationParameters
    ) {
      setCoefficientsData(modifyResponse(calculationParameters as any));
    }
  }, [
    calculationParameters,
    actList?.actList,
    isActs,
    coeffActData,
    coeffData,
  ]);

  return (
    <Dialog PaperProps={popperSetup} open={open}>
      {/* <FormProvider {...methods}> */}
      <Formik<IForm>
        enableReinitialize={true}
        initialValues={coefficientsData}
        onSubmit={(values) => {
          const submitData: IUpdateData[] = [];
          if (
            ((isActs && !values.synchronized) || !isActs) &&
            values.index.global
          ) {
            submitData.push(modifyDataSubmit(values.index.global, 'INDEX'));
          }
          if (
            ((isActs && !values.synchronized) || !isActs) &&
            values.kz.global
          ) {
            submitData.push(modifyDataSubmit(values.kz.global, 'KZ'));
          }
          ((isActs && !values.synchronized) || !isActs) &&
            flatChildren(values.index.coefficients).forEach((item) =>
              submitData.push(modifyDataSubmit(item, 'INDEX')),
            );
          ((isActs && !values.synchronized) || !isActs) &&
            flatChildren(values.kz.coefficients).forEach((item) =>
              submitData.push(modifyDataSubmit(item, 'KZ')),
            );
          console.group('submit data');
          console.log('index coefficients>>>', values.index.coefficients);
          console.log('kz coefficients>>>', values.kz.coefficients);
          console.log('index global>>>', values.index.global);
          console.log('kz global>>>', values.kz.global);
          console.log('submitData>>>', submitData);
          console.groupEnd();
          console.log(submitData, values);

          if (isActs) {
            if (currentAct) {
              console.log(values.acts?.actual);
              Promise.all([
                values.acts?.removed.flatMap((act) =>
                  deleteAct({ calcID, actID: act.id }),
                ),
                updateAct({
                  calcID,
                  values: (values.acts?.actual || []).flatMap((label) => {
                    // _.groups.flatMap(() => {
                    return label.fields.flatMap((act) => {
                      const { id, startDate, endDate } = act;
                      if (currentAct?.id === id) {
                        changeAct?.({
                          id: currentAct.id,
                          endDate: toLocalString(endDate as Date),
                          startDate: toLocalString(startDate as Date),
                          onDate: currentAct.onDate,
                        });
                      }
                      return {
                        id,
                        endDate: toLocalString(endDate as Date),
                        startDate: toLocalString(startDate as Date),
                      };
                    });
                    // }),
                  }),
                }),
                isActs &&
                  !values.synchronized &&
                  updateActCalculation({
                    calcID,
                    actID: currentAct.id,
                    coefficients: submitData,
                  }),
                isActs &&
                  values.synchronized &&
                  syncAct({ calcID, actID: currentAct.id }),
              ]).then(() => {
                close();
              });
              return;
            }
          } else {
            updateCalculation({ calcID, coefficients: submitData }).then(() =>
              close(),
            );
          }
        }}>
        <Form
          calculation={calculation}
          closeForm={close}
          key={'kz'}
          mapIndex={mapParentsIndex.current}
          mapKz={mapParentsKz.current}
          isActs={isActs}
        />
      </Formik>
    </Dialog>
  );
};

const Form = React.memo(
  ({
    closeForm,
    mapKz,
    mapIndex,
    isActs,
    calculation,
  }: {
    closeForm: () => void;
    mapKz: any;
    mapIndex: any;
    isActs: boolean;
    calculation?: CalcData;
  }) => {
    const {
      setFieldValue,
      values,
      getFieldProps,
      setValues,
      validateField,
      dirty,
    } = useFormikContext<IForm>();
    const [tab, setTab] = React.useState<TTab>('indirect_costs');
    const changeTab = useCallback((str: TTab) => setTab(str), []);
    const modifyKey = useMemo(() => {
      return tab === 'value_changes' ? 'index' : 'kz';
    }, [tab]);
    const base = values[modifyKey].global;
    const isPercent = !!base?.isPercent;
    const disabledPZ = !!base?.fot || !!base?.mim || !!base?.mtr;
    const disabledRest = !!base?.pz;

    const refPz = useRef<OrNull<HTMLElement>>(null);
    const refFot = useRef<OrNull<HTMLElement>>(null);
    const refMim = useRef<OrNull<HTMLElement>>(null);
    const refMtr = useRef<OrNull<HTMLElement>>(null);

    const recursiveUpdate = useCallback(
      (
        arr: ICoefficientData[],
        record: Record<string, any>,
        isPercent?: boolean,
      ) => {
        const name = Object.keys(record)[0];
        const value = Object.values(record)[0];
        const getValue = (
          item: ICoefficientData,
          val: any,
          onlyPercent: boolean,
        ) => operation(val, item.isPercent ? '%' : '', onlyPercent);
        const result = arr.map((item) => {
          const itemPercent = item.isPercent;
          let copyItem = { ...item, ...record };
          // if (isPercent !== undefined) {
          console.log('isPercent from props function', isPercent);
          console.log('isPercent from props item', item.isPercent);

          // if (!copyItem[name as keyof typeof copyItem]) {
          // }

          const isUpdatedField = (
            key: keyof typeof copyItem,
            el: typeof copyItem,
          ) => {
            return !!isPercent === !!itemPercent
              ? (el?.[key] as string | number | null)
              : operation(el?.[key] as any, el.isPercent ? '%' : '', false);
          };

          if (isPercent === undefined) {
            copyItem = {
              ...copyItem,
              changedPz: false,
              changedFot: false,
              changedMim: false,
              changedMtr: false,

              [name]: value,
            };
          } else {
            copyItem = {
              ...copyItem,
              changedPz: false,
              changedFot: false,
              changedMim: false,
              changedMtr: false,
              pz: isUpdatedField('pz', copyItem),
              fot: isUpdatedField('fot', copyItem),
              mim: isUpdatedField('mim', copyItem),
              mtr: isUpdatedField('mtr', copyItem),
              [name]: isUpdatedField(name as keyof typeof copyItem, copyItem),
            };
          }
          // }
          if (copyItem.children?.length) {
            // console.log(recursiveUpdate(copyItem.children, record));
            copyItem.children = recursiveUpdate(
              copyItem.children,
              record,
              isPercent,
            );
          }
          return copyItem;
        });
        return result;
      },
      [],
    );

    const updateAllFromGlobal = useCallback(
      (
        record: Record<string, any>,
        modKey: 'kz' | 'index',
        isPercent?: boolean,
      ) => {
        setFieldValue(
          `${modKey}.coefficients`,
          recursiveUpdate(values[modKey].coefficients, record, isPercent),
        );
      },
      [values],
    );
    const onChange = (modKey: 'index' | 'kz', record: any, name: string) => {
      validateField(name).finally(() => {
        updateAllFromGlobal(
          {
            // ...node,
            ...record,
          },
          modKey,
        );
        setFieldValue(`${modifyKey}.global.changedPz`, false);
        setFieldValue(`${modifyKey}.global.changedFot`, false);
        setFieldValue(`${modifyKey}.global.changedMim`, false);
        setFieldValue(`${modifyKey}.global.changedMtr`, false);
      });
    };
    const { openConfirm, ConfirmDialog } = useConfirmDialog({
      handleConfirm: (confirm) => {
        if (confirm) {
          closeForm();
        }
      },
    });

    const handleCloseForm = useCallback(() => {
      if (dirty) {
        openConfirm();
      } else {
        closeForm();
      }
    }, [dirty]);

    const getHelpIcon = useCallback(() => {
      const global = getFieldProps(
        getBaseName(`${modifyKey}.global.global`),
      ).value;
      const coefficients = getFieldProps(
        getBaseName(`${modifyKey}.coefficients.coefficients`),
      ).value;
      const firstChildCondition = coefficients.some(
        (item: ICoefficientData) =>
          item.changedPz ||
          item.changedFot ||
          item.changedMim ||
          item.changedMtr,
      );
      const condition = firstChildCondition;
      // const actualMap = modifyKey === 'kz'?mapKz:mapIndex;

      return condition ? <InformIcon /> : null;
    }, [modifyKey, values]);

    // useEffect(()=>{
    //   (async()=>{
    //     if (modifyKey === 'kz') {
    //       const global = values.kz.global
    //       const innerFields = values.kz.coefficients
    //
    //     }else if(modifyKey === 'index'){
    //
    //     }
    //   })()
    // },[values,mapKz,mapIndex,modifyKey])

    return (
      <WrapperParameters>
        {/* <WrapperParameters onSubmit={methods.handleSubmit(console.log)}> */}
        <Header close={handleCloseForm} isActs={isActs} />
        <Menu isActs={isActs} tab={tab} setTab={changeTab} />
        <CoeffParameters>
          {tab === 'acts' ? (
            <ActTable>
              <ActRow
                isIntegrate={!!calculation?.integrationInfo}
                border
                paddingTop>
                <ActCol head>Сформирован</ActCol>
                <ActCol head align={'center'}>
                  Начало периода
                </ActCol>
                <span />
                <ActCol head align={'center'}>
                  Окончание периода
                </ActCol>
                <span />
                {calculation?.integrationInfo && (
                  <ActCol head align={'flex-start'}>
                    Статус
                  </ActCol>
                )}
              </ActRow>
              {values.acts?.actual.map((estimate, estimateIndex) => {
                return (
                  <React.Fragment
                    key={'estimate' + estimateIndex + estimate.label}>
                    {!!estimate.fields.length && (
                      <Typography
                        style={{ cursor: 'default' }}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        width={'100%'}
                        overflow={'hidden'}
                        pt={2}
                        fontSize={14}
                        color={'#7890b2'}>
                        {estimate.label || 'Общие'}
                      </Typography>
                    )}
                    {estimate.fields.map((row, rowIndex) => (
                      <React.Fragment
                        key={'parent' + estimateIndex + estimate.label}>
                        <EditableRowAct
                          calculation={calculation}
                          isActs={isActs}
                          parentIndex={estimateIndex}
                          rowIndex={rowIndex}
                          row={row}
                        />
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                );
              })}
            </ActTable>
          ) : (
            <Table>
              <Typography whiteSpace={'nowrap'} variant={'body2'}>
                Наименование
              </Typography>
              <Typography
                whiteSpace={'nowrap'}
                variant={'body2'}
                align={'center'}>
                К-т / %
              </Typography>
              <Typography
                whiteSpace={'nowrap'}
                variant={'body2'}
                align={'center'}>
                ПЗ
              </Typography>
              <Typography
                whiteSpace={'nowrap'}
                variant={'body2'}
                align={'center'}>
                ФОТ ОР
              </Typography>
              <Typography
                whiteSpace={'nowrap'}
                variant={'body2'}
                align={'center'}>
                МиМ
              </Typography>
              <Typography
                whiteSpace={'nowrap'}
                variant={'body2'}
                align={'center'}>
                МТР
              </Typography>
              <span></span>
              <DelimetrRow />

              <Box gap={'4px'} display={'flex'} alignItems={'center'}>
                <Badge isGlobal>ALL</Badge>
                Глобальный коэффициент
              </Box>
              {/* {modifyKey === 'index' ? ( */}
              {/* <React.Fragment key={'index'}> */}
              <Field
                key={`${modifyKey}.isPercent`}
                name={`${modifyKey}.global.isPercent`}
                render={({ field }: FieldProps) => {
                  return (
                    <ToggleButtonGroup
                      exclusive
                      fullWidth
                      onChange={(_: any, value) => {
                        const node = getFieldProps(`${modifyKey}.global`).value;
                        if (
                          (node.isPercent && value === '%') ||
                          (!node.isPercent && value === 'К')
                        )
                          return;
                        const isPercent = value === '%';
                        const newNode = {
                          pz: operation(node.pz, value),
                          fot: operation(node.fot, value),
                          mim: operation(node.mim, value),
                          mtr: operation(node.mtr, value),
                          isPercent,
                        };
                        setFieldValue(`${modifyKey}.global`, newNode);
                        updateAllFromGlobal(
                          { isPercent },
                          modifyKey,
                          isPercent,
                        );
                      }}>
                      {['К', '%'].map((groupButton) => {
                        // const selected = groupButton === '%' && row.isPercent;
                        const selected =
                          groupButton === 'К' ? !isPercent : !!isPercent;
                        return (
                          <ToggleButton
                            key={groupButton}
                            value={groupButton}
                            selected={selected}>
                            {groupButton}
                          </ToggleButton>
                        );
                      })}
                    </ToggleButtonGroup>
                  );
                }}
              />

              <Field
                key={`${modifyKey}.pz`}
                name={`${modifyKey}.global.pz`}
                // shouldUpdate={(nextProps: any, prevProps: any) => {
                //   console.log(nextProps, prevProps);
                //   return nextProps.value !== prevProps.value;
                // }}
                // control={methods.control}
                validateOnChange={true}
                validate={validate}
                render={({ field, form, meta }: FieldProps) => {
                  const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
                  const message = (form as any).errors?.[modifyKey]?.global?.pz;
                  const isEmpty = form.initialValues?.[modifyKey]?.global?.pz;

                  return (
                    <>
                      <NumericFormat
                        {...field}
                        inputRef={refPz}
                        onChange={(e) => {
                          field.onChange(e);
                          onChange(
                            modifyKey,
                            { pz: e.target.value, fot: '', mim: '', mtr: '' },
                            field.name,
                          );
                        }}
                        InputProps={{
                          ...getInputProps(
                            disabledPZ,
                            nodeOrigin.changedPz && !field.value,
                            isEmpty,
                          ),
                          ref: refPz,
                        }}
                        customInput={StyledClearableTextField}
                        // isAllowed={(values) => {
                        //   const { formattedValue } = values;
                        //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                        //   // return floatValue < MAX_LIMIT;
                        // }}
                        placeholder={isPercent ? '%' : 'К'}
                        suffix={isPercent ? '%' : ''}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalSeparator=","
                        decimalScale={15}
                        // error={!!fieldState.error}
                        onClear={() => setFieldValue(field.name, '')}
                      />
                      <PopperComponent
                        message={message}
                        refItem={refPz.current}
                      />
                    </>
                  );
                }}
              />
              <Field
                key={`${modifyKey}.fot`}
                name={(`${modifyKey}.global` + '.fot') as 'index.global.fot'}
                // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
                // control={methods.control}
                validateOnChange={true}
                validate={validate}
                render={({ field, form, meta }: FieldProps) => {
                  // console.log(form.errors);
                  const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
                  const message = (form as any).errors?.[modifyKey]?.global
                    ?.fot;
                  // console.log(message);
                  // console.log(refFot);
                  const isEmpty = form.initialValues?.[modifyKey]?.global?.fot;
                  return (
                    <>
                      <NumericFormat
                        {...field}
                        allowNegative={false}
                        onChange={(e) => {
                          // setFieldValue(field.name, e.target.value, true);

                          // const node = getDefaultNode(getFieldProps(`${modifyKey}.global`).value);
                          field.onChange(e);
                          onChange(
                            modifyKey,
                            { fot: e.target.value, pz: '' },
                            field.name,
                          );
                        }}
                        InputProps={{
                          ...getInputProps(
                            disabledRest,
                            nodeOrigin.changedFot && !field.value,
                            isEmpty,
                          ),
                          ref: refFot,
                        }}
                        value={field.value}
                        // inputRef={ref}
                        customInput={StyledClearableTextField}
                        // isAllowed={(values) => {
                        // const { formattedValue } = values;
                        // return /^-?(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                        // return floatValue < MAX_LIMIT;
                        // }}
                        thousandSeparator={' '}
                        placeholder={isPercent ? '%' : 'К'}
                        suffix={isPercent ? '%' : ''}
                        decimalSeparator=","
                        decimalScale={15}
                        error={!!message}
                        onClear={() => setFieldValue(field.name, '')}
                      />
                      <PopperComponent
                        message={message}
                        refItem={refFot.current}
                      />
                    </>
                  );
                }}
              />
              <Field
                key={`${modifyKey}.mim`}
                name={(`${modifyKey}.global` + '.mim') as 'index.global.mim'}
                // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
                // control={methods.control}
                validateOnChange={true}
                validate={validate}
                render={({ field, form }: FieldProps) => {
                  const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
                  const message = (form as any).errors?.[modifyKey]?.global
                    ?.mim;
                  const isEmpty = form.initialValues?.[modifyKey]?.global?.mim;
                  return (
                    <>
                      <NumericFormat
                        {...field}
                        // inputRef={ref}
                        onChange={(e) => {
                          field.onChange(e);
                          onChange(
                            modifyKey,
                            { mim: e.target.value, pz: '' },
                            field.name,
                          );
                        }}
                        InputProps={{
                          ...getInputProps(
                            disabledRest,
                            nodeOrigin.changedMim && !field.value,
                            isEmpty,
                          ),
                          ref: refMim,
                        }}
                        customInput={StyledClearableTextField}
                        // isAllowed={(values) => {
                        //   const { formattedValue } = values;
                        //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                        //   // return floatValue < MAX_LIMIT;
                        // }}
                        placeholder={isPercent ? '%' : 'К'}
                        suffix={isPercent ? '%' : ''}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalSeparator=","
                        decimalScale={15}
                        // error={!!fieldState.error}
                        onClear={() => setFieldValue(field.name, '', true)}
                      />
                      <PopperComponent
                        message={message}
                        refItem={refMim.current}
                      />
                    </>
                  );
                }}
              />
              <Field
                name={`${modifyKey}.global.mtr`}
                key={`${modifyKey}.mtr`}
                // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
                validateOnChange={true}
                validate={validate}
                render={({ field, form }: FieldProps) => {
                  const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
                  const message = (form as any).errors?.[modifyKey]?.global
                    ?.mtr;
                  const isEmpty = form.initialValues?.[modifyKey]?.global?.mtr;

                  return (
                    <>
                      <NumericFormat
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          onChange(
                            modifyKey,
                            { mtr: e.target.value, pz: '' },
                            field.name,
                          );
                        }}
                        // inputRef={ref}
                        InputProps={{
                          ...getInputProps(
                            disabledRest,
                            nodeOrigin.changedMtr && !field.value,
                            isEmpty,
                          ),
                          ref: refMtr,
                        }}
                        customInput={StyledClearableTextField}
                        // isAllowed={(values) => {
                        //   const { formattedValue } = values;
                        //   return /^(\d{0,20})(?:,\d{0,15})?%?$/.test(formattedValue);
                        //   // return floatValue < MAX_LIMIT;
                        // }}
                        placeholder={isPercent ? '%' : 'К'}
                        suffix={isPercent ? '%' : ''}
                        allowNegative={false}
                        thousandSeparator={' '}
                        decimalSeparator=","
                        decimalScale={15}
                        // error={!!fieldState.error}
                        onClear={() => {
                          setFieldValue(field.name, '');
                          // field.onChange('');
                        }}
                      />
                      <PopperComponent
                        message={message}
                        refItem={refMtr.current}
                      />
                    </>
                  );
                }}
              />
              <Box
                key={`${modifyKey}.btn`}
                position={'relative'}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}>
                <>
                  {getHelpIcon()}
                  <ClearIcon
                    sx={{ position: 'relative', right: '-15px' }}
                    onClick={() => {
                      console.log('click');
                      console.log(
                        getFieldProps(getBaseName(`${modifyKey}.global`)).value,
                      );
                      console.log(clearData);
                      const update = {
                        ...getFieldProps(`${modifyKey}.global`).value,
                        ...clearData,
                      };
                      console.log(update);
                      setFieldValue(`${modifyKey}.global`, update, true);
                      // methods.setValue(`${modifyKey}.global` as 'index.global', initGlobalParams);
                    }}
                    cursor={'pointer'}
                    color={'secondary'}
                  />
                </>
              </Box>
              {tab === 'indirect_costs' && (
                <ListRow
                  key={'indirect_costs'}
                  mapKz={mapKz}
                  mapIndex={mapIndex}
                  modifyKey={'kz'}
                  list={values[modifyKey].coefficients}
                  name={''}
                />
              )}
              {tab === 'value_changes' && (
                <ListRow
                  key={'value_changes'}
                  mapKz={mapKz}
                  mapIndex={mapIndex}
                  modifyKey={'index'}
                  list={values[modifyKey].coefficients}
                  name={''}
                />
              )}
            </Table>
          )}
        </CoeffParameters>

        <ConfirmDialog />
      </WrapperParameters>
    );
  },
);
Form.displayName = 'Form';

const EditableRowAct: React.FC<{ [key: string]: any; row: ActList }> = ({
  calculation,
  row,
  rowIndex,
  parentIndex,
}) => {
  const { currentAct, changeAct } = useContext(HandbookContext);

  const { values, setFieldValue } = useFormikContext<IForm>();

  const startDate = React.useMemo(() => {
    return row.startDate;
  }, [row]);

  const endDate = React.useMemo(() => {
    return row.endDate;
  }, [row]);

  /**
   * являются ли даты одинаковыми
   * @param dateA
   * @param dateB
   */
  const isSameDate = (dateA: Date, dateB: Date) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();

  /**
   * разбирается список дат для вывода на страницу, делается плоская структура
   */
  const unFlatRanges = useMemo(() => {
    const fields = values.acts?.actual || [];
    return fields
      .reduce((acc: { start: string | Date; end: string | Date }[], item) => {
        acc.push(
          ...item.fields.flatMap((dates) => ({
            start: dates.startDate || '',
            end: dates.endDate || '',
          })),
        );
        return acc;
      }, [])
      .filter(
        (d) =>
          !isSameDate(d.start as Date, new Date(row.startDate || '')) &&
          !isSameDate(d.end as Date, new Date(row.endDate || '')),
      );
  }, [values]);

  /**
   * массив недоступных дат
   */
  const disabledDates: { start: Date; end: Date }[] = useMemo(() => {
    const fields = values.acts?.actual || [];
    if (!fields) return [];
    return unFlatRanges.reduce(
      (disabled, dateRange) => {
        const { start, end } = dateRange;
        disabled.push({ start: new Date(start), end: new Date(end) });
        return disabled;
      },
      [] as { start: Date; end: Date }[],
    );
  }, [startDate, endDate, values]);

  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = useCallback(
    (type: 'startDate' | 'endDate' | 'onDate') => {
      const startD = startDate as Date;
      const endD = endDate as Date;

      const sortedDates = [...disabledDates, {} as { start: Date; end: Date }]
        .sort((a, b) => a?.start?.getTime() - b?.start?.getTime())
        .map((d, i, array) => {
          if (i == 0) {
            return { start: null, end: d.start ?? null };
          } else
            return {
              start: array[i - 1].end,
              end: d?.start ?? null,
            };
        });
      const dates: { minDate: null | Date; maxDate: null | Date } = {
        minDate: null,
        maxDate: null,
      };
      if (!startD && !endD) return dates;
      if (sortedDates.length === 1) {
        if (type === 'startDate') {
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            dates.maxDate = new Date(year, month, date - 1);
          }
        }
        if (type === 'endDate') {
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            dates.minDate = new Date(year, month, date + 1);
          }
        }
        return dates;
      }
      for (const { start, end } of sortedDates) {
        if (start === null) continue;
        if (type === 'startDate') {
          let d: Date | undefined;
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            d = new Date(year, month, date - 1);
          }
          if (start === null && endD && isBefore(endD, end)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else if (end === null && isAfter(endD as Date, start)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else {
            if (isAfter(endD as Date, start) && isBefore(endD as Date, end)) {
              dates.minDate = add(start, { days: 1 });
              dates.maxDate = d ?? end;
              break;
            }
          }
        }
        if (type === 'endDate') {
          let d: Date | undefined;
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            d = new Date(year, month, date + 1);
          }
          if (start === null && startD && isBefore(startD, end)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else if (end === null && isAfter(startD as Date, start)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else {
            if (
              isAfter(startD as Date, start) &&
              isBefore(startD as Date, end)
            ) {
              dates.minDate = d ?? start;
              dates.maxDate = sub(end, { days: 1 });
              break;
            }
          }
        }
      }
      return dates;
    },
    [disabledDates, startDate, endDate],
  );

  const handleRemove = useCallback(
    (updateItem: ActList) => {
      const fields = values.acts?.actual || [];
      const copyFields = [...fields];
      /**
       * функция фильтрации по recordID
       */
      const filter = (i: ActList) => {
        return i.id !== updateItem.id;
      };

      const currentParent = copyFields[parentIndex];
      console.log(currentParent);

      /**
       * новый список для отрисовки, фильтрация
       */
      currentParent.fields = currentParent.fields.filter(filter);

      /* Получение полного списка к удалению */
      const removedArr: ActList[] = values.acts?.removed || [];
      if (updateItem.id === currentAct?.id) {
        const condidate = fields.map((_) => _.fields.find((a) => a.id));
        if (condidate[0]) {
          changeAct?.(condidate[0]);
        } else {
          changeAct?.(null);
        }
      }

      /* пушится новый объект и перезаписывается значение */
      removedArr.push({
        ...updateItem,
      });
      setFieldValue('acts.removed', removedArr, true);

      /* Обновляется родитель, а затем обновляются значения */
      setFieldValue(`acts.actual.${parentIndex}`, currentParent, true);
    },
    [rowIndex, parentIndex, currentAct, changeAct, values],
  );
  const checkDate = (
    inputDate: Date | null,
    start: Date | null,
    end: Date | null,
  ) => {
    if (!inputDate || !start || !end) return false;
    return (
      isSameDate(inputDate, start) ||
      (inputDate > start && inputDate < end) ||
      isSameDate(inputDate, end)
    );
  };
  const shouldDisabled = (
    dayOrMonth: unknown,
    render: 'startDate' | 'endDate',
  ) => {
    if (dayOrMonth) {
      if (disabledDates.length) {
        if (render === 'startDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end),
          );
        }
        if (render === 'endDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end),
          );
        }
      }
    }
    return false;
  };
  console.log(row.status, statuses[row.status!]!.title);
  // console.log(row);
  return (
    <ActRow
      isIntegrate={!!calculation?.integrationInfo}
      active={row.id === currentAct?.id}>
      <ActCol>
        {formatDateToString(new Date(row.onDate || ''), 'dd.MM.yyyy')}
      </ActCol>
      <ActCol>
        <DesktopDatePicker
          value={startDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'startDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'startDate')}
          onChange={(e, keyboardInputValue) => {
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e &&
                  e instanceof Date &&
                  !isNaN(e.getTime()) &&
                  !keyboardInputValue
                ? e.toLocaleDateString()
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.actual.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.actual.0.fields.0.startDate',
                e as Date,
                true,
              );
              const lastDay = lastDayOfMonth(e as Date);
              const endDate =
                disabledRange('endDate').maxDate &&
                sub(disabledRange('endDate').maxDate as Date, { days: 1 });
              if (
                endDate &&
                isDate(endDate) &&
                e instanceof Date &&
                endDate.getMonth() === e.getMonth()
              ) {
                setFieldValue(
                  `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                  e as Date,
                  true,
                );
              } else {
                if (!isNaN(lastDay.getTime())) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    e as Date,
                    true,
                  );
                } else {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    e as Date,
                    true,
                  );
                }
              }
            }
          }}
          components={{
            OpenPickerIcon: IconDate,
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={startDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.startDate`,
                    null,
                    true,
                  );
                  return;
                }
                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!,
                  );
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.startDate`,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true,
                  );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                autoComplete: 'off',
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>&mdash;</ActCol>
      <ActCol>
        <DesktopDatePicker
          value={endDate}
          components={{
            OpenPickerIcon: IconDate,
          }}
          maxDate={disabledRange('endDate').maxDate}
          minDate={disabledRange('endDate').minDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'endDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'endDate')}
          onChange={(e: any, keyboardInputValue) => {
            console.log(keyboardInputValue);
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e && !isNaN(e.getTime()) && !keyboardInputValue
                ? e.toLocaleDateString()
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                e,
                true,
              );
            }
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={endDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    null,
                    true,
                  );
                  return;
                }
                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!,
                  );
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true,
                  );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                // maxLength,
                // minLength,
                autoComplete: 'off',
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>
        <IconButton onClick={() => handleRemove(row)} color="warning">
          <Delete />
        </IconButton>
      </ActCol>
      {calculation?.integrationInfo && (
        <ActCol color={statuses[row.status!]!.color} align={'center'}>
          {statuses[row.status!]!.title}
        </ActCol>
      )}
    </ActRow>
  );
};

const Row = React.memo(
  ({
    row,
    name,
    modifyKey,
    mapKz,
    mapIndex,
  }: {
    row: ICoefficientData;
    mapKz: any;
    mapIndex: any;
    name: string;
    modifyKey: 'kz' | 'index';
  }) => {
    const [show, setShow] = React.useState(false);
    const { setFieldValue, values, getFieldProps } = useFormikContext<IForm>();
    // const { control, watch, setValue } = useFormContext<IForm>();

    const refPz = useRef<OrNull<HTMLElement>>(null);
    const refFot = useRef<OrNull<HTMLElement>>(null);
    const refMim = useRef<OrNull<HTMLElement>>(null);
    const refMtr = useRef<OrNull<HTMLElement>>(null);

    const disabledPZ = !!row?.fot || !!row?.mim || !!row?.mtr;
    const disabledRest = !!row?.pz;
    const getSuffix = useCallback(
      (name: string) => {
        return getFieldProps(name).value.isPercent ? '%' : undefined;
      },
      [getFieldProps],
    );
    const isPercent = useMemo(() => {
      return getFieldProps(`${modifyKey}.coefficients` + name).value?.isPercent;
    }, [getFieldProps, name]);

    const suffix = useMemo(() => {
      return isPercent ? '%' : undefined;
    }, [isPercent]);

    function findNodeByRowIDInForests(
      forests: ICoefficientData[],
      rowID: number,
    ): ICoefficientData | null {
      let foundNode: ICoefficientData | null = null;

      forests.some((tree) => {
        foundNode = findNodeByRowID(tree, rowID);
        return foundNode !== null;
      });

      return foundNode;
    }

    const recursiveCheck = (item: ICoefficientData) => {
      // console.group('recursiveCheck')
      const actualMap = modifyKey === 'kz' ? mapKz : mapIndex;
      const changedArr: ICoefficientData[] = [];
      const global = values[modifyKey].global;
      const parent = item.parentID
        ? findNodeByRowIDInForests(
            values?.[modifyKey]?.coefficients,
            item.parentID,
          )
        : global;
      console.group('parent checks');
      console.log('parent>>>', parent);
      console.log('item>>>', item);
      console.groupEnd();
      const different =
        parent!.pz !== item.pz ||
        parent!.fot !== item.fot ||
        parent!.mim !== item.mim ||
        parent!.mtr !== item.mtr;
      let temp: any = actualMap.get(item.parentID);
      // console.log(actualMap);
      // console.log(temp);
      // console.log(item);
      while (temp) {
        const copyTemp = { ...temp };
        // console.log('check');
        copyTemp.changedPz = different;
        copyTemp.changedMim = different;
        copyTemp.changedFot = different;
        copyTemp.changedMtr = different;
        changedArr.push(copyTemp);
        temp = actualMap.get(temp.parentID);
        // console.log(copyTemp)
      }
      // console.log(temp)
      return changedArr;
      // console.groupEnd()
      // const getParent = (id:number,parentID:number|null)=>{
      //   if (actualMap.has(id)) {
      //     const current = actualMap.get(id);
      //     if (!parent || !actualMap.has(parentID)) {
      //     }
      //     const parent = actualMap.get(id);
      //   }
      //
      // }
    };
    const processCoefficientData = useCallback(
      (
        data: ICoefficientData[],
        record: { [key: string]: any },
        id: number,
        clearKey: string | string[],
      ): ICoefficientData[] => {
        const newData: ICoefficientData[] = JSON.parse(JSON.stringify(data));
        const find = findNodeByRowIDInForests(newData, id);
        const findParent = find?.parentID
          ? findNodeByRowIDInForests(newData, find?.parentID)
          : null;
        const global = values[modifyKey].global;
        console.log(global);
        if (find) {
          Object.assign(find, record);
          // if (findParent) {
          console.log(find, global, findParent);
          find.changedPz = findParent
            ? toValue(findParent?.pz as null) !== toValue(find.pz) || false
            : toValue(global.pz) !== toValue(find.pz);
          find.changedFot = findParent
            ? toValue(findParent?.fot as null) !== toValue(find.fot) || false
            : toValue(global.fot) !== toValue(find.fot);
          find.changedMim = findParent
            ? toValue(findParent?.mim as null) !== toValue(find.mim) || false
            : toValue(global.mim) !== toValue(find.mim);
          find.changedMtr = findParent
            ? toValue(findParent?.mtr as null) !== toValue(find.mtr) || false
            : toValue(global.mtr) !== toValue(find.mtr);
          // if (() ||
          //   () ||
          //   () ||
          //   ()) {
          // }else{
          //   find.changedPz = false
          //   find.changedFot = false
          //   find.changedMim = false
          //   find.changedMtr = false
          // }
          // }
          find.children = (find.children || []).map((item) =>
            traverse(item, findParent),
          );
          find.parentID && clearParent(find.id ?? find.rowID, newData);
          // if (global.pz !== find.pz || global.fot !== find.fot || global.mim !== find.mim || global.mtr !== find.mtr) {
          //   setFieldValue(`${modifyKey}.global.changedPz`,true,true)
          //   setFieldValue(`${modifyKey}.global.changedFot`,true,true)
          //   setFieldValue(`${modifyKey}.global.changedMim`,true,true)
          //   setFieldValue(`${modifyKey}.global.changedMtr`,true,true)
          // }else{
          //   setFieldValue(`${modifyKey}.global.changedPz`,false,true)
          //   setFieldValue(`${modifyKey}.global.changedFot`,false,true)
          //   setFieldValue(`${modifyKey}.global.changedMim`,false,true)
          //   setFieldValue(`${modifyKey}.global.changedMtr`,false,true)
          // }
        }

        function clearParent(id: number, arrData: ICoefficientData[]) {
          const finded = findNodeByRowIDInForests(arrData, id);
          let dataObjects: ICoefficientData[] = [];
          console.log(finded);
          if (finded) {
            dataObjects = recursiveCheck(finded).sort((itemA, itemB) =>
              itemA!.lvl! > itemB!.lvl! ? 1 : -1,
            );
          }
          //
          // if (find) {
          //   const clearKeys = [];
          //   if (Array.isArray(clearKey)) {
          //     clearKey.forEach(key=>{
          //       if (key in find && find[key as keyof typeof find]) {
          //         clearKeys.push(`changed${key.charAt(0).toUpperCase() + key.slice(1)}`);
          //       }
          //     })
          //     // clearKeys.push(...clearKey);
          //   } else {
          //     if (clearKey in find ) {
          //       clearKeys.push(`changed${clearKey.charAt(0).toUpperCase() + clearKey.slice(1)}`);
          //     }
          //   }
          //   // clearKeys.forEach((item) => {
          //   //   if (item in find && find[item as keyof typeof find]) {
          //   //     clearKeys.push(`changed${item.charAt(0).toUpperCase() + item.slice(1)}`);
          //   //   }
          //   // });
          console.log(dataObjects);
          let isDiff = true;
          for (const f of dataObjects) {
            console.log(global, f);
            if (
              global.pz != f.pz ||
              find!.pz != f.pz ||
              global.fot != f.fot ||
              find!.fot != f.fot ||
              global.mim != f.mim ||
              find!.mim != f.mim ||
              global.mtr != f.mtr ||
              find!.mtr != f.mtr
            ) {
              console.log('not equal');
              isDiff = true;
              f.changedPz = true;
              f.changedFot = true;
              f.changedMim = true;
              f.changedMtr = true;
            } else {
              console.log('equal');
              isDiff = false;
              f.changedPz = false;
              f.changedFot = false;
              f.changedMim = false;
              f.changedMtr = false;
            }
            const {
              children,
              mim,
              mtr,
              fot,
              lvl,
              rowType,
              title,
              parentID,
              type,
              pz,
              id,
              rowID,
              ...folderWithoutChildren
            } = f;
            findAndReplaceNodeInTreeArray(
              arrData,
              f.id ?? f.rowID,
              folderWithoutChildren as any,
            );
          }
          // setFieldValue(`${modifyKey}.global.changedPz`,isDiff,true)
          // setFieldValue(`${modifyKey}.global.changedFot`,isDiff,true)
          // setFieldValue(`${modifyKey}.global.changedMim`,isDiff,true)
          // setFieldValue(`${modifyKey}.global.changedMtr`,isDiff,true)
          console.log(arrData);
          //   if (find.parentID && find.parentID !== null) {
          //     clearParent(find.parentID, arrData);
          //   }
          // }
        }

        function traverse(
          node: ICoefficientData,
          parent: ICoefficientData | null,
        ): ICoefficientData {
          console.group('traverse');
          console.log(global);
          console.log(record);
          console.log(node);
          console.log(
            (record?.changedPz && !!node.pz) ||
              ((!!record?.fot || !!record?.mim || !!record?.mtr) && !!node.pz),
          );
          console.groupEnd();
          const copyNode = {
            ...node,
            // @ts-ignore
            ...record,
            changedPz: parent
              ? toValue(parent.pz) !== toValue(node.pz) || false
              : false,
            changedFot: parent
              ? toValue(parent.fot) !== toValue(node.fot) || false
              : false,
            changedMim: parent
              ? toValue(parent.mim) !== toValue(node.mim) || false
              : false,
            changedMtr: parent
              ? toValue(parent.mtr) !== toValue(node.mtr) || false
              : false,
          };

          if (copyNode.children && copyNode.children.length > 0) {
            copyNode.children = copyNode.children.map((item) =>
              traverse(item, copyNode),
            );
          }

          return copyNode;
        }

        console.log(newData);
        return newData;
      },
      [values],
    );

    // useEffect(() => {
    //   const value = isPercent ? '%' : '';
    //   const newNode = {
    //     ...row,
    //     pz: operation(row.pz, value),
    //     fot: operation(row.fot, value),
    //     mim: operation(row.mim, value),
    //     mtr: operation(row.mtr, value),
    //   };
    //   setFieldValue(`${modifyKey}.coefficients` + name,newNode);
    // }, [isPercent]);

    const getHelpIcon = useCallback(() => {
      const node = getFieldProps(`${modifyKey}.coefficients` + name).value;
      const condition =
        node.changedPz || node.changedFot || node.changedMim || node.changedMtr;
      return condition && node?.rowType === 'FOLDER' ? <InformIcon /> : null;
    }, [name, row]);

    return (
      <Fragment>
        <Box
          paddingLeft={`${row.lvl! > 1 ? 12 * (row.lvl! - 1) : 0}px`}
          gap={'4px'}
          display={'flex'}
          alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'} gap={'4px'}>
            {!!row.children?.length && row.rowType === 'FOLDER' ? (
              <KeyboardArrowDownIcon
                style={{ cursor: 'pointer', rotate: show ? '180deg' : '0deg' }}
                onClick={() => setShow((prevState) => !prevState)}
              />
            ) : (
              <div style={{ width: '24px' }} />
            )}
            {row.rowType! === 'FOLDER' ? (
              <Badge>УР {row.lvl}</Badge>
            ) : (
              <div>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.5556 4H5.77778C4.8 4 4 4.8 4 5.77778V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V8.44444L15.5556 4ZM7.55556 7.55556H12V9.33333H7.55556V7.55556ZM16.4444 16.4444H7.55556V14.6667H16.4444V16.4444ZM16.4444 12.8889H7.55556V11.1111H16.4444V12.8889ZM14.6667 9.33333V5.77778L18.2222 9.33333H14.6667Z"
                    fill="#7890B2"
                  />
                </svg>
              </div>
            )}
          </Box>
          <Cell>{row.title}</Cell>
        </Box>
        <Field
          shouldUpdate={(nextProps: any, prevProps: any) =>
            nextProps.value !== prevProps.value
          }
          // control={control}
          name={(`${modifyKey}.coefficients` + name + '.isPercent') as any}
          render={({ field }: FieldProps) => {
            return (
              <ToggleButtonGroup
                {...field}
                exclusive
                fullWidth
                onChange={(_, value) => {
                  const node: ICoefficientData = getFieldProps(
                    getBaseName(field.name),
                  ).value;
                  if (
                    (node.isPercent && value === '%') ||
                    (!node.isPercent && value === 'К')
                  )
                    return;
                  const coefficients = [...values[modifyKey].coefficients];

                  const newNode: ICoefficientData = {
                    ...node,
                    pz: operation(node.pz, value),
                    fot: operation(node.fot, value),
                    mim: operation(node.mim, value),
                    mtr: operation(node.mtr, value),
                    isPercent: value === '%',
                  };
                  // setFieldValue(field.name, value === '%');
                  findAndReplaceNodeInTreeArray(
                    coefficients,
                    node.rowID,
                    newNode,
                  );
                  setFieldValue(`${modifyKey}.coefficients`, coefficients);
                }}>
                {['К', '%'].map((groupButton) => {
                  // const selected = groupButton === '%' && row.isPercent;
                  const selected =
                    groupButton === 'К' ? !field.value : field.value;
                  return (
                    <ToggleButton
                      key={groupButton}
                      value={groupButton}
                      selected={selected}>
                      {groupButton}
                    </ToggleButton>
                  );
                })}
              </ToggleButtonGroup>
            );
          }}
        />
        <Field
          // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
          // control={control}
          name={(`${modifyKey}.coefficients` + name + '.pz') as any}
          validateOnChange={true}
          validate={validate}
          render={({ field, form }: FieldProps) => {
            const node = getFieldProps(getBaseName(field.name)).value;
            const message = (form as any).errors?.[
              modifyKey
            ]?.coefficients?.find((item: any) => item?.pz)?.pz;
            const isEmpty =
              form.initialValues?.[modifyKey]?.coefficients?.[
                Number(name.replace('.', ''))
              ]?.pz;
            const global = getFieldProps(`${modifyKey}.global`).value;
            return (
              <>
                <NumericFormat
                  {...field}
                  onChange={(e) => {
                    const newCoefficients = processCoefficientData(
                      values[modifyKey].coefficients!,
                      { ...getDefaultNode(node), pz: e.target.value },
                      // { ...getDefaultNode(node), pz: e.target.value, changedPz: false },
                      node.rowID!,
                      ['pz', 'fot', 'mim', 'mtr'],
                    );
                    setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                    form.validateForm(field.name);
                  }}
                  InputProps={{
                    ...getInputProps(
                      disabledPZ,
                      node.changedPz && !field.value,
                      isEmpty,
                    ),
                    ref: refPz,
                  }}
                  customInput={StyledClearableTextField}
                  // isAllowed={(values) => {
                  //   const { formattedValue } = values;
                  //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                  // }}
                  placeholder={isPercent ? '%' : 'К'}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={15}
                  suffix={getSuffix(getBaseName(field.name))}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent message={message} refItem={refPz.current} />
              </>
            );
          }}
        />
        <Field
          // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
          // control={control}
          name={(`${modifyKey}.coefficients` + name + '.fot') as any}
          validateOnChange={true}
          validate={validate}
          render={({ field, form }: FieldProps) => {
            const node = getFieldProps(getBaseName(field.name)).value;
            const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
              ?.fot;
            const isEmpty =
              form.initialValues[modifyKey]?.coefficients?.[
                Number(name.replace('.', ''))
              ]?.fot;
            const global = getFieldProps(`${modifyKey}.global`).value;
            return (
              <>
                <NumericFormat
                  {...field}
                  // inputRef={ref}
                  onChange={(e) => {
                    const newCoefficients = processCoefficientData(
                      values[modifyKey].coefficients!,
                      { pz: '', fot: e.target.value },
                      // { pz:'',fot: e.target.value, changedFot: false,changedPz: global.changedPz },
                      node.rowID!,
                      ['fot', 'pz'],
                    );
                    setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                    form.validateField(field.name);
                  }}
                  InputProps={{
                    ...getInputProps(
                      disabledRest,
                      node.changedFot && !field.value,
                      isEmpty,
                    ),
                    ref: refFot,
                  }}
                  customInput={StyledClearableTextField}
                  // isAllowed={(values) => {
                  //   const { formattedValue } = values;
                  //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                  //   // return floatValue < MAX_LIMIT;
                  // }}
                  allowNegative={false}
                  thousandSeparator={' '}
                  placeholder={isPercent ? '%' : 'К'}
                  // suffix={isPercent ? '%' : ''}
                  decimalSeparator=","
                  decimalScale={15}
                  suffix={getSuffix(getBaseName(field.name))}
                  // error={!!fieldState.error}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent message={message} refItem={refFot.current} />
              </>
            );
          }}
        />
        <Field
          // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
          // control={control}
          name={(`${modifyKey}.coefficients` + name + '.mim') as any}
          validateOnChange={true}
          validate={validate}
          render={({ field, form }: FieldProps) => {
            const node = getFieldProps(getBaseName(field.name)).value;
            const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
              ?.mim;
            const isEmpty =
              form.initialValues?.[modifyKey]?.coefficients?.[
                Number(name.replace('.', ''))
              ]?.mim;
            const global = getFieldProps(`${modifyKey}.global`).value;

            return (
              <>
                <NumericFormat
                  {...field}
                  // inputRef={ref}
                  onChange={(e) => {
                    const newCoefficients = processCoefficientData(
                      values[modifyKey].coefficients!,
                      { pz: '', mim: e.target.value },
                      // {  pz:'',mim: e.target.value, changedMim: false,changedPz: global.changedPz },
                      node.rowID!,
                      ['mim', 'pz'],
                    );
                    console.log(newCoefficients);
                    setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                    form.validateField(field.name);
                  }}
                  InputProps={{
                    ...getInputProps(
                      disabledRest,
                      node.changedMim && !field.value,
                      isEmpty,
                    ),
                    ref: refMim,
                  }}
                  customInput={StyledClearableTextField}
                  // isAllowed={(values) => {
                  //   const { formattedValue } = values;
                  //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                  //   // return floatValue < MAX_LIMIT;
                  // }}
                  placeholder={isPercent ? '%' : 'К'}
                  suffix={getSuffix(getBaseName(field.name))}
                  // suffix={isPercent ? '%' : ''}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={15}
                  // error={!!fieldState.error}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent message={message} refItem={refMim} />
              </>
            );
          }}
        />
        <Field
          // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
          // control={control}
          name={(`${modifyKey}.coefficients` + name + '.mtr') as any}
          validateOnChange={true}
          validate={validate}
          render={({ field, form }: FieldProps) => {
            const node = getFieldProps(getBaseName(field.name)).value;
            const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
              ?.mtr;
            const isEmpty =
              form.initialValues?.[modifyKey]?.coefficients?.[
                Number(name.replace('.', ''))
              ]?.mtr;
            const global = getFieldProps(`${modifyKey}.global`).value;
            return (
              <>
                <NumericFormat
                  {...field}
                  // inputRef={ref}
                  onChange={(e) => {
                    const newCoefficients = processCoefficientData(
                      values[modifyKey].coefficients!,
                      { pz: '', mtr: e.target.value },
                      // {  pz:'',mtr: e.target.value, changedMtr: false,changedPz: global.changedPz },
                      node.rowID!,
                      ['mtr', 'pz'],
                    );
                    setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                    form.validateField(field.name).finally(() => {
                      if (e.target.value) {
                        if (global.pz) {
                          // setFieldValue(`${modifyKey}.global.pz`, '');
                          setFieldValue(`${modifyKey}.global.changedPz`, true);
                        }
                        if (global.mtr) {
                          // setFieldValue(`${modifyKey}.global.mtr`, '');
                          setFieldValue(`${modifyKey}.global.changedMtr`, true);
                        }
                      }
                    });
                  }}
                  InputProps={{
                    ...getInputProps(
                      disabledRest,
                      node.changedMtr && !field.value,
                      isEmpty,
                    ),
                    ref: refMtr,
                  }}
                  customInput={StyledClearableTextField}
                  // isAllowed={(values) => {
                  //   const { formattedValue } = values;
                  //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                  //   // return floatValue < MAX_LIMIT;
                  // }}
                  placeholder={isPercent ? '%' : 'К'}
                  // suffix={isPercent ? '%' : ''}
                  allowNegative={false}
                  thousandSeparator={' '}
                  decimalSeparator=","
                  decimalScale={15}
                  suffix={getSuffix(getBaseName(field.name))}
                  // error={!!fieldState.error}
                  onClear={() => setFieldValue(field.name, '')}
                />
                <PopperComponent message={message} refItem={refMtr.current} />
              </>
            );
          }}
        />

        <Box
          display={'flex'}
          position={'relative'}
          alignItems={'center'}
          justifyContent={'center'}>
          {getHelpIcon()}
          <ClearIcon
            sx={{ position: 'relative', right: '-15px' }}
            onClick={() => {
              console.log(`${modifyKey}.coefficients` + name);
              const update = {
                ...row,
                ...clearData,
              };
              setFieldValue(`${modifyKey}.coefficients` + name, update);
            }}
            cursor={'pointer'}
            color={'secondary'}
          />
        </Box>
        {show && (
          <ListRow
            list={row.children}
            mapKz={mapKz}
            mapIndex={mapIndex}
            name={name + '.children'}
            modifyKey={modifyKey}
          />
        )}
      </Fragment>
    );
  },
);
Row.displayName = 'Row';

const ListRow = React.memo(
  ({
    list,
    name,
    modifyKey,
    mapKz,
    mapIndex,
  }: {
    list: ICoefficientData[];
    name: string;
    modifyKey: 'kz' | 'index';
    mapKz: any;
    mapIndex: any;
  }) => {
    return (
      <Fragment>
        {list?.map((row, index) => {
          return (
            <Row
              mapKz={mapKz}
              mapIndex={mapIndex}
              row={row}
              key={(row.title || '') + index}
              modifyKey={modifyKey}
              name={
                name ? name + '.' + index.toString() : '.' + index.toString()
              }
            />
          );
        })}
      </Fragment>
    );
  },
);
ListRow.displayName = 'ListRow';

export default Parameters;
