import { FullVorColumn } from './Table.types';

export const fullVorColumns: FullVorColumn[] = [
  {
    id: 'lsr',
    title: 'Шифр ЛСР',
    align: 'center',
  },
  {
    id: 'chapter',
    title: 'Раздел',
    align: 'center',
  },
  {
    id: 'header',
    title: 'Заголовок',
    align: 'center',
  },
  {
    id: 'workType',
    title: 'Вид работ',
    align: 'center',
  },
  {
    id: 'number',
    title: '№ по ЛСР',
    align: 'center',
  },
  {
    id: 'code',
    title: 'Код расценки',
    align: 'center',
  },
  {
    id: 'title',
    title: 'Наименование',
    align: 'center',
  },
  {
    id: 'unit',
    title: 'Ед. изм',
    align: 'center',
  },
  {
    id: 'quantity',
    title: 'Кол-во',
    align: 'center',
  },
  {
    id: 'constructionEffortByUnit',
    title: 'ТЗ ОР на ед, чел-ч',
    align: 'center',
  },
  {
    id: 'constructionEffort',
    title: 'ТЗ ОР , чел-ч',
    align: 'center',
  },
  {
    id: 'mechanicsEffortByUnit',
    title: 'ТЗМ на ед, чел-ч',
    align: 'center',
  },
  {
    id: 'mechanicsEffort',
    title: 'ТЗМ, чел-ч',
    align: 'center',
  },
  {
    id: 'totalWorkCostByUnit',
    title: 'Стоимость ЕР, руб.',
    align: 'center',
  },
  {
    id: 'totalWorkCost',
    title: 'Всего стоимость',
    align: 'center',
  },
];
