import {
  format as FnsFormat,
  format,
  isDate,
  isValid,
  parseISO,
} from 'date-fns';

export function toLocalString(d: Date) {
  return d
    .toLocaleDateString('ru-RU')
    .replace(/^(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1')
    .replace(/\//g, '-');
}

export const isSameDate = (dateA: Date, dateB: Date) =>
  dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear();

export function parseDateString(value: any, originalValue: any) {
  if (!originalValue) return null;

  const firstFormattedDate = FnsFormat(originalValue, 'MM/dd/yy');
  const receivedYear = firstFormattedDate.split('/')[2];
  const finalYear =
    receivedYear.length === 2 ? `20${receivedYear}` : receivedYear;
  const secondFormattedDate = [
    ...firstFormattedDate.split('/').slice(0, 2),
    finalYear,
  ];

  const formattedDate = new Date(secondFormattedDate.join('/'));
  const parsedDate = isDate(formattedDate) ? formattedDate : null;

  return parsedDate;
}

export const formatDateToString = (
  date: Date,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string => {
  if (!date) return '';
  const parsedDate = parseDateString('', date);

  if (!parsedDate) return '';
  return FnsFormat(parsedDate, format, options || {});
};

export const formatToDate = (
  dateString: string | null | undefined,
  format?: string,
): Date | null => {
  if (!dateString) return null;

  const splitedDate = dateString.split('.');
  const formattedDate = [splitedDate[1], splitedDate[0], splitedDate[2]].join(
    '/',
  );
  return new Date(formattedDate);
};

export const formatToShortYear = (date: string) => {
  const preShortDate = date.split('.');
  if (preShortDate.length !== 3) return date;

  const shortYear = preShortDate[2].slice(2, 5);
  return [...preShortDate.slice(0, 2), shortYear].join('.');
};

interface DateResponse {
  date: string;
  dateShortYear: string;
  time: string;
  fullTime: string;
}

// 2022-06-31T12:30:00
export const parseResponseDate = (fullDate: string): DateResponse => {
  if (!fullDate) return {} as DateResponse;

  const splitted = fullDate.split('T');
  const splittedDate = splitted[0].split('-');
  const year = splittedDate[0];
  const month = splittedDate[1];
  const day = splittedDate[2];
  const date = `${day}.${month}.${year}`;

  const dateShortYear = formatToShortYear(date);

  const fullTime = splitted[1];
  const time = fullTime.split(':').slice(0, 2).join(':');

  return {
    date,
    dateShortYear,
    time,
    fullTime,
  };
};

interface GetFormatDateProps {
  date: Date | string | undefined;
  fnsFormatPattern?: string;
  withTime?: boolean;
}

export const getFormatDate = ({
  date,
  fnsFormatPattern = 'dd.MM.yyyy',
  withTime = false,
}: GetFormatDateProps) => {
  const dateFormatString = `${fnsFormatPattern}${withTime ? ', HH:mm' : ''}`;

  if (!date) return null;

  if (date instanceof Date) {
    return format(date, dateFormatString);
  }

  if (typeof date === 'string') {
    return isValid(new Date(date))
      ? format(parseISO(date), dateFormatString)
      : null;
  }
};
