import { RowClassParams } from 'ag-grid-community';
import {
  ActList,
  HandbookExecutionRowPartData,
  HandbookExecutionTreeData,
} from 'types';
import { getFormatDate } from 'utils/formatDate';
import { IExecutedTabContext } from '.';

export function formatDateExecutedTab(act: ActList) {
  return (
    getFormatDate({ date: act.startDate || '' }) +
    ' — ' +
    getFormatDate({ date: act.endDate || '' })
  );
}

export function getTotalExecutedTab(
  totalParts: HandbookExecutionRowPartData[],
): HandbookExecutionTreeData {
  const totalItem: HandbookExecutionTreeData = totalParts.reduce(
    (acc, part) => {
      acc.parts = [...(acc.parts ?? []), part];
      return acc;
    },
    { id: -1 } as HandbookExecutionTreeData,
  );

  return totalItem;
}

export function getRowClassExecutedTab(
  params: RowClassParams<HandbookExecutionTreeData, IExecutedTabContext>,
) {
  return params.data?.type === 'FOLDER'
    ? `level-${params.data?.lvl}`
    : params.data?.type === 'RATE'
      ? 'rate'
      : 'rate-position';
}
