import { createContext, useState } from 'react';
import { WithChildren } from 'types';
import { WSContextState, WSContextValue } from './ws.types';
import { UploadType } from 'pages/Lsr/components/WSCustomSnackbar/WSCustomSnackbar.types';

export const wsContext = createContext({} as WSContextValue);

export function WSCProvider({ children }: WithChildren) {
  const [state, setState] = useState(new WSContextState());

  function setLsrIsDrawerOpen(open: boolean) {
    setState((v) => ({
      ...v,
      socketLsr: { ...v.socketLsr, isDrawerOpen: open },
    }));
  }

  function setLsrProjectIDInit(id?: number) {
    setState((v) => ({
      ...v,
      socketLsr: { ...v.socketLsr, projectIDInit: id },
    }));
  }

  function setLsrUploaded(status: UploadType) {
    setState((v) => ({
      ...v,
      socketLsr: { ...v.socketLsr, isUploaded: status },
    }));
  }

  function setImportHandbookDrawerOpen(open: boolean) {
    setState((v) => ({
      ...v,
      socketImportHandbook: { ...v.socketImportHandbook, isDrawerOpen: open },
    }));
  }

  function setImportHandbookUploaded(status: UploadType) {
    setState((v) => ({
      ...v,
      socketImportHandbook: { ...v.socketImportHandbook, isUploaded: status },
    }));
  }

  function setCalcIsDrawerOpen(open: boolean) {
    setState((v) => ({
      ...v,
      socketCalc: { ...v.socketCalc, isDrawerOpen: open },
    }));
  }

  function setCalcProjectIDInit(id?: number) {
    setState((v) => ({
      ...v,
      socketCalc: { ...v.socketCalc, projectIDInit: id },
    }));
  }

  function setCalcUploaded(status: UploadType) {
    setState((v) => ({
      ...v,
      socketCalc: { ...v.socketCalc, isUploaded: status },
    }));
  }

  return (
    <wsContext.Provider
      value={{
        ...state,
        setLsrIsDrawerOpen,
        setLsrProjectIDInit,
        setLsrUploaded,
        setCalcIsDrawerOpen,
        setCalcProjectIDInit,
        setCalcUploaded,
        setImportHandbookDrawerOpen,
        setImportHandbookUploaded,
      }}>
      {children}
    </wsContext.Provider>
  );
}
