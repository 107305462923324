import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { PageStyled } from './FullVor.styles';
import { Table } from './Table';
import Topbar from './Topbar/Topbar';

const FullVor: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  useBreadcrumbs([{ title: 'Ведомость укрупненных расценок' }]);

  return (
    <PageStyled>
      <Topbar isHandbook={isHandbook} />
      <Table isHandbook={isHandbook} />
    </PageStyled>
  );
};

export default FullVor;
