import { Box, Checkbox, InputBase } from '@mui/material';
import styled from 'styled-components';
import { RowMenu } from './components';

export const BodyStyledRow = styled.div`
  width: 3270px;
  color: #2b3648;
  padding: 6px 0;
  height: 44px;
  letter-spacing: 0.17px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 100px 50px 200px 200px 150px 150px 100px 200px 600px 150px 150px 150px 150px 150px 150px 150px 150px;
  align-items: center;
  gap: 20px;
  position: relative;
`;

export const BodyTdStyled = styled(Box)`
  padding-left: 10px;
  // padding-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledRow = styled(Box)<{ $isHover: boolean; $rowType: string }>`
  position: relative;
  border-top: ${({ theme, $isHover, $rowType: $type }) =>
    `1px solid ${$isHover ? ($type !== 'position' ? theme.palette.primary.main : '#d2dcff') : '#d2dcff'}`};

  &:last-child {
    border-bottom: 1px solid #d2dcff;
  }
`;

export const InputStyled = styled(InputBase)`
  border: 1px solid rgba(92, 110, 140, 0.2);
  padding-left: 8px;
  padding-left: 8px;
  border-radius: 4px;
  font-size: 14px;
`;

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
`;

export const StyledRowMenu = styled(RowMenu)`
  position: absolute;
  left: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 19;
`;
