import React from 'react';
import AdminNavBar from '../../components/AdminNavBar';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header';
import { InnerContainer, MainContainer } from '../HomeLayout/styles';

const AdminLayout: React.FC = () => {
  return (
    <>
      <AdminNavBar />
      <MainContainer className="MainContainer">
        <Header />
        <InnerContainer className="InnerContainer">
          <Outlet />
        </InnerContainer>
      </MainContainer>
    </>
  );
};

export default AdminLayout;
