import { Box } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { StyledTreeNodeWrapper } from './TreeNodeRow.styles';
import { TreeNodeRowProps } from './TreeNodeRow.types';
import { CreateLevelForm, MoveGroupForm, Row } from './components';
import { TreeNodeContextConsumer, TreeNodeContextProvider } from './context';

export const TreeNodeRow: FC<TreeNodeRowProps & { isHandbook: boolean }> = memo(
  ({
    rows,
    level,
    levelIds,
    childrenIds: childrenIdsFromProps,
    groupIds: groupIdsFromProps,
    checkboxShowed,
    maxDeep,
    isHandbook,
    ...rest
  }): JSX.Element => {
    const parentLevel = useMemo(() => level, []);
    return (
      <Box>
        {checkboxShowed && <MoveGroupForm isHandbook={isHandbook} />}
        {rows.map((row, index) => {
          let deep: number | undefined = undefined;
          if (level === 0 && Array.isArray(maxDeep)) {
            deep = maxDeep[index];
          }

          return (
            <TreeNodeContextProvider
              maxDeep={deep ?? maxDeep}
              key={row.id}
              rowID={row.id}
              rowTitle={row.title}
              rowType={row.type}
              levelIds={levelIds}
              level={row.level ?? level}
              rowChildren={row.children}>
              <TreeNodeContextConsumer>
                {({
                  selectedIds,
                  levelIds,
                  groupIds,
                  level,
                  childrenIds,
                  formVisible,
                  isExpanded,
                  onSetSelectedIds,
                }) => (
                  <>
                    {formVisible.create && (
                      <CreateLevelForm
                        isHandbook={isHandbook}
                        groupIds={groupIdsFromProps}
                        childrenIds={childrenIdsFromProps}
                      />
                    )}
                    <Row
                      isHandbook={isHandbook}
                      index={index}
                      row={row}
                      parentLevel={parentLevel}
                      parentGroupIds={groupIdsFromProps}
                      checkboxShowed={checkboxShowed}
                      parentChildrenLength={childrenIdsFromProps.length}
                      {...rest}
                    />
                    <StyledTreeNodeWrapper $isExpanded={isExpanded}>
                      <TreeNodeRow
                        isHandbook={isHandbook}
                        maxDeep={deep ?? maxDeep}
                        level={level}
                        rows={row.children}
                        levelIds={levelIds}
                        selectedIds={selectedIds}
                        childrenIds={childrenIds}
                        groupIds={groupIds}
                        checkboxShowed={formVisible.move}
                        onSetSelectedIds={onSetSelectedIds}
                      />
                    </StyledTreeNodeWrapper>
                  </>
                )}
              </TreeNodeContextConsumer>
            </TreeNodeContextProvider>
          );
        })}
      </Box>
    );
  },
);

TreeNodeRow.displayName = 'TreeNodeRow';
