import { UploadType } from 'pages/Lsr/components/WSCustomSnackbar/WSCustomSnackbar.types';

export class WSContextState {
  socketLsr: SocketData = new SocketData();
  socketCalc: SocketData = new SocketData();
  socketImportHandbook: SocketData = new SocketData();
}

export interface WSContextValue extends WSContextState {
  setLsrIsDrawerOpen: (open: boolean) => void;
  setLsrProjectIDInit: (id?: number) => void;
  setLsrUploaded: (status: UploadType) => void;
  setImportHandbookDrawerOpen: (open: boolean) => void;
  setImportHandbookUploaded: (status: UploadType) => void;
  setCalcIsDrawerOpen: (open: boolean) => void;
  setCalcProjectIDInit: (id?: number) => void;
  setCalcUploaded: (status: UploadType) => void;
}

class SocketData {
  isDrawerOpen?: boolean;
  projectIDInit?: number;
  isUploaded: UploadType = 'start';
}
