import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

type AcceptedValue = string | boolean | number | null;

export interface QueryHandler {
  get: <T = string>(key: string) => T | null;
  set: (key: string, value: AcceptedValue) => void;
  remove: (key: string) => void;
}

/**
 * Хук позволяющий взаимодействовать с query параметрами.
 */
export default function useQuery(
  callback?: (handler: QueryHandler) => any,
): QueryHandler {
  const location = useLocation();
  const [query, setQuery] = useState(new URLSearchParams(location.search));

  useEffect(() => {
    setQuery(new URLSearchParams(location.search));
  }, [location.search]);

  const updateURL = useCallback(() => {
    const arr = [
      window.location.origin + window.location.pathname,
      query.toString(),
    ];
    const url = arr.filter(Boolean).join('?');

    window.history.pushState({ path: url }, '', url);
  }, [query]);

  const get = useCallback(
    (key: string) => {
      return query.get(key) as any;
    },
    [query],
  );

  const set = useCallback((key: string, value: AcceptedValue) => {
    query.set(key, String(value));
    updateURL();
  }, []);

  const remove = useCallback((key: string) => {
    query.delete(key);
    updateURL();
  }, []);

  const handler = {
    get,
    set,
    remove,
  };

  useEffect(() => {
    if (callback) callback(handler);
  }, []);

  return handler;
}
