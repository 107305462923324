import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { FieldForm } from 'components/FieldForm';
import { FormItem } from '../FormItem';
import {
  FormSelectField,
  FormTextField,
  FormTitledWrapper,
} from './FormLeftBlock.styles';
import { useFormikContext } from 'formik';
import {
  projectFinTypes,
  projectStatuses,
  projectTypes,
  projectTypeShort,
} from 'types';
import { StyledSelectMenuItem } from 'components/UserManagement/components/UserRoleSelection';
import { useParams } from 'react-router-dom';
import { ProjectFormData } from '../../ProjectManagement.types';
import {
  PROJECT_NAME_MAX_LENGTH,
  PROJECT_FULLNAME_MAX_LENGTH,
} from '../../ProjectManagement.validation';

export const FormLeftBlock: FC = () => {
  const { values: formValues, setFieldValue } =
    useFormikContext<ProjectFormData>();

  const { projectId: projectIdString } = useParams();
  const projectId = Number(projectIdString);

  return (
    <Stack flex={1} maxWidth={600} spacing={2.5}>
      <FormTitledWrapper
        title="О проекте *"
        variant="primary"
        contentWrapper="stack"
        bordered="border">
        <FormItem
          title="Краткое наименование"
          input={
            <FormTextField
              version="project"
              name="shortName"
              placeholder="Укажите краткое наименование проекта"
              maxLength={PROJECT_NAME_MAX_LENGTH}
            />
          }
        />
        <FormItem
          title="Полное наименование"
          input={
            <FormTextField
              version="project"
              name="fullName"
              placeholder="Укажите полное наименование проекта или его описание"
              multiline
              minRows={3}
              maxLength={PROJECT_FULLNAME_MAX_LENGTH}
            />
          }
        />
      </FormTitledWrapper>

      <FormTitledWrapper
        title="Сроки реализации *"
        variant="primary"
        contentWrapper="stack"
        bordered="border">
        <FormItem
          title="Дата начала"
          input={
            <FieldForm
              version="date"
              name="startDate"
              dataValue={formValues.startDate}
              onDataChange={(value: Date | null) =>
                setFieldValue('startDate', value)
              }
            />
          }
        />
        <FormItem
          title="Дата окончания"
          input={
            <FieldForm
              version="date"
              name="endDate"
              dataValue={formValues.endDate}
              onDataChange={(value: Date | null) =>
                setFieldValue('endDate', value)
              }
              dateFieldProps={{
                minDate: formValues.startDate || undefined,
              }}
            />
          }
        />
      </FormTitledWrapper>

      <FormTitledWrapper
        title="Параметры проекта *"
        variant="primary"
        contentWrapper="stack"
        bordered="border">
        <FormItem
          title="Статус проекта"
          input={
            <FormSelectField
              version="select"
              name="status"
              label="Выберите статус проекта"
              disablePlaceholder={!!formValues.status}>
              {projectStatuses.map((status) => (
                <StyledSelectMenuItem value={status} key={status}>
                  <Typography>{status}</Typography>
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          }
        />

        <FormItem
          title="Тип проекта"
          input={
            <FormSelectField
              version="select"
              name="type"
              label="Выберите тип проекта"
              SelectProps={{ disabled: !!projectId }}
              disablePlaceholder={!!formValues.type}>
              {projectTypes.map((type) => (
                <StyledSelectMenuItem value={type} key={type}>
                  <Typography>{projectTypeShort[type]}</Typography>
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          }
        />

        <FormItem
          title="Вид финансирования"
          input={
            <FormSelectField
              version="select"
              name="finType"
              label="Выберите вид финансирования"
              SelectProps={{ disabled: !!projectId }}
              disablePlaceholder={!!formValues.finType}>
              {projectFinTypes.map((type) => (
                <StyledSelectMenuItem value={type} key={type}>
                  <Typography>{type}</Typography>
                </StyledSelectMenuItem>
              ))}
            </FormSelectField>
          }
        />
      </FormTitledWrapper>
    </Stack>
  );
};
