import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { calculationsApi } from '../../../../api/calculations';
import {
  changeModalState,
  clearWorks,
  setExtraWorks,
  setMainWorks,
} from '../../../../store/slices/vor/vor';
import { RootState, useAppDispatch } from '../../../../store/store';
import { toggleButtons } from './TableLegend.constants';
import {
  ButtonWrapper,
  StyledCount,
  StyledToggleButton,
  StyledToggleButtonWrapper,
  TableLegendFormButton,
  TableLegendWrapper,
} from './TableLegend.styles';
import { TableLegendProps } from './TableLegend.types';

export const TableLegend: FC<TableLegendProps> = ({
  selectedTableVariant,
  onTableVariantChange,
  works,
  redirect,
  handleOpenModal,
  countPositions,
  countGroupedPositions,
  isMainWorks,
  clearWorksInRTK,
  selected,
}) => {
  const dispatch = useAppDispatch();
  const { isAddingVor, savingData, deletedRows } = useSelector(
    (state: RootState) => state.vor.table,
  );

  useEffect(() => {
    if (countGroupedPositions === 0) {
      onTableVariantChange('positions');
    }
  }, [countGroupedPositions]);

  const openModal = () => {
    handleOpenModal(true);
    dispatch(clearWorks());
  };

  const addVorHandler = useCallback(() => {
    if (savingData) {
      const [main, extra] = works;
      const newData = { ...savingData };
      newData.body = {
        ...savingData.body,
        main: [...savingData.body.main, ...main.map((_) => _.id)],
        secondary: [...savingData.body.secondary, ...extra.map((_) => _.id)],
      };
      dispatch(setMainWorks(main));
      dispatch(setExtraWorks(extra));
      dispatch(changeModalState());

      const deletedWorks = [...main, ...extra].filter((item) =>
        deletedRows.find((row) => row.id === item.id),
      );
      if (deletedWorks.length) {
        dispatch(
          calculationsApi.util.updateQueryData(
            'getVorPositions',
            { calcId: savingData.calcId },
            (draft) => {
              Object.assign(draft.data, deletedWorks);
            },
          ),
        );
      }
      redirect();
    }
  }, [savingData, isAddingVor, works]);

  const abortAdding = () => {
    clearWorksInRTK();
    dispatch(changeModalState());
    redirect();
  };

  return (
    <TableLegendWrapper>
      <ToggleButtonGroup
        sx={{ height: '32px', boxSizing: 'content-box' }}
        exclusive>
        {toggleButtons
          .filter((value, index) => {
            if (isAddingVor) {
              if (index === 0) {
                return value;
              }
              return null;
            }
            return value;
          })
          .map((button) => (
            <StyledToggleButtonWrapper key={button.value}>
              <StyledToggleButton
                size="small"
                value={button.value}
                selected={button.value === selectedTableVariant}
                disabled={countGroupedPositions === 0}
                onChange={(_, value) => onTableVariantChange(value)}>
                {button.label}
                {button.value !== 'fullVor' && (
                  <StyledCount>
                    {
                      {
                        positions: countPositions,
                        grouped: countGroupedPositions,
                      }[button.value]
                    }
                  </StyledCount>
                )}
              </StyledToggleButton>
            </StyledToggleButtonWrapper>
          ))}
      </ToggleButtonGroup>
      <ButtonWrapper>
        {isAddingVor && (
          <TableLegendFormButton
            color="warning"
            disableElevation
            onClick={abortAdding}>
            Отмена
          </TableLegendFormButton>
        )}
        {selectedTableVariant === 'positions' && (
          <TableLegendFormButton
            sx={{
              '&.Mui-disabled': {
                background: '#9AA2B0',
                color: '#fff',
              },
            }}
            disabled={isAddingVor ? !selected : isMainWorks === 0}
            onClick={() => {
              isAddingVor ? addVorHandler() : openModal();
            }}
            color="success"
            disableElevation>
            {isAddingVor ? 'Добавить' : 'Сформировать укрупненную расценку'}
          </TableLegendFormButton>
        )}
      </ButtonWrapper>
    </TableLegendWrapper>
  );
};
