/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 * @author CyrilStrone<77802640+CyrilStrone@users.noreply.github.com>
 */
import { ActList } from '../types';

export const openDBExecuted = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('store-executed', 1);

    request.onerror = () => {
      reject('Error opening database');
    };

    request.onsuccess = function () {
      resolve(this.result);
    };

    request.onupgradeneeded = function () {
      const db = this.result as IDBDatabase;
      if (!db.objectStoreNames.contains('acts-executed')) {
        db.createObjectStore('acts-executed', { keyPath: 'id' });
      }
    };
  });
};

/**
 * добавление данных в локальную БД
 */
export const addDataExecuted = (
  db: IDBDatabase,
  data: { calcID: number; act: ActList },
): Promise<string> => {
  const transaction = db.transaction(['acts-executed'], 'readwrite');
  const objectStore = transaction.objectStore('acts-executed');
  const dataToStore = { id: data.calcID, act: data.act };
  const request = objectStore.put(dataToStore);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve('Data added');
    request.onerror = () => reject('Error adding data');
  });
};

/**
 * удаление данных из локальной БД
 */
export const deleteDataExecuted = (
  db: IDBDatabase,
  calcID: number,
): Promise<string> => {
  const transaction = db.transaction(['acts-executed'], 'readwrite');
  const objectStore = transaction.objectStore('acts-executed');
  const request = objectStore.delete(calcID);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve('Data deleted');
    request.onerror = () => reject('Error deleting data');
  });
};

/**
 * получение данных из локальной БД
 */
export const getDataExecuted = (
  db: IDBDatabase,
  calcID: number,
): Promise<{ calcID: number; act: ActList }> => {
  const transaction = db.transaction(['acts-executed'], 'readonly');
  const objectStore = transaction.objectStore('acts-executed');
  const request = objectStore.get(calcID);

  return new Promise((resolve, reject) => {
    request.onsuccess = () => resolve(request.result);
    request.onerror = () => reject('Error getting data');
  });
};
