/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Menu, MenuItem } from '@mui/material';
import { useDeleteCalculationMutation } from 'api/calculations';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useProjectId } from 'hooks/useProjectId';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CalcData } from 'types';
import { deleteData, openDB } from 'utils/indexDB';
import { useLazyDownloadHandbvookCalcQuery } from '../../../../../api/calculationDictionary';
import { useCalcId } from '../../../../../hooks/useCalcId';
import { downloadBase64File } from '../../../../../utils/downloadBase64File';
import { HandbookContext } from '../handbook.services';

type TProjectMenuProps = {
  anchor: HTMLElement | null;
  setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>;
  calculation?: CalcData;
  openEdit: () => void;
  isCalc: boolean;
};

const CalcMenu: React.FC<TProjectMenuProps> = ({
  anchor,
  isCalc,
  setAnchor,
  calculation,
  openEdit,
}) => {
  const { t: translateDelete } = useTranslation('calculation');

  const projectID = useProjectId();
  const calcID = useCalcId();
  const navigate = useNavigate();

  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();
  const [downloadCalc] = useLazyDownloadHandbvookCalcQuery();

  const { openUploadDrawer } = React.useContext(HandbookContext);

  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculation?.id || 0 }).then(
        () => {
          openDB().then((db) => deleteData(db, calculation!.id));
        },
      );
    }
  }, []);

  const { ConfirmDialog: ConfirmDialogDelete, openConfirm: openConfirmDelete } =
    useConfirmDialog({
      title: 'Вы уверены?',
      body: 'Расчёт будет удалён и восстановить его будет невозможно',
      handleConfirm: handleConfirmDelete,
    });

  const handleDownloadCalc = React.useCallback(() => {
    downloadCalc(calcID).then(
      (response) =>
        response.data &&
        downloadBase64File(
          response.data,
          `расчет${calculation?.title ? ' ' + calculation?.title : ''}.xlsx`,
        ),
    );
  }, [calcID]);

  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmDelete(actionName);
    },
    [calculation?.id],
  );
  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`, { replace: true });
    enqueueSnackbar(translateDelete('success.calculationDelete'), {
      variant: 'success',
    });
  });
  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={!!anchor}
        onClose={() => setAnchor(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuItem
          onClick={() => handleDeleteCalculationClick('Delete')}
          disabled={deleteCalculationResponse.isLoading}>
          Удалить расчет
        </MenuItem>
        <MenuItem
          onClick={() => {
            openEdit();
            setAnchor(null);
          }}>
          Редактировать
        </MenuItem>
        {isCalc && (
          <>
            <MenuItem onClick={handleDownloadCalc}>Экспорт</MenuItem>
            <MenuItem
              onClick={() => {
                openUploadDrawer(true);
                setAnchor(null);
              }}>
              Загрузить .XLS
            </MenuItem>
          </>
        )}
      </Menu>
      <ConfirmDialogDelete />
    </>
  );
};

export default CalcMenu;
