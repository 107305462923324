import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#0044B4',
      light: '#6D9ADC',
      dark: '#00358C',
    },
    secondary: {
      dark: '#5C6E8C',
      gray: '#7890B2',
      main: '#5C6E8C',
    },
    error: {
      main: '#F46B6B',
    },
    warning: {
      main: '#ED6C02',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
    },
    text: {
      dark: '#2B3648',
      white: '#ffffff',
      main: '#0044B4',
      gray: '#5C6E8C',
    },
    legends: {
      blue: '#6D9ADC',
      green: '#8DD4C8',
      red: '#FFB1B1',
      yellow: '#FFE08F',
      skyblue: '#68C9FF',
      lightblue: '#9CC4FF',
      darkblue: '#576F92',
      orange: '#FE9B3F',
      purple: '#D0AFFF',
      lightgreen: '#6FCCBC',
      gray: '#D2DCFF',
      disabled: '#9AA2B0',
      divider: '#D1D8FA',
    },
    status: {
      blue: '#0044B4',
      green: '#2E7D32',
      orange: '#ED6C02',
      gray: '#5C6E8C',
    },
    bg: {
      gray: '#EDEFF3',
      white: '#ffffff',
      shades: '#F6F7FB',
      selected: '#e8f1fb',
    },
    bgRow: {
      gray: '#EDEFF3',
      white: '#ffffff',
      shades: '#F6F7FB',
    },
    bgLevel: {
      level1: '#e6ecf8',
      level2: '#f2f6fb',
      level3: '#f8fcfc',
      level4: '#fdfbfe',
      level5: '#fff9f9',
      level6: '#f9f6ff',
    },
    bgLevelNew: {
      level1: '#E5ECF7',
      level2: '#F2F6FB',
      level3: '#f8fcfc',
      level4: '#fdfbfe',
      level5: '#fff9f9',
      level6: '#f9f6ff',
    },
    subtypeColor: {
      inactive: '#B8B8B8',
      deleted: '#B8B8B8',
      dont_belong: '#FF0101',
    },
    borderColorLevel: {
      level1: '#7398D5',
      level2: '#AAD5EE',
      level3: '#b9ebe3',
      level4: '#dfb8f6',
      level5: '#FFB1B1',
      level6: '#876c99',
    },

    gridAreas: {
      ГЕНПОДРЯД:
        '"icon title gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract scroll"',
      СУБПОДРЯД:
        '"icon title subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract scroll"',
      АНАЛИЗ:
        '"icon title gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract gencontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract subcontract analysisEstimate analysisEstimate analysisEstimate analysisEstimate scroll"',
    },
    table: {
      yellow: '#FFCD4B',
      error: '#D32F2F',
      errorBg: '#ff0000',
    },
    purple: '#9427D7',
    disabled: '#9AA2B0',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
  },
});

theme.typography.h1 = {
  fontWeight: 500,
  fontSize: '18px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.text.dark}`,
};

theme.typography.h2 = {
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '160%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.primary.main}`,
};

theme.typography.subtitle1 = {
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '175%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.primary.main}`,
};

theme.typography.subtitle2 = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '130%',
  letterSpacing: '0.1px',
  textTransform: 'none',
  color: `${theme.palette.text.dark}`,
};

theme.typography.body1 = {
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '150%',
  letterSpacing: '0.15px',
  textTransform: 'none',
  color: `${theme.palette.text.dark}`,
};

theme.typography.body2 = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  letterSpacing: '0.17px',
  textTransform: 'none',
  color: `${theme.palette.secondary.dark}`,
};

theme.typography.buttonMedium = {
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '171%',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  color: `${theme.palette.secondary.dark}`,
};

theme.typography.buttonSmall = {
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '169%',
  letterSpacing: '0.46px',
  textTransform: 'uppercase',
  color: `${theme.palette.primary.main}`,
};

theme.typography.tooltip = {
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '110%',
  letterSpacing: 0,
  textTransform: 'uppercase',
  color: `${theme.palette.primary.main}`,
};
