import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { useCallback } from 'react';
import Button from '../components/Button';

export interface UseExitConfirmPropsConfidence {
  handleConfirmDelete: (confirm: boolean, actionName: string) => void;
  handleConfirmCopy: (confirm: boolean, actionName: string) => void;
  dialogTitle: string;
}

export interface UseExitConfirmDataConfidence {
  ConfirmDialogConfidence: React.FC;
  openConfirmConfidence: (actionName: string) => void;
  dialogTitle: string;
}

export type ConfirmDialogConfidencePropsType = {
  dialogTitle: string;
};

const useConfirmDialogConfidence = (
  data: UseExitConfirmPropsConfidence,
  dialogTitle: string,
): UseExitConfirmDataConfidence => {
  const { handleConfirmDelete, handleConfirmCopy } = data;

  const [open, setOpen] = React.useState(false);
  const [actionName, setActionName] = React.useState('');

  const openConfirm = useCallback((actionName: string) => {
    setOpen(true);
    setActionName(actionName);
  }, []);

  const onClose = useCallback((confirm: boolean, actionName?: string) => {
    setOpen(false);
    switch (actionName) {
      case 'Delete':
        return handleConfirmDelete(confirm, actionName);
      // break;
      case 'Copy':
        return handleConfirmCopy(confirm, actionName);
      //break;
      default:
        break;
    }
  }, []);

  const ConfirmDialogConfidence: React.FC = () => {
    return (
      <Dialog open={open} onClose={() => onClose(false, actionName)}>
        <Box sx={{ padding: '10px 25px 25px 25px', width: '400px' }}>
          <DialogTitle
            sx={{
              textAlign: 'center',
              fontWeight: 600,
              letterSpacing: '0.15px',
              color: '#0044B4',
            }}>
            {dialogTitle ? dialogTitle : 'Вы действительно хотите выйти?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ textAlign: 'center' }}>
              Внесённые данные не сохранятся.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              onClick={() => onClose(true, actionName)}
              color="success">
              Подтвердить
            </Button>
            <Button fullWidth onClick={() => onClose(false)} autoFocus>
              Отменить
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  };
  return {
    ConfirmDialogConfidence,
    openConfirmConfidence: openConfirm,
    dialogTitle,
  };
};

export default useConfirmDialogConfidence;
