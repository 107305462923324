import { ReactComponent as TangleIcon } from 'assets/icons/tanglIcon.svg';

import {
  CustomButton,
  IntegrateContainer,
  IntegrateTitle,
} from './TangleIntegrate.style';
import { resolver } from './TangleIntegrate.types';
import { PageStyled } from '../../../Calculations/components/Accomplishment/Accomplishment.styles';
import { ReactComponent as CloudGearIcon } from 'assets/icons/cloudGear.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/cloudSuccess.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/cloudError.svg';
import Button from '../../../../components/Button';
import { CircularProgress, Stack } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  useChangeStatusTanglMutation,
  useConnectToTangleMutation,
  useDeleteAccTangleMutation,
  useLazyCheckStatusConnectionQuery,
  useLazyGetCredentialsQuery,
} from '../../../../api/tangl';
import { ITangleData } from '../../../../api/tangl/tangl.types';
import FieldBlock from './components/FieldBlock';
import useConfirmDialog from '../../../../hooks/useConfirmDialog';

const defaultValues = {
  credentials: {
    secret: '',
    clientID: '',
    password: '',
    username: '',
  },
  isEnabled: false,
};

function TanglIntegrate() {
  const [
    getCredentials,
    { data: credentialsData, isFetching: isFetchingCredentials },
  ] = useLazyGetCredentialsQuery({});
  const [
    getStatus,
    { data: statusData, isFetching: isFetchingStatus, error, isError },
  ] = useLazyCheckStatusConnectionQuery({});
  // const { data: credentialsData, isFetching: isFetchingCredentials } = useGetCredentialsQuery({});
  const [deleteCredentials] = useDeleteAccTangleMutation();
  const [changeStatus] = useChangeStatusTanglMutation();
  const [connect] = useConnectToTangleMutation();
  // const { data: statusData, isFetching: isFetchingStatus, error, isError } = useCheckStatusConnectionQuery();
  const [moreData, setMoreData] = useState(false);

  const isCredentialsBody = useRef(false);

  const isFetch = useMemo(() => {
    return isFetchingStatus || isFetchingCredentials;
  }, [isFetchingStatus, isFetchingCredentials]);

  const methods = useForm<Required<ITangleData>>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver,
    defaultValues,
  });

  const handleSubmitConnectTangl = ({ credentials }: Required<ITangleData>) => {
    console.log('create>>>', credentials);
    connect({ credentials }).then((response) => {
      if ('data' in response) {
        methods.reset(response.data);
        getStatus();
      }
    });
  };
  const handleSubmitChangeStatus = ({ credentials }: Required<ITangleData>) => {
    console.log('update>>>', credentials);
    changeStatus({
      body: { credentials },
      params: {
        enabled: !credentialsData?.isEnabled,
      },
    }).then((response) => {
      if ('data' in response) {
        methods.reset(response.data);
        getStatus();
      }
    });
  };
  const StatusIcon = useMemo(() => {
    console.log(statusData);
    if (!statusData?.status)
      return (
        <div className={'flex flex-row icon-title icon-title-info'}>
          <CloudGearIcon />
          Настройка подключения
        </div>
      );
    if (isError) {
      return (
        <div className={'flex flex-row icon-title icon-title-error'}>
          <ErrorIcon />
          Ошибка
        </div>
      );
    }
    console.log('after switch', statusData);
    switch (statusData.status) {
      case 'DISABLED':
      case 'NO_CREDENTIALS':
        return (
          <div className={'flex flex-row icon-title icon-title-info'}>
            <CloudGearIcon />
            Настройка подключения
          </div>
        );
      case 'OK':
        return (
          <div className={'flex flex-row icon-title icon-title-success'}>
            <SuccessIcon />
            Подключено
          </div>
        );
      case 'BAD_CREDENTIALS':
        return (
          <div className={'flex flex-row icon-title icon-title-error'}>
            <ErrorIcon />
            Ошибка
          </div>
        );
      default:
        return (
          <div className={'flex flex-row icon-title icon-title-error'}>
            <ErrorIcon />
            Ошибка
          </div>
        );
    }
  }, [statusData, isFetch, isError]);

  const isCreate = useMemo(() => {
    return (
      !methods.getValues('isEnabled') ||
      statusData?.status === 'BAD_CREDENTIALS' ||
      statusData?.status === 'NO_CREDENTIALS'
    );
  }, [statusData, methods.watch('isEnabled')]);

  const {
    openConfirm: confirmResetCredentials,
    ConfirmDialog: ConfirmResetCredentials,
  } = useConfirmDialog({
    handleConfirm: (confirm, fn) => {
      if (confirm) {
        deleteCredentials().then(() => {
          methods.reset(defaultValues);
          getStatus();
        });
      }
    },
    title: 'Сбросить настройки',
    body: (
      <span>
        Настройки будут сброшены по умолчанию,
        <br /> а все данные удалены
      </span>
    ),
  });

  useEffect(() => {
    getStatus().then(() =>
      getCredentials({}).then((response) => {
        if ('data' in response) {
          if (response.data?.credentials) {
            setMoreData(true);
            methods.reset(response.data);
          } else {
            setMoreData(false);
            methods.reset(defaultValues);
          }
        } else {
          setMoreData(false);
          methods.reset(defaultValues);
        }
      }),
    );
  }, []);
  console.log(methods.watch('isEnabled'));
  return (
    <PageStyled>
      <IntegrateTitle>Настройки интеграции по API Tangle</IntegrateTitle>
      <FormProvider {...methods}>
        <IntegrateContainer className={'container-bordered grid'}>
          <div className={'block'}>
            <div className={'flex flex-row'}>
              <TangleIcon />
              <p className={'icon-title'}>Tangl</p>
            </div>
          </div>
          {isFetch ? (
            <Stack
              flex={1}
              alignItems="center"
              justifyContent="center"
              height="100%">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <div className={'block'}>
                <div className={'flex'}>
                  <p className={'title'}>Статус подключения</p>
                </div>
                <div className={'flex flex-between'}>
                  {moreData ? (
                    StatusIcon
                  ) : (
                    <CustomButton onClick={() => setMoreData(true)}>
                      Настроить интеграцию
                    </CustomButton>
                  )}
                </div>
              </div>
            </>
          )}
          {!isFetchingCredentials && moreData && (
            <>
              <div className={'block'}>
                <div className={'flex'}>
                  <p className={'title'}>Ключ API и Secret для подключения</p>
                  <p className={'description'}>
                    Для получения идентификаторов обратитесь к администратору
                    Tangl
                  </p>
                </div>
                <FieldBlock
                  disabled={statusData?.status === 'OK'}
                  names={[
                    {
                      name: 'Ключ API',
                      key: 'credentials.clientID',
                      type: 'text',
                    },
                    {
                      name: 'Secret',
                      key: 'credentials.secret',
                      type: 'password',
                    },
                  ]}
                />
              </div>
              <div className={'block'}>
                <div className={'flex'}>
                  <p className={'title'}>Данные аккаунта</p>
                  <p className={'description'}>
                    Для авторизации подключения укажите логин и пароль от вашей
                    учётной записи Tangl
                  </p>
                </div>
                <FieldBlock
                  disabled={statusData?.status === 'OK'}
                  names={[
                    {
                      name: 'Логин',
                      key: 'credentials.username',
                      type: 'text',
                    },
                    {
                      name: 'Пароль',
                      key: 'credentials.password',
                      type: 'password',
                    },
                  ]}
                />
              </div>
              <div className={'block'}>
                <span />

                <div className={'flex flex-row'}>
                  <Button
                    onClick={methods.handleSubmit(
                      isCreate || methods.formState.isDirty
                        ? handleSubmitConnectTangl
                        : handleSubmitChangeStatus,
                    )}
                    type={'submit'}
                    fullWidth
                    style={{ color: 'white' }}
                    color={statusData?.status === 'OK' ? 'error' : 'success'}>
                    {statusData?.status === 'OK' ? 'Отключиться' : 'Подключить'}
                  </Button>
                  <Button
                    onClick={() => {
                      confirmResetCredentials();
                    }}
                    type={'button'}
                    fullWidth
                    style={{ color: 'white' }}>
                    Сбросить
                  </Button>
                </div>
              </div>
            </>
          )}
        </IntegrateContainer>
      </FormProvider>
      <ConfirmResetCredentials />
    </PageStyled>
  );
}

export default TanglIntegrate;
