/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import React from 'react';
import { Item, LeftMenu } from '../Parameters.style';
import { TTab } from '../Parameters.types';

const tabs: {
  name: 'indirect_costs' | 'value_changes' | 'acts';
  value: string;
}[] = [
  {
    name: 'acts',
    value: 'Акты по периодам',
  },
  {
    name: 'indirect_costs',
    value: 'Косвенные затраты',
  },
  {
    name: 'value_changes',
    value: 'Коэффициент изменения стоимости',
  },
];

const Menu: React.FC<{
  tab: TTab;
  setTab: (str: TTab) => void;
  isActs: boolean;
}> = React.memo(({ tab, setTab, isActs }) => {
  const list = React.useMemo(() => {
    return !isActs ? tabs.filter((tab) => tab.name !== 'acts') : tabs;
  }, [isActs]);
  return (
    <LeftMenu>
      {list.map((item) => {
        return (
          <Item
            active={item.name === tab}
            onClick={() => item.name !== tab && setTab(item.name)}>
            {item.value}
          </Item>
        );
      })}
    </LeftMenu>
  );
});

export default Menu;
