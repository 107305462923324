import { api } from '../api';
import {
  AuthorizationSuccess,
  LoginByCode,
  LoginByPhone,
  LoginByToken,
  LoginResponse,
  SendSmsResponse,
} from './types';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    loginByToken: build.mutation<LoginResponse, LoginByToken>({
      query: ({ token }) => ({
        url: `/auth/from-core`,
        method: 'GET',
        params: { token },
      }),
      invalidatesTags: () => ['Profile'],
    }),
    sendSms: build.mutation<SendSmsResponse, LoginByPhone>({
      query: ({ phone }) => ({
        url: `/auth/login-by-phone`,
        method: 'POST',
        body: { phone },
      }),
      invalidatesTags: () => ['Profile', 'ProfileByCompany'],
    }),
    loginByPhone: build.mutation<LoginResponse, any>({
      query: ({ phone, code }) => ({
        url: `/auth/apply-phone-code`,
        method: 'POST',
        body: { phone, code },
      }),
      invalidatesTags: () => ['Profile', 'ProfileByCompany', 'Companies'],
    }),
    sendConfirmationEmail: build.mutation<AuthorizationSuccess, LoginByCode>({
      query: ({ email }) => ({
        url: `/auth/login-by-email-code`,
        method: 'POST',
        body: { email },
      }),
    }),
    confirmEmailByCode: build.mutation<AuthorizationSuccess, LoginByCode>({
      query: ({ code, email }) => {
        return {
          method: 'POST',
          url: `/auth/apply-email-code`,
          body: { code, email },
        };
      },
      invalidatesTags: ['Profile'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginByTokenMutation,
  useSendSmsMutation,
  useLoginByPhoneMutation,
  useSendConfirmationEmailMutation,
  useConfirmEmailByCodeMutation,
} = authApi;
