import styled from 'styled-components';

export const PageStyled = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
`;
