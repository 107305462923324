import * as yup from 'yup';
import { i18n } from 'i18n/index';

const today = new Date();
const maxDate = new Date('2099-12-31');
export const PROJECT_NAME_MAX_LENGTH = 128;
export const PROJECT_FULLNAME_MAX_LENGTH = 200;

export const validationProject = yup.object({
  shortName: yup
    .string()
    .trim()
    .required('common:errors.required')
    .max(
      PROJECT_NAME_MAX_LENGTH,
      i18n.t('common:errors.max', { maxLength: PROJECT_NAME_MAX_LENGTH }),
    ),
  fullName: yup.string().trim().required('common:errors.required'),
  status: yup.string().required('common:errors.required'),
  type: yup.string().required('common:errors.required'),
  finType: yup.string().required('common:errors.required'),
  startDate: yup
    .date()
    .max(maxDate, 'Макс. 2099г')
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
  endDate: yup
    .date()
    .min(today, 'Дата не может быть раньше сегодняшней')
    .min(yup.ref('startDate'), 'Дата окончания должна быть позже даты начала')
    .max(maxDate, 'Макс. 2099г')
    .nullable()
    .required('common:errors.required')
    .typeError('Неверный формат даты'),
});
