/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import styled from 'styled-components';

export const PinnedLevel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 65px;
  gap: 24px;

  color: #668fd2;
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  svg path {
    fill: #668fd2;
  }
`;

export const Level = styled.div<{ floatRight?: boolean; arrowClose: boolean }>`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  svg {
    margin: 7px;
    rotate: 0deg;
    transition: rotate linear 0.2s;
    ${({ floatRight }) => floatRight && '&:last-child {margin-right: 0;}'}
    ${({ arrowClose }) => arrowClose && '&:first-child {rotate: 180deg;}'}
  }
`;
