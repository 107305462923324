import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import useWebSocket, {
  ReadyState,
  resetGlobalState,
} from 'react-use-websocket';

import { useTypedSelector } from 'store/store';
import {
  ConnectionStatusType,
  ProviderWSProps,
  SOCKET_URL_LSR,
  WSContextProps,
  WSMessageBody,
  WSMessageTypeGet,
  WSMessageTypeSend,
} from '.';

const WSContext = createContext<WSContextProps | null>(null);

export const useWS = () => {
  const context = useContext(WSContext);
  if (!context) {
    throw new Error('useWS must be used within an ProviderWS');
  }
  return context;
};

export const ProviderWS: React.FC<ProviderWSProps> = (props) => {
  const [uploadFileLsrProgress, setUploadFileLsrProgress] =
    useState<WSContextProps['uploadFileLsrProgress']>();
  const [uploadFileLsrStatusOk, setUploadFileLsrStatusOk] =
    useState<WSContextProps['uploadFileLsrStatusOk']>();
  const [uploadFileLsrErrors, setUploadFileLsrErrors] =
    useState<WSContextProps['uploadFileLsrErrors']>();

  const { token } = useTypedSelector((state) => state.auth);

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(
    SOCKET_URL_LSR,
    {
      share: true,
      onOpen: () => {
        sendSubscribeAuth();
      },
      shouldReconnect: () => {
        return true;
      },
    },
  );

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState] as ConnectionStatusType;

  useEffect(() => {
    console.log('WS. connectionStatus:', connectionStatus);
  }, [connectionStatus]);

  const canSendMessages = readyState === ReadyState.OPEN;

  const clearUploadFileLsrProgress = useCallback(
    () => setUploadFileLsrProgress(undefined),
    [],
  );
  const clearUploadFileLsrStatusOk = useCallback(
    () => setUploadFileLsrStatusOk(undefined),
    [],
  );
  const clearUploadFileLsrErrors = useCallback(
    () => setUploadFileLsrErrors(undefined),
    [],
  );

  const sendSubscribeAuth = useCallback(() => {
    if (token) {
      sendJsonMessage({
        type: WSMessageTypeSend.auth,
        bodyJson: JSON.stringify({ token: token }),
      });
    }
  }, [sendJsonMessage, token]);

  const uploadFileLsr = useCallback(
    (props: WSMessageBody['upload_file_lsr']) => {
      if (token && canSendMessages) {
        sendJsonMessage({
          type: WSMessageTypeSend.upload_file_lsr,
          bodyJson: JSON.stringify(props),
        });
      }
    },
    [token, canSendMessages, sendJsonMessage],
  );

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const { data, event, type, bodyJson } = JSON.parse(lastMessage.data);
      switch (type) {
        case WSMessageTypeGet.upload_progress: {
          const res = JSON.parse(
            bodyJson,
          ) as WSMessageBody[WSMessageTypeGet.upload_progress];
          console.log(res, WSMessageTypeGet.upload_progress);

          setUploadFileLsrProgress(res);
          break;
        }
        case WSMessageTypeGet.status_ok: {
          const res = JSON.parse(
            bodyJson,
          ) as WSMessageBody[WSMessageTypeGet.status_ok];
          console.log(res, WSMessageTypeGet.status_ok);
          setUploadFileLsrStatusOk(res);
          break;
        }
        case WSMessageTypeGet.with_errors: {
          const res = JSON.parse(
            bodyJson,
          ) as WSMessageBody[WSMessageTypeGet.with_errors];
          console.log(res, WSMessageTypeGet.with_errors);
          setUploadFileLsrErrors(res);
          break;
        }
        default:
          break;
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    return () => {
      resetGlobalState(SOCKET_URL_LSR);
    };
  }, []);

  return (
    <WSContext.Provider
      value={{
        connectionStatus,
        uploadFileLsr,
        uploadFileLsrProgress,
        uploadFileLsrStatusOk,
        readyState,
        uploadFileLsrErrors,
        clearUploadFileLsrProgress,
        clearUploadFileLsrStatusOk,
        clearUploadFileLsrErrors,
      }}>
      {props.children}
    </WSContext.Provider>
  );
};
