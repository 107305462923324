import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useGetAvailableGroupsMutation } from 'api/calculations';
import { VORAvailableGroup } from 'api/calculations/types';
import { MoveGroup } from 'assets/SVG/MoveGroup';
import { LevelFolder } from 'components/LevelFolder';
import { useCalcId } from 'hooks/useCalcId';
import { useTransferElement } from 'pages/FullVor/Table/hooks';
import { FC, useEffect, useState } from 'react';
import { useTreeNodeContext } from '../../../../context';
import { GroupCount } from '../GroupCount';
import {
  StyledAutocomplete,
  StyledMoveGroupFrom,
  StyledText,
} from './MoveGroupForm.styles';

export const MoveGroupForm: FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  const calcID = useCalcId();
  const {
    selectedIds,
    groupIds,
    childrenIds,
    isAllSelected,
    indeterminate,
    onSelectAllIds,
    onToggleFormVisible,
  } = useTreeNodeContext();
  const { transferElement } = useTransferElement(isHandbook);
  const [getAvailableGroups, { data: options, isLoading, reset }] =
    useGetAvailableGroupsMutation();
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] =
    useState<VORAvailableGroup | null>(null);

  const selectedChildrenCount = selectedIds.childrenIds.length;
  const selectedGroupCount = selectedIds.groupIds.length;
  const isMoveButtonDisabled =
    (selectedChildrenCount === 0 && selectedGroupCount === 0) ||
    !selectedOption;

  useEffect(() => {
    if (!open) reset();
  }, [open]);

  useEffect(() => {
    if (!open) return;

    getAvailableGroups({
      params: {
        calcID,
      },
      body: {
        childrenID: selectedIds.childrenIds,
        groupID: selectedIds.groupIds,
      },
    });
  }, [open]);

  const onMoveClickHandler = () => {
    if (selectedOption) {
      transferElement({
        calcID,
        childrenIds: selectedIds.childrenIds,
        groupIds: selectedIds.groupIds,
        to: selectedOption.id,
      });

      closeMoveForm();
    }
  };

  const selectAllChangeHandler = () => {
    onSelectAllIds({ childrenIds, groupIds });
  };

  const closeMoveForm = () => {
    onToggleFormVisible('move');
  };

  const toggleOpenAutocompleteHandler = () => {
    setOpen((prev) => !prev);
  };

  return (
    <StyledMoveGroupFrom
      direction="row"
      alignItems="center"
      spacing={2}
      onClick={(e) => e.stopPropagation()}>
      <Checkbox
        checked={isAllSelected}
        indeterminate={indeterminate}
        onChange={selectAllChangeHandler}
      />
      <MoveGroup fontSize="small" />
      <StyledText>Переместить в группу:</StyledText>
      <Box>
        <StyledAutocomplete
          options={options?.items ?? []}
          value={selectedOption}
          noOptionsText="Нет опций"
          openText="Открыть"
          clearText="Очистить"
          closeText="Закрыть"
          loadingText="Загрузка..."
          open={open}
          loading={isLoading}
          getOptionLabel={(option) => option.name}
          onChange={(_, value) => setSelectedOption(value)}
          onOpen={toggleOpenAutocompleteHandler}
          onClose={toggleOpenAutocompleteHandler}
          renderOption={(props, option) => (
            <Stack component="li" direction="row" {...props} key={option.id}>
              <LevelFolder level={option.lvl} />
              <Typography pl={1}>{option.name}</Typography>
            </Stack>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Выберите группу для перемещения из списка"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Box>
      <GroupCount
        childrenCount={selectedChildrenCount}
        groupCount={selectedGroupCount}
        description="Будет перемещено"
      />
      <Stack direction="row" spacing={1}>
        <Button
          color="success"
          size="small"
          disableElevation
          disabled={isMoveButtonDisabled}
          onClick={onMoveClickHandler}>
          Переместить
        </Button>
        <Button size="small" disableElevation onClick={closeMoveForm}>
          Отменить
        </Button>
      </Stack>
    </StyledMoveGroupFrom>
  );
};
