/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { ICalculationDirectory } from '../CalculationTab/CalculationDirectory.types';
import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import {
  CHECKBOX_CELL_WIDTH,
  CODE_CELL_WIDTH,
  CONSTRUCTIONS_CELL_WIDTH,
  MECHANICS_CELL_WIDTH,
  QUANTITY_CELL_WIDTH,
  TOTAL_COST_CELL_WIDTH,
  UNIT_CELL_WIDTH,
} from '../../../../../Vor/components/PositionsTable';
import Cell from '../../../Accomplishment/components/Cell';
import React from 'react';
import { IVorPosition } from '../../../../../../api/calculationDictionary';
import { CheckboxCell } from '../../../../../Vor/components/TableLegend/cells/CheckboxCell';
import { Stack } from '@mui/system';
import {
  AgGridReactProps,
  AgReactUiProps,
} from 'ag-grid-react/lib/shared/interfaces';
import Progress from 'components/Progress';
import { VorTableVariant } from '../../../../../Vor';
import headerComponent from './components/HeaderComponent';

export interface IVorTabProps extends ICalculationDirectory {}

function generateDynamicFields(
  deep: number,
): (ColDef<IVorPosition, any> | ColGroupDef<IVorPosition>)[] {
  const resultArr: (ColDef<IVorPosition, any> | ColGroupDef<IVorPosition>)[] =
    [];
  console.log(deep);
  for (let i = 0; i < deep; i++) {
    const level = i + 1;
    const headerName = `Уровень ${level}`;
    resultArr.push({
      headerName,
      headerComponent,
      // hide                 : level>3&&!collapse,
      sortIndex: 3 + i,
      headerComponentParams: { showBtn: level === 3 && deep > 3 },
      field: `dynamicItems.${i}`,
      minWidth: 200,
      cellRenderer: (props: ICellRendererParams<IVorPosition, any, any>) => {
        return <Cell>{props.value?.title || ''}</Cell>;
      },
    });
  }
  return resultArr;
}

export function useTable(
  update: any,
  variant: VorTableVariant,
  deep?: number,
): (ColDef<IVorPosition, any> | ColGroupDef<IVorPosition>)[] {
  let hide = false;
  if (variant === 'grouped') {
    hide = true;
  }
  if (deep === undefined) {
    return [];
  }
  const dynamicColumns = generateDynamicFields(deep);
  return [
    {
      headerName: 'Осн',
      hide,
      field: 'type',
      minWidth: 55,
      maxWidth: 55,
      width: 55,
      sortIndex: 0,
      suppressMovable: true,
      resizable: false,
      cellRenderer: ({
        data,
        value,
        node,
        rowIndex,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        const rowColor =
          (data?.type === 'main' && 'info') ||
          (data?.type === 'extra' && 'warning') ||
          undefined;
        const props = {
          label: 'Осн',
          style: {
            minWidth: CHECKBOX_CELL_WIDTH,
            maxWidth: CHECKBOX_CELL_WIDTH,
            width: CHECKBOX_CELL_WIDTH,
          },
        };
        // return <Cell>{value || ""}</Cell>;
        return (
          <Stack
            display={'flex'}
            key={'main'}
            alignItems={'center'}
            justifyContent={'center'}>
            {data && (
              <CheckboxCell
                indexVor={node.rowIndex}
                align={'center'}
                type={'main'}
                update={update}
                color={rowColor}
                iconProps={{ color: 'info' }}
                key={`${data.id}-render-main`}
                vor={data as IVorPosition}
                {...props}
              />
            )}
          </Stack>
        );
      },
    },
    {
      headerName: 'Всп',
      hide,
      field: 'type',
      minWidth: 55,
      maxWidth: 55,
      width: 55,
      sortIndex: 1,
      suppressMovable: true,
      resizable: false,
      cellRenderer: ({
        data,
        value,
        node,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        const rowColor =
          (data?.type === 'main' && 'info') ||
          (data?.type === 'extra' && 'warning') ||
          undefined;
        const props = {
          label: 'Всп',
          style: {
            minWidth: CHECKBOX_CELL_WIDTH,
            maxWidth: CHECKBOX_CELL_WIDTH,
            width: CHECKBOX_CELL_WIDTH,
          },
        };
        // return <Cell>{value || ""}</Cell>;
        return (
          <Stack
            display={'flex'}
            key={'extra'}
            alignItems={'center'}
            justifyContent={'center'}>
            {data && (
              <CheckboxCell
                indexVor={node.rowIndex}
                color={rowColor}
                update={update}
                align={'center'}
                type={'extra'}
                iconProps={{ color: 'warning' }}
                key={`${data.id}-render-extra`}
                vor={data as IVorPosition}
                {...props}
              />
            )}
          </Stack>
        );
      },
    },
    {
      headerName: '№ п/п',
      field: 'num',
      minWidth: 90,
      // maxWidth    : 90,
      // width       : 90,
      sortIndex: 2,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return <Cell float={'center'}>{value || ''}</Cell>;
      },
    },
    // {
    //   headerName     : "Шифр ЛСР",
    //   // headerComponent: HeaderComponent,
    //   field          : "lsr",
    //   minWidth       : LSR_CELL_WIDTH,
    //   maxWidth       : 500,
    //   cellRenderer   : ({ data, value }: ICellRendererParams<IVorPosition, any, any>) => {
    //     return <Cell>{value || ""}</Cell>;
    //   }
    // },
    // {
    //   headerName     : "№ по ЛСР",
    //   // headerComponent: HeaderComponent,
    //   field          : "number",
    //   minWidth       : 150,
    //   maxWidth       : 150,
    //   width          : 150,
    //   // minWidth: LSR_NUMBER_CELL_WIDTH,
    //   // maxWidth: LSR_NUMBER_CELL_WIDTH,
    //   // width: LSR_NUMBER_CELL_WIDTH,
    //   cellRenderer: ({ data, value }: ICellRendererParams<IVorPosition, any, any>) => {
    //     return <Cell float={"center"}>{value || ""}</Cell>;
    //   }
    // },
    // {
    //   headerName     : "Раздел",
    //   // headerComponent: HeaderComponent,
    //   field          : "chapter",
    //   minWidth       : CHAPTER_CELL_WIDTH,
    //   maxWidth       : 500,
    //   cellRenderer   : ({ data, value }: ICellRendererParams<IVorPosition, any, any>) => {
    //     return <Cell>{value || ""}</Cell>;
    //   }
    // },
    // {
    //   headerName     : "Заголовок",
    //   // headerComponent: HeaderComponent,
    //   field          : "title",
    //   minWidth       : 150,
    //   maxWidth       : 350,
    //   cellRenderer   : ({ data, value }: ICellRendererParams<IVorPosition, any, any>) => {
    //     return <Cell>{value || ""}</Cell>;
    //   }
    // },
    ...dynamicColumns,
    {
      headerName: 'Код расценки',
      // headerComponent: HeaderComponent,
      field: 'code',
      minWidth: CODE_CELL_WIDTH,
      // maxWidth    : CODE_CELL_WIDTH,
      // width       : CODE_CELL_WIDTH,
      sortIndex: 3 + dynamicColumns.length,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return <Cell float={'center'}>{value || ''}</Cell>;
      },
    },
    {
      headerName: 'Наименование',
      // headerComponent: HeaderComponent,
      field: 'title',
      minWidth: 370,
      // maxWidth    : 550,
      sortIndex: 4 + dynamicColumns.length,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return <Cell>{value || ''}</Cell>;
      },
    },
    {
      headerName: 'Ед. изм',
      field: 'unit',
      minWidth: UNIT_CELL_WIDTH,
      // maxWidth       : UNIT_CELL_WIDTH,
      // width          : UNIT_CELL_WIDTH,
      suppressMovable: true,
      sortIndex: 5 + dynamicColumns.length,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return <Cell float={'center'}>{value || ''}</Cell>;
      },
    },
    {
      headerName: 'Кол-во',
      field: 'quantity',
      minWidth: QUANTITY_CELL_WIDTH,
      // maxWidth       : QUANTITY_CELL_WIDTH,
      // width          : QUANTITY_CELL_WIDTH,
      sortIndex: 6 + dynamicColumns.length,
      suppressMovable: true,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        console.log(
          value,
          value?.toLocaleString('ru-RU', {
            minimumFractionDigits: 2,
          }),
        );
        return (
          <Cell float={'center'}>
            {value?.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }) || ''}
            {/*{data?.quantity?.toLocaleString('ru-RU', {*/}
            {/*  minimumFractionDigits: 0,*/}
            {/*  maximumFractionDigits: 8,*/}
            {/*})}*/}
          </Cell>
        );
      },
    },
    {
      headerName: 'ТЗ ОР, чел-ч',
      field: 'tz',
      minWidth: CONSTRUCTIONS_CELL_WIDTH,
      // maxWidth       : CONSTRUCTIONS_CELL_WIDTH,
      // width          : CONSTRUCTIONS_CELL_WIDTH,
      sortIndex: 7 + dynamicColumns.length,
      suppressMovable: true,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return (
          <Cell float={'right'}>
            {value?.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
            }) || ''}
          </Cell>
        );
      },
    },
    {
      headerName: 'ТЗМ, чел-ч',
      field: 'tzm',
      minWidth: MECHANICS_CELL_WIDTH,
      // maxWidth       : MECHANICS_CELL_WIDTH,
      // width          : MECHANICS_CELL_WIDTH,
      sortIndex: 8 + dynamicColumns.length,
      suppressMovable: true,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return (
          <Cell float={'right'}>
            {value?.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
            }) || ''}
          </Cell>
        );
      },
    },
    {
      headerName: 'Всего стоимость',
      field: 'total',
      minWidth: TOTAL_COST_CELL_WIDTH,
      // maxWidth       : TOTAL_COST_CELL_WIDTH,
      // width          : TOTAL_COST_CELL_WIDTH,
      sortIndex: 9 + dynamicColumns.length,
      suppressMovable: true,
      cellRenderer: ({
        data,
        value,
      }: ICellRendererParams<IVorPosition, any, any>) => {
        return (
          <Cell float={'right'}>
            {value?.toLocaleString('ru-RU', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) || ''}
          </Cell>
        );
      },
    },
  ];
}

function emptyLevel(lvl: number) {
  return {
    id: null,
    lvl,
    title: '',
  };
}

export function generateLevels<T>(data: T[], deep: number) {
  let copyData: T[] = JSON.parse(JSON.stringify(data));
  const initLength = copyData.length;

  if (initLength < deep) {
    for (let i = initLength; i < deep; i++) {
      copyData.push(emptyLevel(i + 1) as T);
    }
  }
  return copyData;
}

export const defaultProps:
  | AgGridReactProps<IVorPosition>
  | AgReactUiProps<IVorPosition> = {
  // ref={Ref}
  enableCellTextSelection: true,
  ensureDomOrder: true,
  maintainColumnOrder: true,
  defaultColDef: {
    suppressMovable: true,
    resizable: true,
  },
  // columnDefs={columnsDef}
  groupHeaderHeight: 40,
  singleClickEdit: true,
  gridOptions: {
    // suppressDragLeaveHidesColumns: true,
    suppressAnimationFrame: true,
    navigateToNextHeader: () => null,
    tabToNextHeader: () => null,
  },
  // rowData={table.data}
  suppressCellFocus: true,
  getRowId: (params) => {
    return params.data.id.toString();
  },
  rowStyle: {
    padding: '0 !important',
  },
  rowHeight: 55,
  headerHeight: 40,
  loadingOverlayComponent: Progress,
  noRowsOverlayComponent: () => <>нет данных</>,
};
// enableCellTextSelection={true}
// ensureDomOrder={true}
// maintainColumnOrder={true}
// // rowSelection={'single'}
// // onRowSelected={(event) => {
// //}}
// // onCellFocused={(event) => {
// //   let row: any;
// //   if (event.rowPinned === 'top') return;
// //   Ref.current?.api.forEachNode((_) => {
// //     if (_.rowIndex === event.rowIndex) {
// //       // console.log(_.data);
// //       row = _.data;
// //     }
// //   }),
// //   row && setSelectedRow(row as ICalculationDictionary);
// // }}
// groupHeaderHeight={40}
// singleClickEdit
// // isRowExpanded={isRowExpanded}
// // getRowClass={(params: RowClassParams<ICalculationDictionary, any>) => {
// //   return params.data?.rowType === "FOLDER" ? `level-${params.data?.lvl}` : params.data?.rowType === "RATE" ? "rate" : "level-12";
// //   // return `level-${params.data?.lvl}`;
// // }}
// gridOptions={{
//   // suppressDragLeaveHidesColumns: true,
//   suppressAnimationFrame: true,
//   navigateToNextHeader  : () => null,
//   tabToNextHeader       : () => null
// }}
// // onRowClicked={handleRowClicked}
// // isRowExpanded={isRowExpanded}
// // pinnedTopRowData={table.total}
// suppressCellFocus={true}
// getRowId={(params) => {
//   return params.data.id.toString();
// }}
// // getRowHeight={(params) => {
// //   if (params.node.rowPinned === "top") {
// //     return 40;
// //   }
// //   return 80;
// // }}
// rowStyle={{
//   padding: "0 !important"
// }}
// // onGridReady={onGridReady}
// // onCellClicked={onCellClicked}
// // getRowStyle={(params: any) => {
// //   return params.data.lvl === 1 ? { color: '#0044B4' } : undefined;
// // }}
// rowHeight={80}
// headerHeight={40}
// // context={{ rows: hiddenRows, changeDialog }}
// // doesExternalFilterPass={doesExternalFilterPass}
// loadingOverlayComponent={Progress}
// noRowsOverlayComponent={() => <>нет данных</>}
