/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import {
  ColDef,
  ColGroupDef,
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
  NewValueParams,
  RowClassParams,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { enqueueSnackbar } from 'notistack';
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useEditQuantityMutation } from '../../../../api/calculations';
import Progress from '../../../../components/Progress';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import {
  ActList,
  ExecutionCalculationData,
  GetExecutionCalculationData,
} from '../../../../types';
import { ActListContext } from '../CalculationСomplicated';
import { PageStyled, WrapperAgGrid } from './Accomplishment.styles';
import { IPricesSwitch } from './Accomplishment.types';
import ActDialog from './components/ActDialog';
import CaptionTable from './components/CaptionTable';
import Cell from './components/Cell';
import ExportDialog from './components/ExportDialog';
import LevelExecution from './components/LevelExecution';
import { getHeadersParts } from './helper';

const priceSwitches: IPricesSwitch[] = [
  { name: 'Базовые', value: 'base' },
  { name: 'Текущие', value: 'curr' },
];

interface TableContext {
  collapse?: (id: number) => void;
  hiddenRowsIds?: number[];
  filteredData?: ExecutionCalculationData[];
  prices?: 'curr' | 'base';
  current?: ActList | null;
  updateData?: UpdateParams;
  total?: ExecutionCalculationData | null;
  calcID?: string;
  emptyCurr?: boolean;
}

type UpdateParams = (
  params: NewValueParams<ExecutionCalculationData, unknown>,
  act: ActList | null,
  calcID?: number | string,
) => Promise<void>;

export const AgContext = createContext<TableContext>({});

const Accomplishment = () => {
  const {
    current,
    table: d,
    isFetching,
    getTable,
    refetchActs,
  } = useContext(ActListContext);

  const [data, setData] = useState<GetExecutionCalculationData | undefined>(
    undefined,
  );

  const [prices, setPrices] = useState<'curr' | 'base'>('curr');
  const [updateLoader, setUpdateLoader] = useState(false);
  const { calcID } = useParams();
  const [hiddenRowsIds, setHiddenRowsIds] = useState<number[]>([]);

  const [gridRef, setGridRef] = useState<GridReadyEvent<
    ExecutionCalculationData,
    unknown
  > | null>(null);
  const Ref = useRef<AgGridReact<ExecutionCalculationData> | null>(null);

  const columnsRef = useRef<
    | (
        | ColDef<ExecutionCalculationData, any>
        | ColGroupDef<ExecutionCalculationData>
      )[]
    | undefined
  >();

  const [createModal, setCreateModal] = useState(false);
  const [exportModal, setExportModal] = useState('');
  // const { data, isFetching } = useGetExecutionCalculationQuery(
  //   { calcID: Number(calcID) },
  //   { skip: calcID === undefined },
  // );

  const [filteredData, setFilteredData] = useState<
    ExecutionCalculationData[] | undefined
  >(undefined);

  const changePrices = (price: IPricesSwitch) => {
    setPrices(price.value as typeof prices);
  };

  const [update, updateResponse] = useEditQuantityMutation();

  const [, setEditingCell] = useState(false);

  const onCellEditingStarted = () => {
    setEditingCell(true);
  };

  const updateData: UpdateParams = async (params, act, calcID) => {
    setUpdateLoader(true);

    gridRef?.api.showLoadingOverlay();

    try {
      if (act?.id && calcID) {
        const editedData = {
          actID: act.id,
          calcID: Number(calcID),
          body: {
            rowID: params.data.id,
            quantity:
              params.newValue === undefined ||
              params.newValue === null ||
              params.newValue === '0'
                ? null
                : Number(params.newValue),
          },
        };
        await update(editedData).then(() => refetchActs?.());
        setEditingCell(false);
      }
    } catch (error) {
      setEditingCell(false);
      // Обработка ошибки
      console.error('Error:', error);
    }
    return;
  };

  const emptyCurr = useMemo(() => {
    let flag = true;
    /**
     * total.curr не пустой - есть текущие цены
     * parts[любая партия].curr - есть текущие цены
     */
    if (data?.total && data.total?.[0]?.parts) {
      flag = data.total[0].parts.some((part) => {
        let foundTotalKey: keyof typeof part.curr;
        for (foundTotalKey in part.curr) {
          if (
            !Array.isArray(part.curr[foundTotalKey]) &&
            foundTotalKey !== 'hasIndex' &&
            foundTotalKey !== 'hasError'
          ) {
            if (part.curr[foundTotalKey] !== null) {
              return true;
            }
          }
        }
        return false;
      });
      if (!flag) {
        setPrices('base');
      }
    }
    return !flag;
  }, [data?.total, isFetching]);

  const getRowClass = (params: RowClassParams<ExecutionCalculationData>) => {
    const colors = {
      inactive: '#B8B8B8',
      deleted: '#B8B8B8',
      dont_belong: '#FF0101',
    };
    const styles: React.CSSProperties = {};

    const classCell = [];

    if (params.node.data?.type === 'file') {
      classCell.push('cell-bg-file');
      styles.background = 'rgba(0, 68, 180, 0.08)';
    } else if (params.node.data?.type === 'level') {
      classCell.push('cell-bg-level');
      styles.background = 'rgba(2, 136, 209, 0.05)';
    } else if (params.node.data?.type === 'sum') {
      classCell.push('cell-bg-def');
      styles.background = 'white';
    } else {
      classCell.push('cell-bg-def');
    }
    if (params.node.data?.subtype) {
      classCell.push(classCell[0] + '-' + params.node.data.subtype);
      styles.color = colors[params.node.data.subtype];
    }
    return classCell;
  };
  useEffect(() => {
    if (!isFetching) {
      setData(d);
    }
  }, [data, isFetching]);

  useEffect(() => {
    if (!isFetching) {
      setFilteredData(() => {
        if (data?.tree) {
          const indexes: number[] = [];
          const filledArr = data?.tree.reduce(
            (acc: ExecutionCalculationData[], curr) => {
              const newItem: typeof curr = Object.assign({}, curr);
              let idx = false;
              if (newItem && newItem.parent_id && newItem.id) {
                idx =
                  hiddenRowsIds.includes(newItem.parent_id) ||
                  indexes.includes(newItem.id);
                if (idx && newItem.children.length) {
                  indexes.push(...newItem.children.map((item) => item.id));
                }
              }
              return !idx ? [...acc, newItem] : acc;
            },
            [],
          );

          gridRef?.api.hideOverlay();

          setUpdateLoader(false);
          return filledArr?.length ? filledArr : data.tree;
        } else {
          gridRef?.api.hideOverlay();
          setUpdateLoader(false);
          return [];
        }
      });
    }
  }, [hiddenRowsIds, data?.tree, isFetching]);

  const collapse = (id: number) => {
    // if (setHiddenRowsIds) {
    setHiddenRowsIds((d) => {
      const copy = [...d];
      const addIndex = copy.findIndex((hrId) => hrId === id);
      if (addIndex >= 0) {
        copy.splice(addIndex, 1);
      } else {
        copy.push(id);
      }
      return copy;
    });
    // }
  };

  useLayoutEffect(() => {
    gridRef?.api.showLoadingOverlay();
    if (data?.tree) {
      setHiddenRowsIds((d) => {
        const copy = [...d];
        if (!copy.length) {
          data.tree
            .filter((item) => item.type === 'file' || item.type === 'level')
            .forEach((file) => copy.push(file.id));
        }
        return copy;
      });
    }
  }, [data?.tree]);

  useMutationHandlers(
    updateResponse,
    () => {
      enqueueSnackbar('Данные успешно обновлены', {
        variant: 'success',
      });
    },
    (error) => {
      console.log('<<< ERROR >>>', error);
      enqueueSnackbar('Есть проблемы', {
        variant: 'error',
      });
    },
  );

  useEffect(() => {
    if (calcID) {
      getTable?.(Number(calcID));
    }
  }, [calcID]);

  useEffect(() => {
    if ((isFetching && gridRef) || updateLoader) {
      gridRef?.api.showLoadingOverlay();
      // setFilteredData([]);
      // oneLoadData.current = false;
    }
  }, [isFetching, gridRef, updateLoader]);

  const [contextTable, setContextTable] = useState<TableContext>({
    calcID,
    emptyCurr,
    collapse,
    current,
    updateData,
    filteredData,
    prices,
    total: data?.total[0],
    hiddenRowsIds,
  });

  useEffect(() => {
    setContextTable((prevState) => ({
      ...prevState,
      total: data?.total?.[0],
      hiddenRowsIds,
      current,
      calcID,
      emptyCurr,
      filteredData,
      prices,
    }));
    // console.log(Ref.current);
    // Ref.current?.api?.refreshCells({ force: true });
  }, [
    data,
    filteredData,
    hiddenRowsIds,
    current,
    calcID,
    emptyCurr,
    prices,
    isFetching,
  ]);

  useEffect(() => {
    if (data?.tree && data?.total) {
      columnsRef.current = [
        {
          field: 'level',
          pinned: 'left',
          lockPinned: true,
          suppressMovable: true,
          width: 100,
          maxWidth: 100,
          minWidth: 100,
          cellStyle: {
            padding: '0 10px',
          },
          colSpan: (params: any) => {
            return params.data?.id === -1 ? 8 : 0;
          },

          headerName: 'Ур.',

          cellRenderer: (
            params: ICellRendererParams<ExecutionCalculationData, any, any>,
          ) => {
            return <LevelExecution {...params} />;
          },
        },
        {
          field: 'number',
          pinned: 'left',
          lockPinned: true,
          width: 60,
          suppressMovable: false,
          maxWidth: 60,
          minWidth: 60,
          headerName: '№ п\\п',

          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell float={'center'}>{data?.number}</Cell>;
          },
        },
        {
          field: 'lsr',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Шифр ЛСР',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.lsr}</Cell>;
          },
        },
        {
          field: 'chapter',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Раздел',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.chapter}</Cell>;
          },
        },
        {
          field: 'header',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Заголовок',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.header}</Cell>;
          },
        },
        {
          field: 'code',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Код расценки',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.code}</Cell>;
          },
        },
        {
          field: 'title',
          pinned: 'left',
          lockPinned: true,
          width: 310,
          suppressMovable: false,
          maxWidth: 500,
          minWidth: 310,
          headerName: 'Наименование',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell>{data?.title}</Cell>;
          },
        },

        {
          field: 'unit',
          pinned: 'left',
          lockPinned: true,
          width: 120,
          suppressMovable: false,
          maxWidth: 120,
          minWidth: 120,
          headerName: 'Ед. изм.',
          cellRenderer: ({
            data,
          }: ICellRendererParams<ExecutionCalculationData, any, any>) => {
            return <Cell float={'center'}>{data?.unit}</Cell>;
          },
        },
        ...getHeadersParts(
          data?.tree ? data?.tree?.[0]?.parts || [] : [],
          data?.total ? data?.total?.[0]?.parts || [] : [],
          emptyCurr,
        ),
      ];
      Ref.current?.api?.refreshCells({ force: true, suppressFlash: true });
      Ref.current?.api?.refreshHeader();
    }
  }, [data?.tree, data?.total, isFetching]);

  useEffect(() => {
    Ref.current?.api?.refreshCells({ force: true });
    Ref.current?.api?.refreshHeader();
  }, [contextTable, Ref.current, prices]);

  useEffect(() => {
    if (filteredData?.length && gridRef) {
      // gridRef?.api?.applyTransaction({ update: filteredData });
      gridRef?.api?.refreshCells({ force: true });
    }
  }, [filteredData, gridRef]);

  useEffect(() => {
    if (isFetching) {
      if (!updateLoader) {
        setFilteredData(undefined);
        setData(undefined);
        columnsRef.current = undefined;
      }
    }
  }, [isFetching, updateLoader]);
  console.log('filteredData', filteredData);
  return (
    <PageStyled>
      <AgContext.Provider value={contextTable}>
        <CaptionTable
          disableCurr={emptyCurr}
          act={() => setCreateModal(true)}
          exportKS={(str: string) => setExportModal(str)}
          prices={prices}
          changePrices={changePrices}
          priceSwitches={priceSwitches}
        />
        <WrapperAgGrid className="ag-theme-material accompishment">
          {/*{isLoading && <Progress />}*/}
          <AgGridReact
            ref={Ref}
            onGridReady={(e) => setGridRef(e)}
            // onRowClicked={(params) => {
            //   console.log(params);
            //   params?.data?.id && collapse(params?.data?.id);
            // }}
            context={contextTable}
            enableCellTextSelection={true}
            ensureDomOrder={true}
            maintainColumnOrder={true}
            columnDefs={columnsRef.current}
            groupHeaderHeight={25}
            singleClickEdit
            gridOptions={{
              onCellEditingStarted,
              // suppressDragLeaveHidesColumns: true,
              navigateToNextHeader: () => null,
              tabToNextHeader: () => null,
            }}
            pinnedTopRowData={data?.total}
            rowData={filteredData}
            suppressCellFocus={true}
            onFirstDataRendered={(event) => {
              event.api.sizeColumnsToFit();
            }}
            onGridSizeChanged={(event: GridSizeChangedEvent) => {
              event.api.sizeColumnsToFit();
            }}
            onViewportChanged={(event) => {
              event.api.sizeColumnsToFit();
            }}
            getRowId={(params) => {
              return params.data.id.toString();
            }}
            getRowClass={getRowClass}
            getRowHeight={(params) => {
              if (params.node.rowPinned === 'top') {
                return 50;
              }
              return 55;
            }}
            rowStyle={{
              padding: '0 !important',
            }}
            rowHeight={55}
            headerHeight={36}
            // onCellEditingStarted={(event)=>{
            //   const isQuantityField = event.colDef.field?.startsWith('quantity')
            //   // if (isQuantityField) {
            //     if (current && current.status === "CLOSED") {
            //       event.event?.preventDefault();
            //       event.api.stopEditing(true);
            //     }
            //   // }
            // }}
            loadingOverlayComponent={Progress}
            noRowsOverlayComponent={Progress}></AgGridReact>
        </WrapperAgGrid>
        <ActDialog
          update={(f) => {
            gridRef?.api.showLoadingOverlay();
            setUpdateLoader(f);
            // setFilteredData(null);
          }}
          close={() => setCreateModal(false)}
          open={createModal}
        />
        <ExportDialog close={() => setExportModal('')} open={exportModal} />
      </AgContext.Provider>
    </PageStyled>
  );
};

export default Accomplishment;
