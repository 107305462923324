/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import FunctionsIcon from '@mui/icons-material/Functions';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICellRendererParams } from 'ag-grid-community';
import React, { useContext, useMemo } from 'react';
import { SvgSecondLev, SvgThirdLev } from '../../../../../../assets/SVG/SVG';
import { ReactComponent as FirstLevNew } from '../../../../../../assets/icons/LeveLicon/FirstLevNew.svg';
import { ExecutionCalculationData } from '../../../../../../types';
import { AgContext } from '../../Accomplishment';
import Cell from '../Cell';
import { Level, PinnedLevel } from './LevelExecution.style';

interface ILevelExecution
  extends ICellRendererParams<ExecutionCalculationData, any, any> {
  collapse?: any;
  hiddenArr?: any[];
}

const LevelExecution: React.FC<ILevelExecution> = ({ data, context, node }) => {
  const { collapse, hiddenRowsIds } = useContext(AgContext);
  const close = useMemo(() => {
    return hiddenRowsIds?.includes(data?.id!) || false;
  }, [hiddenRowsIds]);

  const onClick = () => {
    collapse?.(data?.id!);
  };

  if (node.rowPinned === 'top') {
    return (
      <PinnedLevel>
        <FunctionsIcon />
        <Cell showTooltip={false}>Суммы по объекту</Cell>
      </PinnedLevel>
    );
  }
  const colors = {
    inactive: '#B8B8B8',
    deleted: '#B8B8B8',
    dont_belong: '#FF0101',
    split: '#B78BAC',
  };
  switch (data?.type!) {
    case 'file':
      return (
        <Level onClick={onClick} arrowClose={close}>
          {data?.children.length ? <KeyboardArrowUpIcon /> : <span />}
          <FirstLevNew
            style={{
              ...(data?.subtype && { fill: colors[data.subtype] as string }),
              width: 22,
              height: 17,
            }}
          />
        </Level>
      );
    case 'level':
      return (
        <Level onClick={onClick} arrowClose={close}>
          {data?.children.length ? <KeyboardArrowUpIcon /> : <span />}
          <SvgSecondLev
            style={{
              fill: data?.subtype ? colors[data.subtype] : '#5291DA',
              width: 17,
              height: 18,
            }}
          />
        </Level>
      );
    case 'row':
      return (
        <Level
          style={{ paddingRight: '8px' }}
          onClick={onClick}
          arrowClose={close}
          floatRight>
          <span />
          <SvgThirdLev
            style={{
              width: 16,
              height: 17,
              fill: data?.subtype ? colors[data.subtype] : '#B78BAC',
            }}
          />
        </Level>
      );
    default:
      return (
        <Cell>
          {!!data?.children.length && (
            <button onClick={onClick}>collabse</button>
          )}
        </Cell>
      );
  }
};

export default LevelExecution;
