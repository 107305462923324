import { UserProjectAccessInfo } from 'types';

export const like = (a: string, b: string): boolean => {
  return a.toLocaleLowerCase().includes(b.toLocaleLowerCase());
};

export const filterByFieldNames = <
  T extends Record<string, Exclude<any, symbol>>,
>(
  data: T[],
  fieldNames: (keyof T)[],
  searchValue: string,
): T[] => {
  return data.filter((item) => {
    return fieldNames.some((fieldName) => {
      const complexKey = fieldName.toString().split('.');

      const deepValue: any = complexKey.reduce((result, currentKey) => {
        return result[currentKey];
      }, item);

      if (complexKey.length > 1) {
        return like(deepValue, searchValue);
      }

      if (fieldName === 'fullName') {
        const fullName = `${item.lastName} ${item.firstName} ${item.middleName}`;
        return like(fullName, searchValue);
      }

      if (fieldName === 'projects') {
        return item.access.projects.some((project: UserProjectAccessInfo) => {
          return like(project.name, searchValue);
        });
      }

      if (fieldName === 'objects') {
        return item.access.projects.some((project: UserProjectAccessInfo) => {
          return project.objects.some((object) => {
            return like(object.name, searchValue);
          });
        });
      }

      return like(item[fieldName] || '', searchValue);
    });
  });
};
