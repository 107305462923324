import { ReadyState } from 'react-use-websocket';

export interface ProviderWSProps {
  children: React.ReactNode;
}

export interface WSContextProps {
  uploadFileLsr: (props: WSMessageBody['upload_file_lsr']) => void;
  uploadFileLsrProgress?: WSMessageBody['upload_progress'];
  uploadFileLsrStatusOk?: WSMessageBody['status_ok'];
  uploadFileLsrErrors?: WSMessageBody['with_errors'];
  readyState: ReadyState;
  clearUploadFileLsrProgress: () => void;
  clearUploadFileLsrStatusOk: () => void;
  clearUploadFileLsrErrors: () => void;
  connectionStatus: ConnectionStatusType;
}

export enum WSMessageTypeSend {
  auth = 'auth',
  upload_file_lsr = 'upload_file_lsr',
}

export enum WSMessageTypeGet {
  upload_progress = 'upload_progress',
  status_ok = 'status_ok',
  with_errors = 'with_errors',
}

export type ConnectionStatusType =
  | 'Connecting'
  | 'Open'
  | 'Closing'
  | 'Closed'
  | 'Uninstantiated';

export interface WSMessageBody {
  [WSMessageTypeGet.upload_progress]: {
    remainTime: string;
    progress: number;
    completed: number;
    total: number;
  };
  [WSMessageTypeGet.status_ok]: {
    totalUploaded: number | null;
    uploadedWithErrors: number | null;
    successfullyUploaded: {
      name: string;
      success: boolean;
      msg: null | string;
    }[];
  } | null;
  [WSMessageTypeSend.upload_file_lsr]: {
    param: { projectID: number };
    files: { base64: string; name: string }[];
    status:
      | null
      | 'agreed'
      | 'not_agreed'
      | 'in_progress'
      | 'undefiend'
      | 'not_selected';
  };
  [WSMessageTypeGet.with_errors]: {
    successfullyUploaded: {
      name: string;
      success: boolean;
      msg: string | null;
    }[];
    totalUploaded: number;
    uploadedWithErrors: {
      name: string;
      success: boolean;
      msg: string | null;
    }[];
  };
}
