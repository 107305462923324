import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import CalculationComplexLegend from './components/CalculationComplexLegend';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  useCopyCalculationMutation,
  useDeleteCalculationMutation,
  useDownloadCalculationMutation,
} from '../../../../api/calculations';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';

import useConfirmDialog, {
  UseExitConfirmProps,
} from '../../../../hooks/useConfirmDialog';
import useConfirmDialogСonfidence, {
  UseExitConfirmPropsConfidence,
} from '../../../../hooks/useConfirmDialogСonfidence';
import { downloadBase64File } from '../../../../utils/downloadCalculation';
import CalculationDrawer from '../CalculationDrawer';

import { Menu, MenuItem, Stack } from '@mui/material';
import { TabData } from 'components/Tabs/Tabs.types';
import { useProjectId } from 'hooks/useProjectId';
import {
  CalcData,
  CalculationComplexTabLabels,
  CalculationComplexTableTabLabels,
  LevelNameTypeEn,
} from 'types';
import { BasePrices } from './components/BasePrices';
import { Estimate } from './components/Estimate';
import ImportDrawer from './components/ImportDrawer';
import { LaborIntensity } from './components/LaborIntensity';
import { Сoefficients } from './components/Сoefficients';

export type LevelNameShowType = {
  name: LevelNameTypeEn;
  show: boolean;
};
export type CalculationComplexProps = {
  calculation: CalcData;
  openEdit: (b: boolean) => void;
};

export type ContractNameType = 'ГЕНПОДРЯД' | 'СУБПОДРЯД' | 'АНАЛИЗ';

const CalculationComplex = ({
  calculation,
  openEdit,
}: CalculationComplexProps) => {
  const { title, createdAt, updatedAt, id: calculationId } = calculation;
  const projectID = useProjectId();
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // ******* Menu Calculation *******
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const MenuOpen = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOpenMenuCalculation = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  // ******* Delete Calculation *******
  const [deleteCalculation, deleteCalculationResponse] =
    useDeleteCalculationMutation();

  const handleDeleteCalculationClick = useCallback(
    (actionName: string) => {
      openConfirmConfidence(actionName);
    },
    [calculationId],
  );

  useMutationHandlers(deleteCalculationResponse, () => {
    navigate(`/projects/${projectID}/calculations`);
    enqueueSnackbar(t('success.calculationDelete'), {
      variant: 'success',
    });
  });

  // ******* Copy Calculation *******
  const [copyCalculation, copyCalculationResponse] =
    useCopyCalculationMutation();

  const handleCopyCalculationClick = useCallback(() => {
    copyCalculation({ projectID, calcID: calculationId });
  }, [calculationId]);

  useMutationHandlers(copyCalculationResponse, () => {
    navigate('/calculations');
    enqueueSnackbar(t('success.calculationCopy'), {
      variant: 'success',
    });
  });

  // ******* Download Calculation *******
  const [downloadCalculation, downloadCalculationResponse] =
    useDownloadCalculationMutation();

  const handleClickDownloadReport = useCallback(() => {
    downloadCalculation({ projectID, calcID: calculationId });
    setAnchorEl(null);
  }, [calculationId]);

  useMutationHandlers(
    downloadCalculationResponse,
    (data: string) => {
      downloadBase64File(data, `${'Смета ' + title + '.xlsx'}`);
    },
    () => {
      enqueueSnackbar('Не удалось скачать пустой расчет', { variant: 'error' });
    },
  );

  // ******* dialog Confirm *******
  const handleConfirmDelete = useCallback((confirm: boolean) => {
    if (confirm) {
      deleteCalculation({ projectID, calcID: calculationId });
    }
  }, []);
  const handleConfirmCopy = useCallback((confirm: boolean) => {
    if (confirm) {
      copyCalculation({ projectID, calcID: calculationId });
    }
  }, []);

  const dialogTitle = 'Вы уверенны?';

  const dataForConfirmDialogConfidence: UseExitConfirmPropsConfidence = {
    handleConfirmDelete,
    handleConfirmCopy,
    dialogTitle,
  };
  const { ConfirmDialogConfidence, openConfirmConfidence } =
    useConfirmDialogСonfidence(dataForConfirmDialogConfidence, dialogTitle);

  /***************************** Drawer Edit Calculation ****************************************/
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const handleConfirm = useCallback((confirm: boolean) => {
    if (confirm) {
      setIsDrawerOpen(false);
    }
  }, []);

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm,
  };

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog);

  const onDrawerClose = useCallback((dirty: boolean, immediately?: boolean) => {
    immediately || !dirty ? setIsDrawerOpen(false) : openConfirm();
  }, []);

  const [open, setOpen] = React.useState(false);

  // ******* Edit Calculation  *******

  const handleEditCalculationClick = useCallback(() => {
    setAnchorEl(null);
    setIsDrawerOpen(true);
  }, [calculationId]);

  /*****************************Tabs Legend****************************************/

  const [currentTab, setCurrentTab] =
    useState<CalculationComplexTabLabels>('Базовые цены');

  const [currentContract, setCurrentContract] =
    useState<ContractNameType>('ГЕНПОДРЯД');

  const tabNames: CalculationComplexTabLabels[] = Object.values(
    CalculationComplexTableTabLabels,
  ); //CalculationStatusesTabLabels

  const onTabChange = useCallback(
    (e: React.SyntheticEvent, tabValue: CalculationComplexTabLabels) => {
      setCurrentTab(tabValue);
    },
    [],
  );

  const onContractChange = useCallback((contractValue: ContractNameType) => {
    if (contractValue !== null) {
      setCurrentContract(contractValue);
    }
  }, []);

  const tabsData: TabData<CalculationComplexTabLabels>[] = useMemo(() => {
    return tabNames.map((tabName) => {
      const disabled = false;
      return {
        value: tabName,
        label: tabName,
      };
    });
  }, []); // projectsData

  useBreadcrumbs(
    [
      { title: 'Расчеты', url: `projects/${projectID}/calculations` },
      { title },
    ],
    [title],
  );

  /*****************************Tabs Legend****************************************/

  const [numberLevel, setNumberLevel] = useState(0);

  const levelNameCurrent = useMemo(() => {
    return [
      {
        name: 'level1' as LevelNameTypeEn,
        show: numberLevel >= 1,
      },
      {
        name: 'level2' as LevelNameTypeEn,
        show: numberLevel >= 2,
      },
      {
        name: 'level3' as LevelNameTypeEn,
        show: numberLevel >= 3,
      },
    ];
  }, [numberLevel]);

  const renderTableData = () => {
    switch (currentTab) {
      case 'Базовые цены':
        return (
          <BasePrices
            openEdit={openEdit}
            calculation={calculation}></BasePrices>
        );
      case 'Коэффициенты':
        return (
          <Сoefficients
            calculation={calculation}
            currentContract={currentContract}></Сoefficients>
        );
      case 'Норм. Трудоемкость':
        return (
          <LaborIntensity
            calculation={calculation}
            currentContract={currentContract}></LaborIntensity>
        );
      case 'Просмотр расчета':
        return (
          <Estimate
            calculation={calculation}
            currentContract={currentContract}
            levelNameCurrent={levelNameCurrent}></Estimate>
        );
    }
  };

  /*************************************************************************************************/

  return (
    <Stack flex={1} maxWidth="100%" sx={{ height: '100%' }}>
      <>
        <CalculationComplexLegend
          calculationStatus={status}
          handleOpenImportDrawer={() => setOpen(true)}
          handleOpenMenuCalculation={handleOpenMenuCalculation}
          currentTab={currentTab}
          tabsData={tabsData}
          onTabChange={onTabChange}
          onContractChange={onContractChange}
          currentContract={currentContract}
          setNumberLevel={setNumberLevel}
          numberLevel={numberLevel}
          calculationId={calculationId}
        />
        {renderTableData()}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={MenuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <MenuItem onClick={handleClickDownloadReport}>
            Скачать расчет
          </MenuItem>
          {/* <MenuItem onClick={handleCopyCalculationClick}>Копировать расчет</MenuItem> */}
          <MenuItem onClick={() => handleDeleteCalculationClick('Delete')}>
            Удалить расчет
          </MenuItem>
          <MenuItem
            onClick={() => {
              openEdit(true);
              setAnchorEl(null);
            }}>
            Редактировать
          </MenuItem>
        </Menu>
      </>
      <CalculationDrawer
        open={isDrawerOpen}
        onClose={onDrawerClose}
        calculationId={calculationId}
      />
      <ImportDrawer open={open} changeOpen={(flag: boolean) => setOpen(flag)} />
      <ConfirmDialogConfidence />
    </Stack>
  );
};

export default CalculationComplex;
