import { useCreateLevelMutation } from 'api/calculations';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { getSlicedChildrenIds } from './hooks.service';
import { CreateLevelPayload } from './hooks.types';

export const useCreateLevel = () => {
  const [createLevelMutation, createLevelResponse] = useCreateLevelMutation();
  const { enqueueSnackbar } = useSnackbar();

  useMutationHandlers(
    createLevelResponse,
    () => {
      enqueueSnackbar('Уровень создан', {
        variant: 'success',
      });
    },
    () => {
      enqueueSnackbar('Ошибка при создании уровня', {
        variant: 'error',
      });
    },
  );

  const createLevel = ({
    calcID,
    childrenIds,
    groupIds,
    title,
    rowType,
    level,
    rowID,
    levelIds,
  }: CreateLevelPayload) => {
    const mergedIds = [...childrenIds, ...groupIds];
    const index = mergedIds.findIndex((id) => id === rowID);

    const groupChildren = getSlicedChildrenIds(mergedIds, index, true);
    const children = getSlicedChildrenIds(
      mergedIds,
      index,
      rowType === 'folded',
    );

    // if (index === -1) return;
    // console.log(children,groupChildren,mergedIds);
    createLevelMutation({
      params: { calcID },
      body: {
        title: title || '-',
        // children,
        // groupChildren,
        children: level !== 1 && rowType === 'group' ? [] : children,
        groupChildren: level !== 1 && rowType === 'group' ? [] : groupChildren,
        parentID:
          level === 1
            ? undefined
            : rowType === 'group'
              ? levelIds[level - 1]
              : index !== 0
                ? levelIds[level - 1]
                : levelIds[level],
        level:
          (rowType === 'folded' &&
            index === 0 &&
            levelIds[level - 1] !== null) ||
          level === 0
            ? 1
            : level,
        recursiveLevelIncrease: level !== 1,
      },
    });
  };

  return { createLevel };
};
