/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import React, { useState } from 'react';
import { useGetActListQuery } from '../../../../api/calculations';
import { OrNull } from '../../../../api/references/estimates/estimates.types';
import useBreadcrumbs from '../../../../hooks/useBreadcrumbs';
import { useCalcId } from '../../../../hooks/useCalcId';
import { useProjectId } from '../../../../hooks/useProjectId';
import useSearchParam from '../../../../hooks/useSearchParams';
import { ActList } from '../../../../types';
import {
  addDataExecuted,
  deleteDataExecuted,
  getDataExecuted,
  openDBExecuted,
} from '../../../../utils/indexDB.executed';
import IntegrateDrawer from '../CalculationСomplicated/components/IntegrateDrawer/IntegrateDrawer';
import Tabs from './components/Tabs';
import UploadDrawer from './components/UploadDrawer';
import { HandbookContext, initialTabs, TTabType } from './handbook.services';
import CalculationTab from './tabs/CalculationTab';
import { ICalculationDirectory } from './tabs/CalculationTab/CalculationDirectory.types';
import { ExecutedTab } from './tabs/ExecutedTab';
import VorTab from './tabs/VorTab';

export const HandbookCalculation: React.FC<ICalculationDirectory> = ({
  calculation,
  openEdit,
}) => {
  const projectId = useProjectId();
  const buttonContainer = React.useRef<HTMLDivElement>(null);
  const { data, refetch } = useGetActListQuery(
    { calcID: calculation?.id! },
    { skip: !calculation?.id },
  );

  const calcID = useCalcId();

  const [qs, setQs] = useSearchParam<TTabType>(initialTabs);

  const [selectedTab, setSelectedTab] = React.useState<TTabType>(qs);

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const changeTab = (_event: React.SyntheticEvent, tabName: TTabType) => {
    setQs(tabName);
  };

  const [integrateDrawer, setIntegrateDrawer] = useState(false);

  const toggleIntegrate = (flag: boolean) => {
    setIntegrateDrawer(flag);
  };
  const [currentAct, setCurrentAct] = React.useState<OrNull<ActList>>(null);

  React.useEffect(() => {
    if (calcID && data) {
      openDBExecuted().then(async (db) => {
        const result = await getDataExecuted(db, calcID);
        if (result) {
          // const find = data.actList.find((_) => _?.id === result?.act?.actID);
          // if (find) {
          //   changeCurrentAct(find);
          // } else {
          //   changeCurrentAct(result.act);
          // }
          setCurrentAct(result.act);
        }
      });
    }
  }, [calcID, data]);

  const changeCurrentAct = React.useCallback(
    (act: ActList | null) => {
      openDBExecuted().then(async (db) => {
        if (act !== null) {
          addDataExecuted(db, { calcID: calcID, act }).then(() => {
            setCurrentAct(act);
          });
        } else {
          deleteDataExecuted(db, calcID).then(() => {
            setCurrentAct(null);
          });
        }
      });
    },
    [calcID],
  );

  const Content = React.useMemo(() => {
    switch (selectedTab) {
      case 'calculation':
        return CalculationTab;
      case 'vor':
        return VorTab;
      case 'executed':
        return ExecutedTab;
      default:
        return CalculationTab;
    }
  }, [selectedTab]);

  React.useEffect(() => {
    if (selectedTab) {
      setSelectedTab(qs);
    }
  }, [qs]);

  useBreadcrumbs(
    [
      { title: `Расчеты`, url: `/projects/${projectId}/calculations` },
      { title: `${calculation?.title}` },
    ],
    [calculation, projectId],
  );

  return (
    <HandbookContext.Provider
      value={{
        buttonContainer: buttonContainer.current,
        openUploadDrawer: (f) => {
          setOpenDrawer((prevState) => f ?? !prevState);
        },
        toggleIntegrate,
        actList: data ?? null,
        currentAct,
        changeAct: changeCurrentAct,
      }}>
      <Tabs
        changeTab={changeTab}
        selectedTab={selectedTab}
        btnPortalContainer={buttonContainer}
      />
      <Content calculation={calculation} openEdit={openEdit} />
      <UploadDrawer onClose={() => setOpenDrawer(false)} open={openDrawer} />
      <IntegrateDrawer
        actList={data?.actList || []}
        changeAct={changeCurrentAct}
        act={currentAct}
        open={integrateDrawer}
        close={() => {
          toggleIntegrate(false);
        }}
        calculation={calculation}
      />
    </HandbookContext.Provider>
  );
};
